import { Subaccount } from "@/app/_hooks/types";
import { useSubaccountMutations } from "@/app/_hooks/useSubaccount";
import { useTranslations } from "next-intl";
import { useEffect, useRef, useState } from "react";
import { Input } from "../../ui/elements/Input";
import { Checks } from "./Checks";

type SubaccountInputProps = {
    onClose: () => void;
} & ({ type: "new" } | { type: "edit"; subaccount: Subaccount });

export function NewSubaccountInput({ onClose, type, ...rest }: SubaccountInputProps) {
    const t = useTranslations("NewSubaccountInput");

    const initialName = type === "edit" ? (rest as { subaccount: Subaccount }).subaccount.name : "";

    const [name, setName] = useState(initialName);
    const [error, setError] = useState<string | undefined>(t("nameRequired"));
    const [touched, setTouched] = useState(false);

    const { createSubaccount, updateSubaccount } = useSubaccountMutations();

    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newName = e.target.value;
        setName(newName);

        if (newName.length === 0) {
            setError(t("nameRequired"));
        } else if (newName.length > 64) {
            setError(t("nameTooLong"));
        } else {
            setError(undefined);
        }

        if (!touched) setTouched(true);
    };

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const handleCreateOrEditSubaccount = () => {
        if (error) return;

        if (type === "new") {
            createSubaccount({ name });
        } else if (type === "edit") {
            const { subaccount } = rest as { subaccount: Subaccount };
            updateSubaccount({ id: subaccount.id, name });
        }

        onClose();
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleCreateOrEditSubaccount();
        }
    };

    return (
        <Input
            ref={inputRef}
            value={name}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            size={40}
            placeholder={type === "new" ? t("subaccountName") : t("editSubaccountName")}
            error={error && touched ? error : undefined}
        >
            <div className="flex items-center text-lg">
                <Checks onSave={handleCreateOrEditSubaccount} onCancel={onClose} isDisabled={!!error} />
            </div>
        </Input>
    );
}
