"use client";
import { ReactNode } from "react";
import { Section } from "../layout/Section";

export const SettingsSection = ({
    children,
    className,
    title,
}: {
    children: React.ReactNode;
    className?: string;
    title: string;
}) => (
    <Section className={className}>
        <SettingsSectionHeader title={title} />
        {children}
    </Section>
);

export const SettingsSectionHeader = ({ title, children }: { title: string; children?: ReactNode }) => {
    return (
        <div className="border-slate-blue-700 flex items-center justify-between border-b p-5 pb-[0.875rem] leading-[1.375rem]">
            <span className="text-lg leading-[1.375rem] font-medium uppercase">{title}</span>
            {children}
        </div>
    );
};

export const SettingsRow = ({ children, className }: { children: React.ReactNode; className?: string }) => (
    <div
        className={`grid grid-cols-1 items-start gap-4 p-5 md:grid-cols-[12.5rem_1fr_12.5rem] md:gap-0 ${className || ""}`}
    >
        {children}
    </div>
);

export const SettingsResponseMessage = ({
    success,
    neutral,
    message,
}: {
    success: boolean;
    neutral?: boolean;
    message: string;
}) => {
    const textColor = neutral ? "text-gray-blue-500" : success ? "text-green-600" : "text-red-600";

    return <div className={`flex-shrink-1 ${textColor}`}>{message}</div>;
};

export const SettingsRowTitle = ({ title, className }: { title: string; className?: string }) => {
    return <div className={`text-wrap capitalize ${className || ""}`}>{title}</div>;
};
