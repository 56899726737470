import { AddressConfirmed } from "@/app/[locale]/(routes)/(responsive)/confirm/withdrawal-address/ConfirmWithdrawalAddress";
import { AddressManagementData, SidebarNames, useSidebar } from "@/app/_contexts/sidebar";
import { useTranslations } from "next-intl";
import { useState } from "react";
import { CgClose } from "react-icons/cg";
import { GrCircleAlert } from "react-icons/gr";
import { AddressInputStage } from "./AddressInputStage";
import { ConfirmationEmailStage } from "./ConfirmationEmailStage";

export interface AddressDetails {
    addressId: number;
    address: string;
    chain: string;
    label: string;
}

const Stage = {
    ADDRESS_INPUT: "ADDRESS_INPUT",
    VERIFICATION: "VERIFICATION",
    CONFIRMED: "CONFIRMED",
} as const;

type StageType = (typeof Stage)[keyof typeof Stage];

export function AddressManagementSidebar({ data }: { data?: AddressManagementData }) {
    const t = useTranslations("AddressManagementSidebar");
    const [currentStage, setCurrentStage] = useState<StageType>(Stage.ADDRESS_INPUT);
    const { closeSidebar } = useSidebar();
    const [addressDetails, setAddressDetails] = useState<AddressDetails | null>(null);

    const renderStageContent = () => {
        switch (currentStage) {
            case Stage.ADDRESS_INPUT:
                return (
                    <AddressInputStage
                        openAtAsset={data?.asset}
                        openAtChain={data?.chain}
                        onNext={(details: AddressDetails) => {
                            setAddressDetails(details);
                            setCurrentStage(Stage.VERIFICATION);
                        }}
                    />
                );
            case Stage.VERIFICATION:
                return (
                    <ConfirmationEmailStage
                        addressDetails={addressDetails!}
                        onNext={() => setCurrentStage(Stage.CONFIRMED)}
                    />
                );
            case Stage.CONFIRMED:
                return <AddressConfirmed />;
            default:
                return null;
        }
    };

    return (
        <div className="flex h-full w-full flex-col overflow-y-auto">
            <div className="border-white-300 flex items-center justify-between border-b border-solid px-3 py-4">
                <div>{t("addressManagement")}</div>
                <CgClose
                    onClick={() => closeSidebar(SidebarNames.AddressManagement)}
                    className="cursor-pointer text-xl"
                />
            </div>
            <div className="flex-1">{renderStageContent()}</div>

            <div className="border-white-200 mx-6 mb-9 flex gap-3 self-end rounded-sm border border-solid p-3">
                <GrCircleAlert className="shrink-0 text-xl" />
                <div className="text-xs">{t("selectNetwork")}</div>
            </div>
        </div>
    );
}
