"use client";

import { AddressManagementSidebar } from "@/app/_components/overlay/sidebar/addaddress/AddressManagementSidebar";
import { Sidebar } from "@/app/_components/overlay/sidebar/Sidebar";
import { AssetWithBalance, Chain } from "@/app/_hooks/types";
import React, { createContext, useCallback, useContext, useState } from "react";

export const SidebarNames = {
    AddressManagement: "addressManagement",
} as const;
export type SidebarNames = (typeof SidebarNames)[keyof typeof SidebarNames];

export type AddressManagementData = {
    asset?: AssetWithBalance;
    chain?: Chain;
};

interface SidebarData {
    [SidebarNames.AddressManagement]: AddressManagementData;
}

interface SidebarConfig<T = unknown> {
    component: React.FC<{ data: T }>;
}

const sidebars: {
    [K in keyof SidebarData]: SidebarConfig<SidebarData[K]>;
} = {
    [SidebarNames.AddressManagement]: {
        component: AddressManagementSidebar,
    },
};

type SidebarName = SidebarNames;

interface SidebarState<T> {
    isOpen: boolean;
    isClosing: boolean;
    data: T | null;
}
type SidebarsState = {
    [K in SidebarName]: SidebarState<SidebarData[K]>;
};

interface SidebarContextProps {
    isSidebarOpen: (sidebarName: SidebarName) => boolean;
    openSidebar: <K extends SidebarName>(
        sidebarName: K,
        ...args: SidebarData[K] extends undefined ? [] : [data: SidebarData[K]]
    ) => void;
    closeSidebar: (sidebarName: SidebarName) => void;
    // setSidebarData: <K extends SidebarName>(sidebarName: K, data: SidebarData[K] | null) => void;
}

const SidebarContext = createContext<SidebarContextProps>({
    isSidebarOpen: () => false,
    openSidebar: () => {},
    closeSidebar: () => {},
    // setSidebarData: () => {},
});

export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error("useSidebar must be used within a SidebarProvider");
    }
    return context;
};
export function SidebarProvider({ children }: { children: React.ReactNode }) {
    const initialSidebarsState = {} as SidebarsState;
    for (const sidebarName in sidebars) {
        initialSidebarsState[sidebarName as SidebarName] = {
            isOpen: false,
            isClosing: false,
            data: null,
        };
    }
    const [sidebarsState, setSidebarsState] = useState<SidebarsState>(initialSidebarsState);

    const isSidebarOpen = useCallback(
        (sidebarName: SidebarName) => {
            return sidebarsState[sidebarName].isOpen;
        },
        [sidebarsState],
    );

    const openSidebar = useCallback(
        <K extends SidebarName>(
            sidebarName: K,
            ...args: SidebarData[K] extends undefined ? [] : [data: SidebarData[K]]
        ) => {
            const data = args[0] as SidebarData[K] | undefined;
            setSidebarsState((prev) => ({
                ...prev,
                [sidebarName]: { isOpen: true, isClosing: false, data: data ?? null },
            }));
        },
        [],
    );

    const closeSidebar = useCallback((sidebarName: SidebarName) => {
        setSidebarsState((prev) => ({
            ...prev,
            [sidebarName]: { ...prev[sidebarName], isClosing: true },
        }));

        setTimeout(() => {
            setSidebarsState((prev) => ({
                ...prev,
                [sidebarName]: { isOpen: false, isClosing: false, data: null },
            }));
        }, 500);
    }, []);

    return (
        <SidebarContext.Provider
            value={{
                isSidebarOpen,
                openSidebar,
                closeSidebar,
            }}
        >
            {children}

            {Object.entries(sidebarsState).map(([sidebarName, sidebarState]) => {
                if (!sidebarState.isOpen && !sidebarState.isClosing) return null;

                const sidebarConfig = sidebars[sidebarName as SidebarName];
                const SidebarComponent = sidebarConfig.component as React.FC<{ data: unknown }>;

                return (
                    <Sidebar key={sidebarName} isClosing={sidebarState.isClosing}>
                        <SidebarComponent data={sidebarState.data as never} />
                    </Sidebar>
                );
            })}
        </SidebarContext.Provider>
    );
}
