"use client";
import { useWebsocketClient } from "@/app/_contexts/websocket";
import { Margin } from "@/app/_hooks/types";
import { useSubaccount } from "@/app/_hooks/useSubaccount";
import { useEffect } from "react";
import { create } from "zustand";

interface MarginState {
    margin: Margin | undefined;
    isLoading: boolean;
    setMargin: (margin: Margin) => void;
    reset: () => void;
}

const initialState = {
    margin: undefined,
    isLoading: true,
};

export const useMarginStore = create<MarginState>((set, get) => ({
    ...initialState,
    setMargin: (margin) => set({ margin, isLoading: false }),
    reset: () => set(initialState),
}));

export const useMarginSubscription = ({ userId }: { userId: number }) => {
    const { client, isConnected } = useWebsocketClient();
    const { subaccountId } = useSubaccount();
    const { setMargin, reset } = useMarginStore();

    useEffect(() => {
        if (!client || !isConnected) {
            return;
        }

        const unsubscribe = client.margin(subaccountId, setMargin);

        return () => {
            unsubscribe();
            reset();
        };
    }, [client, isConnected, userId, subaccountId, setMargin, reset]);
};

export const useMargin = () => {
    const { margin, isLoading } = useMarginStore();
    return { margin, isLoading };
};
