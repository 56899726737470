"use client";
import { useSettings } from "@/app/_hooks/useSettings";
import { FormattedNumber, NumberFormatterProps } from "./FormattedNumber";

export function PrivacyNumber({
    number,
    unit,
    unitColor = "gray",
    options = {},
    locales,
    className,
    onClick,
    "data-testid": dataTestId,
}: NumberFormatterProps) {
    const { settings } = useSettings();

    if (settings?.hideBalances) {
        return (
            <span data-testid={dataTestId} className={className}>
                •••••••••••
            </span>
        );
    }

    return (
        <FormattedNumber
            data-testid={dataTestId}
            number={number}
            unit={unit}
            unitColor={unitColor}
            options={options}
            locales={locales}
            className={className}
            onClick={onClick}
        />
    );
}
