import { useRef } from "react";
import { FormattedNumber } from "../../ui/number/FormattedNumber";
import styles from "./RangeSlider.module.css";

export const RangeSlider = ({
    value = 0,
    valueMax = 100,
    onChange,
    className,
    checkpoints = 5,
    checkPointLabels,
}: {
    value: number;
    valueMax?: number;
    onChange?: (value: number) => void;
    className?: string;
    checkpoints?: number;
    checkPointLabels?: string[];
}) => {
    //TODO - improve
    const thumbtrackRef = useRef(null);

    const valueFromBoundingBox = (e: MouseEvent | React.MouseEvent<HTMLDivElement>) => {
        const container = thumbtrackRef.current! as HTMLDivElement;
        const { left, width } = container.getBoundingClientRect();
        let value = ((e.clientX - left) / width) * valueMax;
        value = Math.max(0, Math.min(value, valueMax));
        value = Math.round(value);
        return value;
    };

    const startDrag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        if (onChange) onChange(valueFromBoundingBox(e));
        document.addEventListener("mousemove", handleDrag);
        document.addEventListener("mouseup", stopDrag);
    };

    const stopDrag = () => {
        document.removeEventListener("mousemove", handleDrag);
        document.removeEventListener("mouseup", stopDrag);
    };

    const handleDrag = (e: MouseEvent) => {
        if (onChange) onChange(valueFromBoundingBox(e));
    };

    const bounded = Math.max(0, Math.min(value, 100));
    const percentage = Math.round((bounded / valueMax) * 100);

    return (
        <div
            className={`${styles.container} ${className}`}
            onMouseDown={startDrag}
            data-testid="trade-orderform-range-slider"
            aria-hidden="true"
        >
            <div className={styles.trackfilled} style={{ width: `${percentage}%` }}></div>
            <div className={styles.trackempty}></div>
            <div className={styles.thumbtrack} ref={thumbtrackRef}>
                <div
                    className={styles.thumb}
                    style={{ left: `${percentage}%` }}
                    onMouseDown={startDrag}
                    role="slider"
                    aria-valuemin={0}
                    aria-valuemax={valueMax}
                    aria-valuenow={bounded}
                    tabIndex={0}
                />
                {checkPointLabels == null && (
                    <div className={styles.sliderLabel} style={{ left: `${bounded}%` }}>
                        <FormattedNumber
                            number={(bounded / valueMax).toString()}
                            options={{
                                fractionDigits: 0,
                                style: "percent",
                            }}
                        />
                    </div>
                )}
                {checkpoints > 0 &&
                    Array.from({ length: checkpoints }, (_, i) => i).map((checkpoint, i) => {
                        const checkpointPercent = (i * 100) / (checkpoints - 1);
                        const filled = percentage >= Math.round(checkpointPercent);
                        return (
                            <div
                                key={checkpoint}
                                style={{ left: `${checkpointPercent}%` }}
                                className={filled ? styles.checkpointFilled : styles.checkpointEmpty}
                                onClick={onChange ? () => onChange((checkpointPercent / 100) * valueMax) : undefined}
                                aria-hidden="true"
                            >
                                <div className={filled ? styles.checkpointLabelFilled : styles.checkpointLabelEmpty}>
                                    {checkPointLabels?.[i]}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
