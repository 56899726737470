import { useMemo } from "react";
import { MarginSchedule, Pair } from "./types";
import { useApiQuery } from "./useApi";
import { useSubaccount } from "./useSubaccount";

export const useLeverage = () => {
    const { subaccountId } = useSubaccount();
    const leverageResp = useApiQuery("/account/leverage", {
        queryParams: { subaccountId },
    });

    return useMemo(() => {
        const m = new Map<string, string>();
        leverageResp.data?.forEach((l) => {
            m.set(l.symbol, l.leverage);
        });
        return m;
    }, [leverageResp.data]);
};

export const useMarginSchedules = () => {
    const resp = useApiQuery("/public/margin-schedules");
    return useMemo(() => {
        const m = new Map<string, MarginSchedule>();
        resp.data?.forEach((schedule) => {
            m.set(schedule.name, schedule);
        });
        return m;
    }, [resp.data]);
};

export const useMarginSchedule = (pair: Pair) => {
    const marginSchedules = useMarginSchedules();
    if (!pair.marginSchedule) {
        return undefined;
    }
    return marginSchedules.get(pair.marginSchedule);
};
