import { useAuth } from "@/app/_contexts/auth";
import { DepositModalData, ModalNames, useModal } from "@/app/_contexts/overlay";
import { AssetWithBalance, Chain } from "@/app/_hooks/types";
import { useApiPost, useApiQuery } from "@/app/_hooks/useApi";
import { useSubaccount } from "@/app/_hooks/useSubaccount";
import { Link } from "@arkham/i18n";
import { Button, Select } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { useState } from "react";
import { LoginButton } from "../../header/desktop/LoginButton";
import { BlurOverlayParent, KYCRequiredOverlay, TwoFactorRequiredOverlay } from "../../ui/elements/BlurOverlay";
import { ChainRow } from "../../ui/elements/select/ChainRow";
import { formatDepositTime } from "../../ui/utils";
import { AssetSelector } from "../shared/AssetSelector";
import { EstimatedTime } from "../shared/EstimatedTime";
import { TransactionModalHeader } from "../shared/TransactionModalHeader";
export function DepositModal({ data }: { data?: DepositModalData }) {
    const { closeModal } = useModal();
    const t = useTranslations("DepositModal");
    const [stage, setStage] = useState(data?.asset ? 2 : 1);
    const [selectedAsset, setSelectedAsset] = useState<AssetWithBalance | null>(data?.asset || null);

    const handleAssetSelect = (asset: AssetWithBalance) => {
        setSelectedAsset(asset);
        setStage(2);
    };
    const { data: hasAuthenticatorApp } = useApiQuery("/auth/authenticator-app/exists");

    const { user } = useAuth();

    const stageHeaderContent = {
        1: "Select an asset to deposit",
        2: selectedAsset && (
            <>
                <span>{t("selectNetworkToDeposit")}</span>
                <div className="flex items-center gap-1">
                    <div className="relative h-4 w-4 shrink-0">
                        <Image
                            src={selectedAsset.imageUrl}
                            alt={`${selectedAsset.name} logo`}
                            className="object-contain"
                            width={16}
                            height={16}
                        />
                    </div>
                    <span className="font-medium">{selectedAsset.symbol}</span>
                </div>
            </>
        ),
    };

    return (
        <div className="relative flex w-full flex-col sm:w-96">
            <TransactionModalHeader
                currentStage={stage}
                totalStages={2}
                setStage={setStage}
                modalName={ModalNames.Deposit}
                headerText="Deposit"
                stageHeaderContent={stageHeaderContent[stage as keyof typeof stageHeaderContent]}
            />
            <div className="relative p-6 pt-0">
                {stage === 1 && <AssetSelector onSelect={handleAssetSelect} />}
                {stage === 2 && selectedAsset && <ChainSelector selectedAsset={selectedAsset} />}
            </div>
            {!user ? (
                <BlurOverlayParent>
                    <div className="text-lg font-medium">{t("pleaseLoginOrRegisterToDeposit")}</div>
                    <div className="flex gap-4">
                        <Link href={`${process.env.NEXT_PUBLIC_AUTH_URL!}/register`}>
                            <Button color="blue">{t("signUp")}</Button>
                        </Link>
                        <LoginButton>
                            <Button color="blue" variant="secondary-alpha">
                                {t("login")}
                            </Button>
                        </LoginButton>
                    </div>
                </BlurOverlayParent>
            ) : !user.kycVerifiedAt ? (
                <KYCRequiredOverlay
                    text={t("pleaseVerifyYourIdentityToDeposit")}
                    onClose={() => closeModal(ModalNames.Deposit)}
                />
            ) : hasAuthenticatorApp === false ? (
                <TwoFactorRequiredOverlay
                    text={t("pleaseSetUpTwoFactorAuthentication")}
                    onClose={() => closeModal(ModalNames.Deposit)}
                />
            ) : null}
        </div>
    );
}

function ChainSelector({ selectedAsset }: { selectedAsset: AssetWithBalance }) {
    const t = useTranslations("DepositModal");
    const [selectedChain, setSelectedChain] = useState<Chain | null>(null);
    const { closeModal, openModal } = useModal();
    const { subaccountId } = useSubaccount();

    const { user } = useAuth();
    const shouldCreateNewAddress = user?.settings.autogenDepositAddresses;

    const { data: existingAddresses, isLoading: isLoadingAddresses } = useApiQuery(`/account/deposit/addresses`, {
        queryParams: {
            chain: selectedChain?.symbol as string,
            subaccountId,
        },
        options: {
            enabled: Boolean(selectedChain?.symbol),
        },
    });

    const hasExistingAddress = Boolean(existingAddresses?.addresses?.length);

    const {
        mutate: generateNewAddress,
        isPending,
        error,
    } = useApiPost("/account/deposit/addresses/new", {}, () => [[`/account/deposit/addresses`]]);

    const handleAddressSelection = () => {
        if (!selectedChain) return;

        if (shouldCreateNewAddress || !hasExistingAddress) {
            generateNewAddress(
                { chain: selectedChain.symbol, subaccountId },
                {
                    onSuccess: (data) => {
                        openModal(ModalNames.DepositAddress, {
                            address: data.address,
                            asset: selectedAsset,
                            chain: selectedChain,
                        });
                        closeModal(ModalNames.Deposit);
                    },
                },
            );
        } else {
            const latestAddress = existingAddresses?.addresses?.[0];
            openModal(ModalNames.DepositAddress, {
                address: latestAddress!,
                asset: selectedAsset,
                chain: selectedChain,
            });
            closeModal(ModalNames.Deposit);
        }
    };

    const getButtonText = () => {
        if (!selectedChain) return t("selectChain");
        if (shouldCreateNewAddress) return t("generateDepositAddress");
        return hasExistingAddress ? t("showDepositAddress") : t("generateDepositAddress");
    };

    return (
        <div className="flex w-full flex-col items-center gap-y-4">
            <Select<string>
                options={selectedAsset.chains.map((chain) => ({
                    value: chain.symbol,
                    label: chain.name,
                    render: (option) => (
                        <ChainRow chain={chain} asset={selectedAsset} minAmount={selectedAsset.minDeposit} />
                    ),
                }))}
                value={selectedChain?.symbol}
                onValueChange={(value: string) => {
                    const chain = selectedAsset.chains.find((c) => c.symbol === value);
                    if (chain) setSelectedChain(chain);
                }}
                placeholder={t("selectChain")}
                type="opaque"
                className="w-full"
                renderValue={(value, options) => {
                    const option = options.find((o) => o.value === value);
                    return option ? <div className="text-white-1200">{option.label}</div> : null;
                }}
            />

            <EstimatedTime
                {...(selectedChain
                    ? {
                          time: formatDepositTime(selectedChain.blockTime, selectedChain.confirmations),
                          minAmount: selectedAsset.minDeposit,
                          asset: selectedAsset,
                      }
                    : {})}
            />

            <Button
                color="green"
                variant="primary"
                disabled={!selectedChain || isPending || isLoadingAddresses}
                className="w-full text-sm"
                onClick={handleAddressSelection}
                isLoading={isPending || isLoadingAddresses}
            >
                {getButtonText()}
            </Button>

            {error ? <div className="text-center text-sm text-red-600">{error.data.message}</div> : null}
        </div>
    );
}
