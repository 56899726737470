"use client";
import { useAuth } from "@/app/_contexts/auth";
import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { useStorage } from "@/app/_contexts/storage";
import { useSubaccountTotalValues } from "@/app/_hooks/useBalances";
import { useToast } from "@arkham/ui-components";
import { useEffect, useMemo } from "react";
import { useApiDelete, useApiPost, useApiPut } from "./useApi";
export interface Subaccount {
    id: number;
    name: string;
    pinned: boolean;
}

export function useSubaccount() {
    const { subaccountId, setSubaccountId, isLoading } = useStorage();

    // const { data: subaccounts = [], isLoading, error } = useApiQuery("/subaccounts");
    const { user } = useAuth();
    const subaccounts = user?.subaccounts || [];

    useEffect(() => {
        if (subaccounts.length > 0 && !subaccounts.find((account) => account.id === subaccountId)) {
            setSubaccountId(0);
        }
    }, [subaccounts, subaccountId, setSubaccountId, user]);

    const selectedSubaccount = useMemo(
        () => subaccounts.find((account) => account.id === subaccountId),
        [subaccounts, subaccountId],
    );

    const getDisplayText = () => {
        if (isLoading) return "Loading...";
        if (!selectedSubaccount) return "No Account Selected";
        return selectedSubaccount.name;
    };

    const subaccountName = getDisplayText();

    return {
        subaccountName,
        selectedSubaccount,
        subaccountId,
        setSubaccountId,
        isLoading,
    };
}

export function useSubaccountList() {
    const { user } = useAuth();
    const subaccounts = user?.subaccounts || [];
    const { subaccountTotalValues, isLoading } = useSubaccountTotalValues();

    const sortedSubaccounts = useMemo(() => {
        if (subaccounts.length === 0) return [];

        const sortByValue = (a: Subaccount, b: Subaccount) => {
            const valueA = Number(subaccountTotalValues[a.id]) || 0;
            const valueB = Number(subaccountTotalValues[b.id]) || 0;
            return valueB - valueA;
        };

        const primaryAccount = subaccounts.find((account) => account.id === 0) || subaccounts[0];
        const pinnedAccounts = subaccounts.filter((account) => account.pinned && account.id !== 0).sort(sortByValue);
        const unpinnedAccounts = subaccounts.filter((account) => !account.pinned && account.id !== 0).sort(sortByValue);

        return [primaryAccount, ...pinnedAccounts, ...unpinnedAccounts];
    }, [subaccounts, subaccountTotalValues]);

    return {
        sortedSubaccounts,
        isLoading,
    };
}

export function useSubaccountMutations() {
    const { closeModal } = useModal();
    const showToast = useToast();

    const { mutate: createSubaccount } = useApiPost(
        "/subaccounts",
        {
            onSuccess: (_, params) => {
                showToast(`Created new subaccount: "${params.name}"`, { type: "success" });
            },
            onError: (e) => {
                console.error(e);
                showToast("An error occurred while creating the subaccount", { type: "error" });
            },
        },
        () => [["/subaccounts"]],
    );

    const { mutate: updateSubaccount } = useApiPut(
        "/subaccounts",
        {
            onSuccess: (_, params) => {
                if ("name" in params) {
                    showToast(`Subaccount name updated to "${params.name}"`, { type: "success" });
                } else if ("pinned" in params) {
                    showToast(`Subaccount is now ${params.pinned ? "pinned" : "unpinned"}`, {
                        type: "success",
                    });
                }
            },
            onError: (e) => {
                console.error(e);
                showToast("Failed to update subaccount. Please try again.", { type: "error" });
            },
        },
        () => [["/subaccounts"]],
    );

    const { mutate: deleteSubaccount } = useApiDelete(
        "/subaccounts/{subaccountId}",
        {
            onSuccess: () => {
                showToast("Subaccount deleted successfully", { type: "success" });
                closeModal(ModalNames.DeleteSubaccountConfirm);
            },
            onError: (e) => {
                console.error(e);
                showToast("Failed to delete subaccount", { type: "error" });
            },
        },
        () => [["/subaccounts"]],
    );

    return {
        createSubaccount,
        updateSubaccount,
        deleteSubaccount,
    };
}
