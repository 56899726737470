import { Position } from "@/app/_hooks/types";
import { useMarketClose } from "@/app/_hooks/useOrders";
import { Button } from "@arkham/ui-components";
import { useTranslations } from "next-intl";

export function MarketCloseButton({ position }: { position: Position }) {
    const t = useTranslations("MarketCloseButton");
    const { marketClose, isLoading } = useMarketClose();

    const handleMarketClose = () => {
        marketClose(position);
    };

    return (
        <Button size="sm" className="text-2xs my-0.5" onClick={handleMarketClose} disabled={isLoading}>
            {t("marketClose")}
        </Button>
    );
}
