"use client";

import { useAuth } from "@/app/_contexts/auth";
import { fetchSettings } from "@/app/_hooks/datafetching";
import { UserSettings } from "@/app/_hooks/types";
import { useApiPost } from "@/app/_hooks/useApi";
import { useToast } from "@arkham/ui-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslations } from "next-intl";

export const useSettings = () => {
    const { user } = useAuth();
    const { data, isLoading } = useQuery({
        queryKey: ["/account/settings"],
        queryFn: () => fetchSettings(),
        initialData: user?.settings,
    });
    const queryClient = useQueryClient();
    const showToast = useToast();
    const t = useTranslations("SettingsUpdateToasts");

    // exhaustively check we have all the settings
    const settingTranslations: Record<keyof UserSettings, string> = {
        hideBalances: t("hideBalances"),
        autogenDepositAddresses: t("autogenDepositAddresses"),
        confirmBeforePlaceOrder: t("confirmBeforePlaceOrder"),
        tickerTapeScroll: t("tickerTapeScroll"),
        updatesFlash: t("uiUpdatesFlash"),
        notifyAnnouncements: t("notifyAnnouncements"),
        notifyCommissions: t("notifyCommissions"),
        notifyDeposits: t("notifyDeposits"),
        notifyOrderFills: t("notifyOrderFills"),
        notifyRebates: t("notifyRebates"),
        notifySendEmail: t("notifySendEmail"),
        notifyWithdrawals: t("notifyWithdrawals"),
        notifyMarginUsage: t("notifyMarginUsage"),
        marginUsageThreshold: t("marginUsageThreshold"),
    };

    const { mutate: updateSettingsApi } = useApiPost(
        "/account/settings/update",
        {
            onMutate: async (newSetting) => {
                await queryClient.cancelQueries({ queryKey: ["/account/settings"] });
                const previousSettings = queryClient.getQueryData(["/account/settings"]);
                queryClient.setQueryData(["/account/settings"], (old: UserSettings | undefined) => ({
                    ...old,
                    ...newSetting,
                }));
                return { previousSettings };
            },
            onError: (err, newSetting, context) => {
                queryClient.setQueryData(["/account/settings"], context);
                showToast(t("updateFailed"), { type: "error" });
            },
            onSettled: (_, __, newSetting) => {
                queryClient.invalidateQueries({ queryKey: ["/user"] });
                const settingKey = Object.keys(newSetting || {})[0] as keyof UserSettings;

                if (settingKey) {
                    const settingValue = newSetting[settingKey];

                    if (typeof settingValue === "boolean") {
                        const statusText = settingValue ? t("enabled") : t("disabled");
                        const settingName = settingTranslations[settingKey] || String(settingKey);
                        showToast(`${settingName} ${statusText}`, { type: "success" });
                    } else {
                        showToast(t("settingsUpdated"), { type: "success" });
                    }
                } else {
                    showToast(t("settingsUpdated"), { type: "success" });
                }
            },
        },
        () => [["/account/settings"]],
    );

    const updateSetting = <K extends keyof UserSettings>(key: K, value: UserSettings[K]) => {
        updateSettingsApi({ [key]: value });
    };

    return {
        settings: data || null,
        updateSetting,
        isLoading,
    };
};
