import { OrderSide, TriggerPriceType, TriggerType } from "@/app/_hooks/types";
import { BigNumber } from "bignumber.js";

export const roundBigNumberToTickSize = (numberToRound: BigNumber, tickSize: BigNumber): BigNumber => {
    return numberToRound.dividedBy(tickSize).decimalPlaces(0, BigNumber.ROUND_DOWN).multipliedBy(tickSize);
};

export const roundToMin = (val: BigNumber, min: BigNumber) => {
    return val.dividedBy(min).decimalPlaces(0).multipliedBy(min);
};

export const isNumber = (val: string): boolean => {
    return /^[-+]?[0-9]+(\.[0-9]+)?$/.test(val);
};

const displayTriggerPriceType: { [key in TriggerPriceType]: string } = {
    last: "Last",
    index: "Index",
    mark: "Mark",
};

export const getTrigger = (order: {
    triggerType: `${TriggerType}`;
    triggerPriceType: `${TriggerPriceType}`;
    triggerPrice: string;
    side: `${OrderSide}`;
}) => {
    let triggerDirection = "?";
    if (order.triggerType === TriggerType.StopLoss) {
        triggerDirection = order.side === "buy" ? "≥" : "≤";
    } else if (order.triggerType === TriggerType.TakeProfit) {
        triggerDirection = order.side === "buy" ? "≤" : "≥";
    }
    return `${displayTriggerPriceType[order.triggerPriceType]} ${triggerDirection} ${order.triggerPrice}`;
};

export function countDecimalPlaces(input: string): number {
    if (input.includes(".")) {
        const parts = input.split(".");
        return parts[1].length;
    }
    return 0;
}
