import { URLS } from "@/app/_constants/urls";
import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { Link } from "@arkham/i18n";
import { Button } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BiLogoTelegram } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { IoMdArrowForward } from "react-icons/io";
import { MdOutlineFeaturedPlayList } from "react-icons/md";
import { VIPSupportTypeform } from "../../ui/buttons/VIPSupportTypeform";
import styles from "./VIPModal.module.css";

const InfoCard = ({
    title,
    description,
    actionText,
    href,
    Icon,
}: {
    title: React.ReactNode;
    description: React.ReactNode;
    actionText: React.ReactNode;
    href: string;
    Icon: React.ElementType;
}) => (
    <div className="border-white-100 bg-black-200 flex flex-col gap-3 rounded-xl border px-5 py-4">
        <div className="flex flex-col gap-1.5">
            <div className="text-white-1100 flex items-center gap-2 text-2xl font-medium">
                <Icon className="text-lg" />
                {title}
            </div>
            <div className="text-white-900 text-sm font-thin">{description}</div>
        </div>
        <Link href={href} target="_blank" className="flex items-center gap-1 self-end text-sm text-blue-700">
            {actionText} <IoMdArrowForward />
        </Link>
    </div>
);

export function VIPModal() {
    const t = useTranslations("VIPModal");
    const { closeModal } = useModal();

    return (
        <div className="relative flex flex-col gap-6 p-6">
            <div>
                <div className="text-3xl font-semibold">{t("vipBenefits")}</div>
                <div className="text-white-900 text-lg">{t("viewExclusivePerks")}</div>
                <CgClose
                    className="absolute top-6 right-6 cursor-pointer text-xl"
                    onClick={() => closeModal(ModalNames.VIP)}
                />
            </div>
            <div className={`flex w-full items-center justify-center gap-3 px-4 py-3 ${styles.barBackground}`}>
                <div className="text-white-1100 text-lg">{t("vipBox")}</div>
                <Link href={URLS.CLAIM_VIP_BOX} className="flex items-center gap-1.5 text-blue-800" target="_blank">
                    {t("claimVipBox")} <IoMdArrowForward />
                </Link>
            </div>
            <div className="flex gap-4">
                <div
                    className={`border-white-100 bg-black-200 flex flex-col items-center justify-center gap-6 rounded-xl border p-5 ${styles.background}`}
                >
                    <Link href={URLS.NOMINATE_VIP} target="_blank">
                        <Button color="blue" variant="secondary-alpha">
                            {t("nominateVIP")}
                        </Button>
                    </Link>
                </div>
                <div className="flex flex-col gap-4">
                    <InfoCard
                        title={t("submitContentForAmplification")}
                        description={t("amplifyContent")}
                        actionText={t("madeAPostFeaturingArkham")}
                        href={URLS.SUBMIT_AMPLIFICATION_CONTENT}
                        Icon={MdOutlineFeaturedPlayList}
                    />

                    <div className="border-white-100 bg-black-200 flex flex-col gap-3 rounded-xl border px-5 py-4">
                        <div className="flex flex-col gap-1.5">
                            <div className="text-white-1100 flex items-center gap-2 text-2xl font-medium">
                                <AiOutlineQuestionCircle className="text-lg" />
                                {t("needHelp")}
                            </div>
                            <div className="text-white-900 text-sm font-thin">{t("getInTouch")}</div>
                        </div>
                        <div className="flex w-full justify-end">
                            <VIPSupportTypeform />
                        </div>
                    </div>

                    <InfoCard
                        title={t("vipPrivateTelegram")}
                        description={t("stayCurrentWithAllThingsArkham")}
                        actionText={t("joinPrivateVipTelegramChannel")}
                        href={URLS.VIP_TELEGRAM}
                        Icon={BiLogoTelegram}
                    />
                </div>
            </div>
        </div>
    );
}
