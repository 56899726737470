"use client";
import { useApiPost } from "@/app/_hooks/useApi";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { useRouter } from "next/navigation";

const performClientLogout = (queryClient: QueryClient, router: AppRouterInstance) => {
    queryClient.invalidateQueries({ queryKey: ["/user"] });
    router.push("/");
    router.refresh();
};

export const useLogout = () => {
    const { mutate: logout } = useApiPost("/auth/logout", {
        onSuccess: () => {
            performClientLogout(queryClient, router);
        },
    });
    const router = useRouter();
    const queryClient = useQueryClient();

    const handleLogout = () => {
        logout(undefined);
    };

    const handleClientLogout = () => {
        performClientLogout(queryClient, router);
    };

    return { handleLogout, handleClientLogout };
};
