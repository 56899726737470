import { Logo } from "@arkham/ui-components";

export function LoadingWrapper({
    loading,
    children,
    className,
    fallback,
}: {
    loading: boolean;
    children: React.ReactNode;
    className?: string;
    fallback?: React.ReactNode;
}) {
    if (loading) {
        if (fallback) {
            return fallback;
        }
        return (
            <div className={`flex h-full w-full items-center justify-center ${className}`}>
                <Logo of="loadingIcon" maxHeight={60} />
            </div>
        );
    }
    return children;
}
