export const VALID_SETTINGS_TABS = [
    "account",
    "security",
    "sessions",
    "trading",
    "interface",
    "notifications",
    "api",
] as const;

export type ValidSettingsTab = (typeof VALID_SETTINGS_TABS)[number];

export function isValidSettingsTab(tab: string): tab is ValidSettingsTab {
    return VALID_SETTINGS_TABS.includes(tab as ValidSettingsTab);
}
