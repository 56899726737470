import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { useApiPut, useApiQuery } from "@/app/_hooks/useApi";
import { Button, useToast } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import { useRef, useState } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { CgClose } from "react-icons/cg";

export function ReferralLinkModal() {
    const t = useTranslations("ReferralLinkModal");
    const { closeModal } = useModal();
    const { data: refs, isLoading: RefsLoading } = useApiQuery("/account/referral-links");
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const referralLink = `${process.env.NEXT_PUBLIC_AUTH_URL!}/register?ref=`;

    const showToast = useToast();
    const [slug, setSlug] = useState("");

    const handleDivClick = () => {
        inputRef.current?.focus();
    };

    const { mutate: changeSlug, isPending: isChangingSlug } = useApiPut(
        "/account/referral-links/{id}/slug",
        {
            onSuccess: () => {
                closeModal(ModalNames.ReferralLink);
                showToast(t("referralLinkSlugUpdated"), { type: "success" });
            },
            onError: (error) => {
                showToast(error.data.message, { type: "error" });
            },
        },
        () => [["/account/referral-links"]],
    );

    const handleConfirm = () => {
        if (!refs?.[0]?.id || !slug) return;

        changeSlug({
            id: refs[0].id,
            slug,
        });
    };

    return (
        <div className="flex flex-col gap-3 px-6 py-5">
            <div className="text-lg font-medium">{t("customizeLink")}</div>
            <CgClose
                className="absolute top-4 right-4 cursor-pointer text-2xl"
                onClick={() => closeModal(ModalNames.ReferralLink)}
            />
            <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-2">
                    <div className="text-xs font-thin">{t("referralLink")}</div>
                    <div
                        className={`flex cursor-text items-center rounded border border-solid p-2 text-xs font-thin ${
                            isFocused ? "border-blue-500" : "border-slate-blue-800"
                        } bg-ash-600`}
                        onClick={handleDivClick}
                    >
                        <span className="text-white-300 flex-0 whitespace-nowrap">{referralLink}</span>
                        <input
                            ref={inputRef}
                            className="placeholder:text-white-800 w-full bg-transparent outline-hidden"
                            type="text"
                            placeholder={t("customLinkPlaceholder")}
                            value={slug}
                            onChange={(e) => setSlug(e.target.value)}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                        />
                    </div>
                </div>
                <div className="flex items-center gap-1">
                    <AiOutlineExclamationCircle />
                    <div className="text-2xs text-white-700 font-thin">{t("customizeLinkDisclaimer")}</div>
                </div>
                <div className="flex gap-2">
                    <Button color="grey" className="w-1/2" onClick={() => closeModal(ModalNames.ReferralLink)}>
                        {t("cancel")}
                    </Button>
                    <Button color="green" className="w-1/2" onClick={handleConfirm} isLoading={isChangingSlug}>
                        {t("confirm")}
                    </Button>
                </div>
            </div>
        </div>
    );
}
