import React, { ReactNode } from "react";
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";

interface CustomCheckboxProps {
    children: ReactNode;
    isChecked: boolean;
    onChange: (checked: boolean) => void;
    className?: string;
}

export const Checkbox = ({ children, isChecked, onChange, className = "" }: CustomCheckboxProps) => {
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === " " || e.key === "Enter") {
            e.preventDefault();
            onChange(!isChecked);
        }
    };

    return (
        <label
            className={`flex cursor-pointer items-center gap-2 select-none ${className}`}
            role="checkbox"
            aria-checked={isChecked}
            tabIndex={0}
            onKeyDown={handleKeyDown}
            onClick={() => onChange(!isChecked)}
        >
            {isChecked ? (
                <MdOutlineCheckBox size={20} aria-hidden="true" className="shrink-0" />
            ) : (
                <MdOutlineCheckBoxOutlineBlank size={20} aria-hidden="true" className="shrink-0" />
            )}
            {children}
        </label>
    );
};
