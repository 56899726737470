import { AssetWithBalance } from "@/app/_hooks/types";
import { useBalances } from "@/app/_hooks/useBalances";
import BigNumber from "bignumber.js";
import Fuse from "fuse.js";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { Input } from "../../ui/elements/Input";
import { PrivacyNumber } from "../../ui/number/PrivacyNumber";

export function AssetSelector({
    onSelect,
    filterZeroBalances = false,
}: {
    onSelect: (asset: AssetWithBalance) => void;
    filterZeroBalances?: boolean;
}) {
    const t = useTranslations("AssetSelector");
    const { balances } = useBalances();
    const [searchQuery, setSearchQuery] = useState("");

    const filteredBalances = useMemo(
        () => (filterZeroBalances ? balances.filter((asset) => !new BigNumber(asset.balanceUSDT).isZero()) : balances),
        [balances, filterZeroBalances],
    );

    const fuse = useMemo(
        () =>
            new Fuse(filteredBalances, {
                keys: ["name", "symbol"],
                threshold: 0.3,
                ignoreLocation: false,
            }),
        [filteredBalances],
    );

    const filteredAssets = useMemo(() => {
        if (!searchQuery) return filteredBalances;
        return fuse.search(searchQuery).map((result) => result.item);
    }, [fuse, searchQuery, filteredBalances]);

    const sortedAssets = filteredAssets.sort((a, b) => new BigNumber(b.balanceUSDT).comparedTo(a.balanceUSDT));

    return (
        <div className="flex h-full flex-col">
            <div className="mb-4 flex-none">
                <Input
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder={t("searchPlaceholder")}
                    className="text-xs"
                    textColor="text-white-800"
                    buttonSide="left"
                >
                    <div className="text-white-800 flex h-8 w-6 items-center justify-end">
                        <BiSearch />
                    </div>
                </Input>
            </div>

            <div className="flex-none">
                <div className="mb-2 flex justify-between px-1.5 text-xs">
                    <div>{t("asset")}</div>
                    <div>{t("availBalance")}</div>
                </div>
            </div>

            <div className="-mr-3 h-60 max-h-60 min-h-0 min-h-60 flex-1 overflow-y-scroll pr-3">
                {sortedAssets.length > 0 ? (
                    sortedAssets.map((asset) => <AssetRow key={asset.symbol} asset={asset} onSelect={onSelect} />)
                ) : (
                    <div className="py-2 text-center">{t("noAssets")}</div>
                )}
            </div>
        </div>
    );
}

function AssetRow({ asset, onSelect }: { asset: AssetWithBalance; onSelect: (asset: AssetWithBalance) => void }) {
    return (
        <button
            className="hover-blue flex w-full justify-between rounded-sm p-2"
            aria-label={`Select ${asset.name}`}
            onClick={() => onSelect(asset)}
        >
            <div className="flex items-center text-xs">
                <Image className="mr-1.5 object-contain" src={asset.imageUrl} alt={asset.name} width={16} height={16} />
                <div>{asset.name}&nbsp;</div>
                <div className="text-gray-1000 translate-y-px">{asset.symbol}</div>
            </div>

            <div className="text-2xs flex flex-0 flex-col items-end gap-0.5">
                <PrivacyNumber number={asset.freeUSDT} options={{ style: "currency", currency: "USD" }} />
                <div className="text-gray-1000">
                    <PrivacyNumber number={asset.free} options={{ abbreviateNumbers: true }} />
                    &nbsp;{asset.symbol}
                </div>
            </div>
        </button>
    );
}
