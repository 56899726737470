"use client";

interface SectionProps extends React.HTMLAttributes<HTMLElement> {
    children: React.ReactNode;
    transparent?: boolean;
}

export function Section({ children, className, transparent, ...props }: SectionProps) {
    return (
        <section
            className={`${transparent ? "bg-blue-a100 rounded-sm backdrop-blur-3xl" : "border-0.5 border-slate-blue-800 bg-slate-blue-200 rounded-sm"} ${className || ""} `}
            {...props}
        >
            {children}
        </section>
    );
}
