"use client";
import { Pair, Ticker } from "@/app/_hooks/types";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useWebsocketClient } from "../websocket";

const TickerContext = createContext<Ticker | null>(null);

export const TickerProvider = ({ pair, children }: { pair: Pair; children: React.ReactNode }) => {
    const { client, isConnected } = useWebsocketClient();
    const [ticker, setTicker] = useState<Ticker | null>(null);

    useEffect(() => {
        if (!client || !isConnected) return;
        return client.ticker(pair.symbol, true, (res) => {
            setTicker(res);
        });
    }, [client, isConnected]);

    return <TickerContext.Provider value={ticker}>{children}</TickerContext.Provider>;
};

export const useTicker = () => {
    return useContext(TickerContext);
};
