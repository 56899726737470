import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { Order, OrderType, TriggerOrder } from "@/app/_hooks/types";
import { useCancelOrder, useTriggerOrder } from "@/app/_hooks/useOrders";
import { useTranslations } from "next-intl";
import styles from "./Cancel.module.css";

const orderType = (type: `${OrderType}`) => {
    switch (type) {
        case OrderType.LimitGtc:
            return "Limit";
        case OrderType.Market:
            return "Market";
        default:
            return type;
    }
};

const isTriggerOrder = (order: Order | TriggerOrder): order is TriggerOrder => {
    return (order as TriggerOrder).triggerOrderId !== undefined;
};

export function CancelModal({ data }: { data: Order | TriggerOrder }) {
    const t = useTranslations("CancelModal");
    const { closeModal } = useModal();
    const { cancelOrder } = useCancelOrder();
    const { cancelTriggerOrder } = useTriggerOrder();

    const onClick = () => {
        if (isTriggerOrder(data)) {
            cancelTriggerOrder(data);
        } else {
            cancelOrder(data);
        }
    };

    return (
        <div className={`p-4 ${styles.container}`}>
            <div className={styles.header}>
                <div className={styles.headerText}>
                    <span>
                        <span>{isTriggerOrder(data) ? "Trigger Order" : "Order"}</span>
                        <span>: </span>
                        <span>{orderType(data.type)}</span>
                    </span>
                    <div className={data.side === "buy" ? styles.green : styles.red}>{data.side}</div>
                </div>
            </div>
            <div className={styles.content}>
                {isTriggerOrder(data) ? (
                    <span className="flex flex-col">
                        <div className={styles.confirmRow}>
                            <div>{t("triggerPrice")}</div>
                            <div>{data.triggerPrice}</div>
                        </div>
                        <div className={styles.confirmRow}>
                            <div>{t("orderPrice")}</div>
                            <div>{data.price}</div>
                        </div>
                    </span>
                ) : (
                    <span className="flex flex-col">
                        <div className={styles.confirmRow}>
                            <div>{t("orderPrice")}</div>
                            <div>{data.price}</div>
                        </div>
                        <div className={styles.confirmRow}>
                            <div>{t("avgPrice")}</div>
                            <div>{data.avgPrice}</div>
                        </div>
                    </span>
                )}
                <div className={styles.confirmRow}>
                    <div>{t("quantity")}</div>
                    <div>{data.size}</div>
                </div>
                <div className={styles.confirmRow}>
                    <div>{t("orderType")}</div>
                    <div>{data.type}</div>
                </div>

                <div className={styles.buttons}>
                    <button className={styles.buttonConfirm} onClick={() => closeModal(ModalNames.Cancel)}>
                        {t("keep")}
                    </button>
                    <button className={styles.buttonDeny} onClick={onClick}>
                        {t("cancel")}
                    </button>
                </div>
            </div>
        </div>
    );
}
