import { MarketCloseButton } from "@/app/_components/ui/buttons/MarketCloseButton";
import { Position } from "@/app/_hooks/types";
import { usePairs } from "@/app/_hooks/useFetch";
import BigNumber from "bignumber.js";
import { useTranslations } from "next-intl";
import { AssetIcon } from "../../ui/images/AssetIcon";

export function PositionInfoModal({ data }: { data: Position }) {
    const t = useTranslations("PositionInfoModal");
    const { pairs } = usePairs();

    const pair = pairs?.find((pair) => pair.symbol === data.symbol);

    if (!pair) {
        return null;
    }

    return (
        <div className="flex flex-col items-center gap-6 p-4">
            <div className="flex flex-col items-center gap-4">
                <AssetIcon url={pair.baseImageUrl} url2={pair.quoteImageUrl} width={40} height={40} />

                <span className="text-xl font-thin">
                    {pair.baseSymbol}/ {pair.quoteSymbol}
                </span>
            </div>
            <div className="flex w-full flex-col items-center">
                <Row label="Size" value={data.base} />
                <Row label={t("value")} value={data.value} />
                <Row label={t("breakEvenPrice")} value={data.breakEvenPrice || "-"} />
                <Row label={t("markPrice")} value={data.markPrice} />
                <Row label={t("liquidationPrice")} value="N/A" />
                <Row label={t("initialMargin")} value="N/A" />
                <Row label={t("maintenanceMargin")} value={data.maintenanceMargin} />
                <Row
                    label={t("pnl")}
                    value={data.pnl}
                    className={new BigNumber(data.pnl).isGreaterThan(0) ? "text-green-800" : "text-red-800"}
                />
            </div>
            <MarketCloseButton position={data} />
        </div>
    );
}

function Row({ label, value, className }: { label: string; value: string; className?: string }) {
    return (
        <div className="border-slate-blue-700 flex w-full justify-between border-b py-3">
            <div>{label}</div>
            <div className={className}>{value}</div>
        </div>
    );
}
