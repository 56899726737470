import BigNumber from "bignumber.js";
import { Margin, MarginSchedule, Position } from "./types";

export const getLiquidationPrice = (
    margin: Margin | undefined,
    position: Position | undefined,
    schedule: MarginSchedule | undefined,
) => {
    if (!margin || !position || !schedule) {
        return undefined;
    }
    if (position.base === "0") {
        return undefined;
    }

    // M_maintother = maintenance margin of positions other than this one.
    const M_maintother = new BigNumber(margin.maintenance).minus(position.maintenanceMargin);

    // C = collateral.
    const C = new BigNumber(margin.total).minus(position.pnl);

    // B = position.base.
    const B = new BigNumber(position.base);

    // Q = position.quote.
    const Q = new BigNumber(position.quote);

    // Solving for P_liq such that B * P_liq + C + Q = M_maintother + M_maintthis(P_liq).
    // For a given band, M_maintthis(P_liq) = .6 * (marginRate * B.abs() * P_liq - rebate)
    // Thus, B * P_liq + C + Q = M_maintother + .6 * (marginRate * B.abs() * P_liq - rebate)
    // Solving for P_liq, we get:
    // When long: P_liq = (M_maintother - C - Q - .6 * rebate) / (B * (1 - .6 * marginRate))
    // When short: P_liq = (M_maintother - C - Q + .6 * rebate) / (B * (1 + .6 * marginRate))

    // We want to find the first band that satisfies the condition.

    let prevPositionLimit = new BigNumber(0);
    for (const band of schedule.bands) {
        const marginRate = new BigNumber(band.marginRate);
        const rebate = new BigNumber(band.rebate);
        const one = new BigNumber(1);

        const top = M_maintother.minus(C).minus(Q).minus(rebate.multipliedBy(0.6));
        const bottom = B.isPositive()
            ? B.multipliedBy(one.minus(marginRate.multipliedBy(0.6)))
            : B.multipliedBy(one.plus(marginRate.multipliedBy(0.6)));
        const P_liq = top.dividedBy(bottom);

        const positionLimit = new BigNumber(band.positionLimit);
        const notional = P_liq.multipliedBy(B.abs());
        if (notional.isGreaterThan(prevPositionLimit) && notional.isLessThanOrEqualTo(positionLimit)) {
            return P_liq.toString();
        }

        prevPositionLimit = positionLimit;
    }

    return undefined;
};

export const getPositionLimit = (marginSchedule: MarginSchedule | undefined, leverage: string) => {
    if (!marginSchedule) {
        return undefined;
    }
    for (let i = marginSchedule.bands.length - 1; i >= 0; i--) {
        if (parseFloat(marginSchedule.bands[i].leverageRate) >= parseFloat(leverage)) {
            return new BigNumber(marginSchedule.bands[i].positionLimit);
        }
    }
    return undefined;
};

export const getPessimisticInitialMargin = (position: Position, leverageMap: Map<string, string>) => {
    return BigNumber.max(
        position.initialMargin,
        BigNumber(position.value).dividedBy(leverageMap.get(position.symbol) ?? "1"),
    );
};
