import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { Session } from "@/app/_hooks/types";
import { useApiPost, useApiQuery } from "@/app/_hooks/useApi";
import { useLogout } from "@/app/_hooks/useLogout";
import { Button, useToast } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { parseUserAgent } from "../../settings/sessions/SessionsSettingsTab";

interface SessionManagementModalProps {
    data: {
        session?: Session;
        allSessions?: boolean;
        isOpenedOnMobile?: boolean;
    };
}

export function SessionManagementModal({ data }: SessionManagementModalProps) {
    const { data: sessionsResponse } = useApiQuery("/account/sessions", {});
    const t = useTranslations("SessionsSection");
    const { handleClientLogout } = useLogout();
    const { closeModal } = useModal();
    const toast = useToast();
    const { session, allSessions, isOpenedOnMobile } = data || {};
    const [confirmationMode, setConfirmationMode] = useState(false);
    const isCurrentSession = session && sessionsResponse?.currentSession === session.id;

    const { mutate: deleteSession } = useApiPost(
        "/account/sessions/delete",
        {
            onSuccess: (_, { sessionId }) => {
                if (isCurrentSession) {
                    handleClientLogout();
                } else {
                    closeModal(ModalNames.SessionManagementConfirm);
                    toast(t("sessionDeleted"), { type: "success" });
                }
            },
            onError: () => {
                toast(t("errorDeletingSession"), { type: "error" });
            },
        },
        () => [["/account/sessions"]],
    );

    const { mutate: terminateAllSessions } = useApiPost("/account/sessions/terminate-all", {
        onSuccess: () => {
            toast(t("sessionsTerminated"), { type: "success" });
            handleClientLogout();
        },
        onError: () => {
            toast(t("errorTerminatingSessions"), { type: "error" });
        },
    });

    const handleEndSession = () => {
        if (isOpenedOnMobile && !confirmationMode) {
            setConfirmationMode(true);
            return;
        }

        if (session) {
            deleteSession({ sessionId: session.id });
        }
    };

    const handleTerminateAll = () => {
        terminateAllSessions(undefined);
    };

    const handleNevermind = () => {
        if (isOpenedOnMobile && confirmationMode) {
            setConfirmationMode(false);
        } else {
            closeModal(ModalNames.SessionManagementConfirm);
        }
    };

    const renderHeader = (title: string) => (
        <div className="flex justify-between">
            <h2 className="text-2xl font-semibold">{title}</h2>
            <IoMdClose
                onClick={() => closeModal(ModalNames.SessionManagementConfirm)}
                className="cursor-pointer text-lg"
                data-testid="modal-subaccount-close-button"
            />
        </div>
    );

    if (allSessions) {
        return (
            <div className="flex flex-col gap-4 p-6 sm:max-w-120 sm:min-w-120">
                {renderHeader(t("endAllSessions"))}
                <p className="text-white-700">{t("endAllSessionsConfirmation")}</p>
                <div className="mt-2 flex justify-between gap-3">
                    <Button
                        variant="tertiary"
                        color="grey"
                        className="flex-1 text-nowrap"
                        onClick={() => closeModal(ModalNames.SessionManagementConfirm)}
                    >
                        {t("nevermind")}
                    </Button>
                    <Button variant="primary" color="red" className="flex-1 text-nowrap" onClick={handleTerminateAll}>
                        {t("endAllSessions")}
                    </Button>
                </div>
            </div>
        );
    }

    if (!session) {
        return null;
    }

    const getHeaderTitle = () => {
        if (isOpenedOnMobile && confirmationMode) {
            return t("areYouSure");
        }
        return t("sessionDetails");
    };

    const logoutButtonText = isCurrentSession ? t("logout") : t("endSession");

    return (
        <div className="flex flex-col gap-4 p-6 sm:max-w-120 sm:min-w-120">
            {renderHeader(getHeaderTitle())}
            <div className="flex flex-col gap-6">
                <div className="flex justify-between">
                    <span className="text-white-800">{t("userAgent")}</span>
                    <span>{parseUserAgent(session.userAgent).displayString}</span>
                </div>
                <div className="flex justify-between">
                    <span className="text-white-800">{t("ipAddress")}</span>
                    <span>{session.ipAddress}</span>
                </div>
                <div className="flex justify-between">
                    <span className="text-white-800">{t("createdAt")}</span>
                    <span>{new Date(session.createdAt).toLocaleString()}</span>
                </div>
                <div className="flex justify-between">
                    <span className="text-white-800">{t("lastUsed")}</span>
                    <span>{new Date(session.lastUsedAt).toLocaleString()}</span>
                </div>

                {isCurrentSession && (
                    <p className="text-sm text-red-900">
                        {t("byEndingYourCurrentSessionYouWillBeLoggedOutImmediately")}
                    </p>
                )}

                {isOpenedOnMobile && !confirmationMode ? (
                    <Button variant="tertiary" color="red" onClick={handleEndSession} className="w-full text-nowrap">
                        {logoutButtonText}
                    </Button>
                ) : (
                    <div className="flex justify-between gap-3">
                        <Button
                            variant="tertiary"
                            color="grey"
                            onClick={handleNevermind}
                            className="flex-1 text-nowrap"
                        >
                            {t("nevermind")}
                        </Button>
                        <Button
                            variant="tertiary"
                            color="red"
                            onClick={handleEndSession}
                            className="flex-1 text-nowrap"
                        >
                            {logoutButtonText}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}
