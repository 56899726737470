"use client";

import { MobileOverlay } from "@/app/_components/header/mobile/MobileOverlay";
import { Modal } from "@/app/_components/layout/Modal";
import { ApiKeyModal } from "@/app/_components/modals/apiKey/ApiKey";
import { CancelModal } from "@/app/_components/modals/cancel/Cancel";
import { ChangeLeverage } from "@/app/_components/modals/changeLeverage/ChangeLeverage";
import { DepositModal } from "@/app/_components/modals/deposit/DepositModal";
import { DepositAddressModal } from "@/app/_components/modals/depositaddress/DepositAddressModal";
import { EnableFuturesModal } from "@/app/_components/modals/enablefutures/EnableFuturesModal";
import { TransferModal } from "@/app/_components/modals/newtransfer/TransferModal";
import { TransferSuccessModal } from "@/app/_components/modals/newtransfer/TransferSucessModal";
import { OrderInfoModal } from "@/app/_components/modals/orderinfo/OrderInfo";
import { PositionInfoModal } from "@/app/_components/modals/positioninfo/PositionInfo";
import { PriceAlertModal } from "@/app/_components/modals/priceAlert/PriceAlert";
import { ReferralLinkModal } from "@/app/_components/modals/refferalLink/ReferralLinkModal";
import { DeleteSubaccountConfirmModal } from "@/app/_components/modals/subaccount/DeleteSubaccountConfirm";
import { SubaccountManagementModal } from "@/app/_components/modals/subaccount/SubaccountManagement";
import { VIPModal } from "@/app/_components/modals/VIP/VIPModal";
import { WalletAssetInfoModal } from "@/app/_components/modals/walletassetinfo/WalletAssetInfo";
import { WithdrawalModal } from "@/app/_components/modals/withdrawal/WithdrawalModal";
import { WithdrawalSuccessModal } from "@/app/_components/modals/withdrawal/WithdrawalSuccessModal";
import {
    AlertPriceType,
    ApiKey,
    AssetWithBalance,
    Chain,
    Order,
    Pair,
    Position,
    Session,
    Subaccount,
    TriggerOrder,
    WithdrawalAddress,
} from "@/app/_hooks/types";
import { useParams, useRouter } from "next/navigation";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { AirdropClaimModal } from "../_components/modals/airdropClaim/AirdropClaimModal";
import { AirdropClaimAddressModal } from "../_components/modals/airdropClaimAddress/AirdropClaimAddressModal";
import { MFAModal } from "../_components/modals/mfa/MFAModal";
import { PreviousSeasonsModal } from "../_components/modals/previouseasons/PreviousSeasonsModal";
import { SessionManagementModal } from "../_components/modals/sessionManagement/SessionManagementModal";
export type WithdrawalSuccessModalData = {
    asset: AssetWithBalance;
    withdrawalBaseAmount: string;
    withdrawalUSDAmount: string;
    address: WithdrawalAddress;
};

export type DepositModalData = {
    asset: AssetWithBalance;
};

export type TransferModalData = {
    asset: AssetWithBalance;
};

export type WithdrawalModalData = {
    asset: AssetWithBalance;
};

export type TransferSuccessModalData = {
    asset: AssetWithBalance;
    amount: string;
    usdValue: string;
    subaccountTo: string;
    subaccountFrom: string;
};

export type PriceAlertModalData = {
    pair: Pair;
    alertPrice: string;
    alertPriceType: `${AlertPriceType}`;
};

export type ChangeLeverageModalData = {
    pair: Pair;
};

export type DepositAddressModalData = {
    address: string;
    asset: AssetWithBalance;
    chain: Chain;
};

export type ApiKeyModalData = {
    apiKey?: ApiKey;
};

export type SessionManagementModalData = {
    session?: Session;
    allSessions?: boolean;
    isOpenedOnMobile?: boolean;
};

export const MFATopics = {
    login: "login",
    change_email: "change_email",
    change_password: "change_password",
    delete_user: "delete_user",
    order: "order",
    mfa_every_trade: "mfa_every_trade",
    set_session_duration: "set_session_duration",
} as const;

export type MFAModalData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- fix later
    mutation: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- fix later
    mutationData: any;
    topic: (typeof MFATopics)[keyof typeof MFATopics];
};

export const ModalNames = {
    Cancel: "cancel",
    ApiKey: "ApiKey",
    PriceAlert: "priceAlert",
    DeleteSubaccountConfirm: "deleteSubaccountConfirm",
    SubaccountManagement: "subaccountManagement",
    Transfer: "transfer",
    MobileMenu: "mobilemenu",
    PositionInfo: "positioninfo",
    OrderInfo: "orderinfo",
    WalletAssetInfo: "walletAssetInfo",
    ChangeLeverage: "changeLeverage",
    DepositAddress: "depositAddress",
    Deposit: "deposit",
    Withdrawal: "withdrawal",
    TransferSuccess: "transferSuccess",
    EnableFutures: "enablefutures",
    WithdrawalSuccess: "withdrawalSuccess",
    VIP: "vip",
    ReferralLink: "referralLink",
    MFA: "mfa",
    PreviousSeasons: "previousSeasons",
    AirdropClaimAddress: "airdropClaimAddress",
    AirdropClaim: "airdropClaim",
    SessionManagementConfirm: "sessionManagementConfirm",
} as const;
export type ModalNames = (typeof ModalNames)[keyof typeof ModalNames];

interface ModalData {
    [ModalNames.Cancel]: Order | TriggerOrder;
    [ModalNames.ApiKey]: ApiKeyModalData;
    [ModalNames.PriceAlert]: PriceAlertModalData;
    [ModalNames.DeleteSubaccountConfirm]: Subaccount;
    [ModalNames.SubaccountManagement]: undefined;
    [ModalNames.Deposit]: DepositModalData | undefined;
    [ModalNames.MobileMenu]: undefined;
    [ModalNames.PositionInfo]: Position;
    [ModalNames.OrderInfo]: Order;
    [ModalNames.WalletAssetInfo]: AssetWithBalance;
    [ModalNames.ChangeLeverage]: ChangeLeverageModalData;
    [ModalNames.DepositAddress]: DepositAddressModalData;
    [ModalNames.Withdrawal]: WithdrawalModalData;
    [ModalNames.Transfer]: TransferModalData;
    [ModalNames.TransferSuccess]: TransferSuccessModalData;
    [ModalNames.EnableFutures]: undefined;
    [ModalNames.WithdrawalSuccess]: WithdrawalSuccessModalData;
    [ModalNames.VIP]: undefined;
    [ModalNames.MFA]: MFAModalData;
    [ModalNames.ReferralLink]: undefined;
    [ModalNames.PreviousSeasons]: undefined;
    [ModalNames.AirdropClaimAddress]: undefined;
    [ModalNames.AirdropClaim]: undefined;
    [ModalNames.SessionManagementConfirm]: SessionManagementModalData;
}

interface ModalConfig<T = unknown> {
    component: React.FC<{ data: T }>;
    zIndex?: number;
    header?: React.ReactNode;
    renderComponent?: boolean;
}

const modals: {
    [K in keyof ModalData]: ModalConfig<ModalData[K]>;
} = {
    [ModalNames.Cancel]: {
        component: CancelModal,
    },
    [ModalNames.Deposit]: {
        component: DepositModal,
    },
    [ModalNames.Withdrawal]: {
        component: WithdrawalModal,
    },
    [ModalNames.DepositAddress]: {
        component: DepositAddressModal,
    },
    [ModalNames.ApiKey]: {
        component: ApiKeyModal,
    },
    [ModalNames.PriceAlert]: {
        component: PriceAlertModal,
        header: "Price Alert",
    },
    [ModalNames.DeleteSubaccountConfirm]: {
        component: DeleteSubaccountConfirmModal,
        zIndex: 40,
    },
    [ModalNames.SubaccountManagement]: {
        component: SubaccountManagementModal,
        zIndex: 50,
    },
    [ModalNames.MobileMenu]: {
        component: MobileOverlay,
        renderComponent: true,
    },
    [ModalNames.PositionInfo]: {
        component: PositionInfoModal,
        header: "Position details",
    },
    [ModalNames.OrderInfo]: {
        component: OrderInfoModal,
        header: "Order details",
    },
    [ModalNames.WalletAssetInfo]: {
        component: WalletAssetInfoModal,
    },
    [ModalNames.ChangeLeverage]: {
        component: ChangeLeverage,
        header: "Change Leverage",
    },
    [ModalNames.Transfer]: {
        component: TransferModal,
    },
    [ModalNames.TransferSuccess]: {
        component: TransferSuccessModal,
    },
    [ModalNames.EnableFutures]: {
        component: EnableFuturesModal,
    },
    [ModalNames.WithdrawalSuccess]: {
        component: WithdrawalSuccessModal,
    },
    [ModalNames.VIP]: {
        component: VIPModal,
    },
    [ModalNames.ReferralLink]: {
        component: ReferralLinkModal,
    },
    [ModalNames.MFA]: {
        component: MFAModal,
    },
    [ModalNames.PreviousSeasons]: {
        component: PreviousSeasonsModal,
    },

    [ModalNames.AirdropClaimAddress]: {
        component: AirdropClaimAddressModal,
    },
    [ModalNames.AirdropClaim]: {
        component: AirdropClaimModal,
    },
    [ModalNames.SessionManagementConfirm]: {
        component: SessionManagementModal,
    },
};

type ModalName = ModalNames;

interface ModalState<T> {
    isOpen: boolean;
    data: T | null;
}

type ModalsState = {
    [K in ModalName]: ModalState<ModalData[K]>;
};

interface ModalContextProps {
    isModalOpen: (modalName: ModalName) => boolean;
    openModal: <K extends ModalName>(
        modalName: K,
        ...args: ModalData[K] extends undefined ? [] : [data: ModalData[K]]
    ) => void;
    closeModal: (modalName: ModalName) => void;
    setModalData: <K extends ModalName>(modalName: K, data: ModalData[K] | null) => void;
    preventOutsideClickClose: () => void;
}

const ModalContext = createContext<ModalContextProps>({
    isModalOpen: () => false,
    openModal: () => {},
    closeModal: () => {},
    setModalData: () => {},
    preventOutsideClickClose: () => {},
});

export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error("useModal must be used within a ModalProvider");
    }
    return context;
};

export function ModalProvider({ children }: { children: React.ReactNode }) {
    const router = useRouter();
    const params = useParams();
    const [canOutsideClickClose, setCanOutsideClickClose] = useState(true);

    const initialModalsState = {} as ModalsState;
    for (const modalName in modals) {
        initialModalsState[modalName as ModalName] = {
            isOpen: false,
            data: null,
        };
    }
    const [modalsState, setModalsState] = useState<ModalsState>(initialModalsState);

    const isModalOpen = useCallback(
        (modalName: ModalName) => {
            return modalsState[modalName].isOpen;
        },
        [modalsState],
    );

    const openModal = useCallback(
        <K extends ModalName>(modalName: K, ...args: ModalData[K] extends undefined ? [] : [data: ModalData[K]]) => {
            const data = args[0] as ModalData[K] | undefined;
            setModalsState((prev) => ({
                ...prev,
                [modalName]: { isOpen: true, data: data ?? null },
            }));
        },
        [modals, router, setModalsState],
    );

    const closeModal = useCallback(
        (modalName: ModalName) => {
            setCanOutsideClickClose(true);
            setModalsState((prev) => ({
                ...prev,
                [modalName]: { isOpen: false, data: null },
            }));
        },
        [setCanOutsideClickClose, modals, router, setModalsState],
    );

    const preventOutsideClickClose = useCallback(() => {
        setCanOutsideClickClose(false);
    }, []);

    const setModalData = useCallback(
        <K extends ModalName>(modalName: K, data: ModalData[K] | null) => {
            setModalsState((prev) => ({
                ...prev,
                [modalName]: { ...prev[modalName], data },
            }));
        },
        [modals, router, setModalsState],
    );

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                Object.keys(modalsState).forEach((modalName) => {
                    if (modalsState[modalName as ModalName].isOpen) {
                        closeModal(modalName as ModalName);
                    }
                });
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [modalsState, closeModal]);

    return (
        <ModalContext.Provider
            value={{
                isModalOpen,
                openModal,
                closeModal,
                setModalData,
                preventOutsideClickClose,
            }}
        >
            {children}

            {Object.entries(modalsState).map(([modalName, modalState]) => {
                if (!modalState.isOpen) return null;

                const modalConfig = modals[modalName as ModalName];
                const ModalComponent = modalConfig.component as React.FC<{ data: unknown }>;

                if (modalConfig.renderComponent) {
                    return <ModalComponent key={modalName} data={modalState.data as never} />;
                }

                return (
                    <Modal
                        key={modalName}
                        name={modalName as ModalName}
                        zIndex={modalConfig.zIndex || 30}
                        canOutsideClickClose={canOutsideClickClose}
                        header={modalConfig.header}
                    >
                        <ModalComponent data={modalState.data as never} />
                    </Modal>
                );
            })}
        </ModalContext.Provider>
    );
}
