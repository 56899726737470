import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { SidebarNames, useSidebar } from "@/app/_contexts/sidebar";
import { AssetWithBalance, Chain, WithdrawalAddress } from "@/app/_hooks/types";
import { useApiPost, useApiQuery } from "@/app/_hooks/useApi";
import { useSubaccount } from "@/app/_hooks/useSubaccount";
import { Button, Select, useToast } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";
import { Checkbox } from "../../ui/buttons/Checkbox";
import { Address } from "../../ui/elements/Address";
import { ChainRow } from "../../ui/elements/select/ChainRow";
import { AmountInput } from "../shared/AmountInput";

export function SelectWithdrawalAddressSection({ selectedAsset }: { selectedAsset: AssetWithBalance }) {
    const t = useTranslations("SelectWithdrawalAddressSection");
    const [selectedChain, setSelectedChain] = useState<Chain | null>(null);
    const [selectedAddress, setSelectedAddress] = useState<WithdrawalAddress | undefined>();
    const [baseAmount, setBaseAmount] = useState("");
    const [USDAmount, setUSDAmount] = useState("");
    const [isError, setIsError] = useState(false);
    const [confirmChecked, setConfirmChecked] = useState(false);

    const { openModal, closeModal } = useModal();
    const { closeSidebar, openSidebar } = useSidebar();
    const { data: withdrawalAddressesList } = useApiQuery("/account/withdrawal/addresses");
    const showToast = useToast();
    const { subaccountId } = useSubaccount();

    const filteredWithdrawalAddresses = withdrawalAddressesList?.filter(
        (address) => address.chain === selectedChain?.symbol,
    );

    useEffect(() => {
        if (filteredWithdrawalAddresses?.length === 1) {
            setSelectedAddress(filteredWithdrawalAddresses[0]);
        }
    }, [filteredWithdrawalAddresses]);

    const { mutate: sendWithdrawal, isPending } = useApiPost(
        "/account/withdraw",
        {
            onSuccess: () => {
                if (selectedAddress) {
                    openModal(ModalNames.WithdrawalSuccess, {
                        asset: selectedAsset,
                        withdrawalBaseAmount: baseAmount,
                        withdrawalUSDAmount: USDAmount,
                        address: selectedAddress,
                    });
                }
                closeModal(ModalNames.Withdrawal);
                showToast(t("withdrawalInitiatedSuccessfully"), { type: "success" });
            },
            onError: (error, params) => {
                console.error("error", error, params);
                showToast(error.data.message, { type: "error" });
            },
        },
        (params) => [["/account/withdrawals"]],
    );

    function handleSubmit() {
        if (!selectedAddress) {
            showToast(t("pleaseSelectWithdrawalAddress"), { type: "error" });
            return;
        }
        sendWithdrawal({
            addressId: selectedAddress!.id,
            amount: baseAmount,
            symbol: selectedAsset.symbol,
            subaccountId: subaccountId,
        });
    }

    function handleSidebar() {
        openSidebar(SidebarNames.AddressManagement, {
            asset: selectedAsset,
            chain: selectedChain ? selectedChain : undefined,
        });
    }

    const isSubmitDisabled = !selectedChain || !selectedAddress || !baseAmount || !confirmChecked || isError;

    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-1">
                <div className="text-white-800">{t("network")}</div>
                <Select<string>
                    options={selectedAsset.chains.map((chain) => ({
                        value: chain.symbol,
                        label: chain.name,
                        render: (option) => (
                            <ChainRow
                                chain={chain}
                                asset={selectedAsset}
                                minAmount={selectedAsset.minWithdrawal}
                                fee={selectedAsset.withdrawalFee}
                            />
                        ),
                    }))}
                    value={selectedChain?.symbol}
                    onValueChange={(value: string) => {
                        const chain = selectedAsset.chains.find((c) => c.symbol === value);
                        if (chain) {
                            setSelectedChain(chain);
                            setSelectedAddress(undefined);
                        }
                    }}
                    placeholder={t("selectNetwork")}
                    type="transparent"
                    className="w-full"
                    renderValue={(value, options) => {
                        const option = options.find((o) => o.value === value);
                        return option ? <div className="text-white-1200">{option.label}</div> : null;
                    }}
                />
            </div>
            <div className="flex flex-col gap-1">
                <div className="text-white-800">{t("withdrawalAddress")}</div>
                {filteredWithdrawalAddresses?.length !== 0 && (
                    <div className="text-white-600 flex justify-between text-xs">
                        <div>{t("showingAddresses", { count: filteredWithdrawalAddresses?.length ?? 0 })}</div>
                    </div>
                )}
                <div className="border-white-200 flex max-h-48 flex-col gap-1.5 overflow-scroll rounded-sm border border-solid p-1.5 text-xs">
                    {filteredWithdrawalAddresses?.length ? (
                        filteredWithdrawalAddresses.map((address) => (
                            <button
                                onClick={() => setSelectedAddress(address)}
                                key={address.id}
                                className={`flex cursor-pointer justify-between rounded border border-solid px-3 py-2.5 ${
                                    selectedAddress?.address === address.address
                                        ? "bg-blue-hover border-blue-600 opacity-100"
                                        : `${address.confirmed === false ? "border-yellow-900" : "border-transparent"} hover:bg-white-200 opacity-35 hover:opacity-100`
                                }`}
                            >
                                <div>{address.label}</div>
                                <Address address={address.address} />
                            </button>
                        ))
                    ) : (
                        <div className="text-white-800 flex justify-center">{t("noAddresses")}</div>
                    )}
                </div>
                <button className="text-left text-xs text-blue-700" onClick={handleSidebar}>
                    + {t("addNewAddress")}
                </button>
            </div>

            <AmountInput
                asset={selectedAsset}
                onChange={({ baseValue, USDValue }) => {
                    setBaseAmount(baseValue);
                    setUSDAmount(USDValue);
                }}
                onError={setIsError}
            />

            <Checkbox
                isChecked={confirmChecked}
                onChange={(value) => {
                    setConfirmChecked(value);
                }}
                className={`text-white-1100 text-xs ${confirmChecked ? "opacity-100" : "opacity-50"}`}
            >
                {t("confirmWithdrawal")}
            </Checkbox>

            {selectedChain?.symbol === "AVAX" && <div className="text-yellow-1000 text-xs">{t("avaxWarning")}</div>}

            <Button
                color="green"
                variant="primary"
                className="w-full"
                onClick={handleSubmit}
                disabled={isSubmitDisabled}
                isLoading={isPending}
            >
                {t("submitWithdrawal")}
            </Button>
        </div>
    );
}
