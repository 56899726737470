"use client";
import { useTranslations } from "next-intl";
import { useState } from "react";
import { FiCopy } from "react-icons/fi";
export const Copy = ({
    value,
    className,
    tooltipText,
    useText,
}: {
    value: string;
    className?: string;
    tooltipText: string;
    useText?: string;
}) => {
    const [tooltipContent, setTooltipContent] = useState(tooltipText);
    const t = useTranslations("CopyButton");
    const handleCopy = async (event: React.MouseEvent | React.KeyboardEvent) => {
        event?.stopPropagation();

        try {
            await navigator.clipboard.writeText(value);
            setTooltipContent(t("copied"));
            setTimeout(() => setTooltipContent(tooltipText), 2000);
        } catch (error) {
            console.error("Failed to copy:", error);
        }
    };

    return (
        <div className={`cursor-pointer ${className ?? ""}`}>
            <button
                type="button"
                onClick={handleCopy}
                data-tooltip-id="tooltip"
                data-tooltip-content={tooltipContent}
                aria-label="Copy to clipboard"
                data-place="top"
            >
                {!useText ? <FiCopy className="outline-hidden select-none" /> : useText}
            </button>
        </div>
    );
};
