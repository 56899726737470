"use client";
import { useWebsocketClient } from "@/app/_contexts/websocket";
import { Position, UserInfoResponse } from "@/app/_hooks/types";
import { useEffect } from "react";
import { create } from "zustand";
import { useSubaccount } from "./useSubaccount";

interface PositionsState {
    positions: Map<string, Position>;
    setPositions: (updater: (prev: Map<string, Position>) => Map<string, Position>) => void;
    reset: () => void;
}

const initialState = {
    positions: new Map<string, Position>(),
};

export const usePositionsStore = create<PositionsState>((set) => ({
    ...initialState,
    setPositions: (updater) =>
        set((state) => ({
            positions: updater(state.positions),
        })),
    reset: () => set(initialState),
}));

interface UsePositionsSubscriptionProps {
    user: UserInfoResponse | undefined;
}

export const usePositionsSubscription = ({ userId }: { userId: number }) => {
    const { client, isConnected } = useWebsocketClient();
    const { subaccountId } = useSubaccount();
    const { setPositions, reset } = usePositionsStore();

    useEffect(() => {
        if (!client || !isConnected) {
            return;
        }

        const unsubscribe = client.positions(subaccountId, true, (data: Position | Position[]) => {
            setPositions((prev) => {
                const newPositions = new Map(prev);

                if (Array.isArray(data)) {
                    data.forEach((position) => {
                        if (position.base === "0") {
                            newPositions.delete(position.symbol);
                        } else {
                            newPositions.set(position.symbol, position);
                        }
                    });
                } else {
                    if (data.base === "0" && data.openBuySize === "0" && data.openSellSize === "0") {
                        newPositions.delete(data.symbol);
                    } else {
                        newPositions.set(data.symbol, data);
                    }
                }
                return newPositions;
            });
        });

        return () => {
            unsubscribe();
            reset();
        };
    }, [client, isConnected, userId, subaccountId, setPositions, reset]);
};

export const usePositions = () => {
    return usePositionsStore((state) => state.positions);
};
