import { ModalNames, useModal, WithdrawalSuccessModalData } from "@/app/_contexts/overlay";
import { Link } from "@arkham/i18n";
import { Button } from "@arkham/ui-components";
import Image from "next/image";
import { BiCheckCircle } from "react-icons/bi";
import { Address } from "../../ui/elements/Address";
import { FormattedNumber } from "../../ui/number/FormattedNumber";

export function WithdrawalSuccessModal({ data }: { data: WithdrawalSuccessModalData }) {
    const { closeModal } = useModal();

    const formatDate = () => {
        const date = new Date();
        return date.toLocaleString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
        });
    };

    return (
        <div className="flex w-96 min-w-96 flex-col gap-5 p-6">
            <div className="flex items-center gap-3">
                <BiCheckCircle className="text-5xl text-green-800" />
                <div className="flex flex-col">
                    <div className="text-2xl">Withdrawal Submitted</div>
                    <div className="text-white-900 text-xs">{formatDate()}</div>
                </div>
            </div>
            <div className="bg-black-400 flex flex-col items-center gap-3 rounded-lg py-3">
                <Image src={data.asset.imageUrl} width={43} height={43} alt="Token Icon"></Image>

                <div className="flex flex-col items-center gap-1">
                    <FormattedNumber number={data.withdrawalBaseAmount} className="text-lg" unit={data.asset.symbol} />
                    <FormattedNumber number={data.withdrawalUSDAmount} className="text-xs" unit="USD" />
                </div>
            </div>
            <div className="flex flex-col gap-3 text-xs">
                <div className="flex flex-col gap-3">
                    <div className="flex justify-between">
                        <div>Network</div>
                        <div>{data.address.chain}</div>
                    </div>
                    <div className="flex justify-between">
                        <div>Address</div>
                        <div className="flex gap-2">
                            {data.address.label}

                            <Address className="text-white-800" address={data.address.address}></Address>
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div>Total Amount</div>
                        <FormattedNumber number={data.withdrawalBaseAmount} unit={data.asset.symbol} />
                    </div>
                </div>

                <div className="flex w-full gap-2">
                    <Link
                        href="/wallet/withdrawals"
                        className="w-1/2"
                        onClick={() => closeModal(ModalNames.WithdrawalSuccess)}
                    >
                        <Button color="grey" className="w-full">
                            View withdrawals
                        </Button>
                    </Link>
                    <div className="w-1/2">
                        <Button
                            color="green"
                            className="w-full"
                            onClick={() => closeModal(ModalNames.WithdrawalSuccess)}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
