import styles from "./ProgressBar.module.css";
export function ProgressBar({ currentStage, totalStages }: { currentStage: number; totalStages: number }) {
    const percentage = (currentStage / totalStages) * 100;
    return (
        <div className="bg-white-200 h-px min-h-px w-full">
            <div
                style={{ width: `${percentage}%` }}
                className={`h-px transition-all duration-500 ${styles.progressBar}`}
            ></div>
        </div>
    );
}
