"use client";
import { useBalances } from "@/app/_hooks/useBalances";
import { useMargin } from "@/app/_hooks/useMargin";
import { usePositions } from "@/app/_hooks/usePositions";

import { useTicker } from "@/app/_contexts/trades/ticker";
import {
    CreateOrderRequest,
    OrderSide,
    OrderType,
    Pair,
    PairType,
    TriggerPriceType,
    TriggerType,
} from "@/app/_hooks/types";
import { useApiQuery } from "@/app/_hooks/useApi";
import { useLeverage, useMarginSchedule } from "@/app/_hooks/useLeverage";
import { getLiquidationPrice, getPessimisticInitialMargin, getPositionLimit } from "@/app/_hooks/util";
import BigNumber from "bignumber.js";
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from "react";

export const OrderKind = {
    Limit: "limit",
    Market: "market",
    TPSL: "tpsl",
} as const;
export type OrderKind = (typeof OrderKind)[keyof typeof OrderKind];

interface OrderFormContextProps {
    pair: Pair;

    kind: OrderKind;
    setKind: Dispatch<SetStateAction<OrderKind>>;

    side: OrderSide;
    setSide: Dispatch<SetStateAction<OrderSide>>;
    type: OrderType;
    setType: Dispatch<SetStateAction<OrderType>>;
    price: string;
    setPrice: Dispatch<SetStateAction<string>>;
    takeProfitPrice: string;
    setTakeProfitPrice: Dispatch<SetStateAction<string>>;
    stopLossPrice: string;
    setStopLossPrice: Dispatch<SetStateAction<string>>;
    size: string;
    setSize: Dispatch<SetStateAction<string>>;
    notional: string;
    setNotional: Dispatch<SetStateAction<string>>;
    triggerType: TriggerType;
    triggerPriceType: TriggerPriceType;
    setTriggerPriceType: Dispatch<SetStateAction<TriggerPriceType>>;
    triggerPrice: string;
    setTriggerPrice: Dispatch<SetStateAction<string>>;
    errors: Record<string, boolean>;
    setErrors: Dispatch<SetStateAction<Record<string, boolean>>>;
    notionalLessFee: string;
    fee: string;

    free: string;
    required: string;
    maxSize: string;
    maxNotional: string;
    liquidationPrice?: string;

    baseFree: string;
    quoteFree: string;

    takeProfitEnabled: boolean;
    setTakeProfitEnabled: Dispatch<SetStateAction<boolean>>;
    stopLossEnabled: boolean;
    setStopLossEnabled: Dispatch<SetStateAction<boolean>>;
    reduceOnly: boolean;
    setReduceOnly: Dispatch<SetStateAction<boolean>>;

    showConfirmModal: boolean;
    setShowConfirmModal: (show: boolean, order: CreateOrderRequest | null) => void;
    orderToConfirm: CreateOrderRequest | null;

    reset: () => void;
}

const OrderFormContext = createContext<OrderFormContextProps | null>(null);

const useNewMargin = (pair: Pair, side: OrderSide, price: BigNumber, size: string) => {
    const leverageMap = useLeverage();
    const marginSchedule = useMarginSchedule(pair);
    const { margin } = useMargin();
    const positionsMap = usePositions();
    const spendableMargin = useMemo(() => {
        let tmp = new BigNumber(margin?.total ?? "0");
        positionsMap.forEach((position) => {
            tmp = tmp.minus(getPessimisticInitialMargin(position, leverageMap));
        });
        return BigNumber.max(0, tmp).toString();
    }, [margin, positionsMap, leverageMap]);

    const { balances } = useBalances();
    const baseFree = balances.find((asset) => asset.symbol === pair.baseSymbol)?.free ?? "0";
    const quoteFree = balances.find((asset) => asset.symbol === pair.quoteSymbol)?.free ?? "0";
    if (pair.pairType === PairType.Spot) {
        if (side === "buy") {
            return {
                leverage: "1",
                free: quoteFree,
                required: "0",
                maxSize: price.isZero() ? "0" : new BigNumber(quoteFree).dividedBy(price).toString(),
                maxNotional: quoteFree,
                liquidationPrice: "0",
                baseFree,
                quoteFree,
            };
        } else {
            return {
                leverage: "1",
                free: baseFree,
                required: "0",
                maxSize: baseFree,
                maxNotional: new BigNumber(baseFree).multipliedBy(price).toString(),
                liquidationPrice: "0",
                baseFree,
                quoteFree,
            };
        }
    } else {
        const position = positionsMap.get(pair.symbol);
        const leverage = leverageMap.get(pair.symbol) ?? "1";

        const positionLimit = getPositionLimit(marginSchedule, leverage) || new BigNumber("0");

        const longExposure = new BigNumber(position?.openBuyNotional ?? "0").plus(position?.value ?? "0");
        const shortExposure = new BigNumber(position?.openSellNotional ?? "0").minus(position?.value ?? "0");
        const maxExposure = BigNumber.max(longExposure, shortExposure);
        const usedPositionLimit = side === "buy" ? longExposure : shortExposure;

        // how much notional do we get for free to reverse the position?
        const slack = maxExposure.minus(side === "buy" ? longExposure : shortExposure);

        const availableNotional = new BigNumber(spendableMargin).multipliedBy(leverage);

        // How much can we buy/sell with before we hit our position limit?
        const availableLimit = BigNumber.max(0, positionLimit.minus(usedPositionLimit));

        const maxNotional = BigNumber.min(availableNotional.plus(slack), availableLimit);

        const orderNotional = new BigNumber(size).multipliedBy(price);

        const liquidationPrice = getLiquidationPrice(
            margin,
            position && {
                ...position,
                base:
                    side === "buy"
                        ? new BigNumber(position?.base ?? "0").plus(size).toString()
                        : new BigNumber(position?.base ?? "0").minus(size).toString(),
                quote:
                    side === "buy"
                        ? new BigNumber(position?.quote ?? "0").minus(orderNotional).toString()
                        : new BigNumber(position?.quote ?? "0").plus(orderNotional).toString(),
            },
            marginSchedule,
        );

        return {
            free: spendableMargin.toString(),
            required: BigNumber.max(0, orderNotional.minus(slack).dividedBy(leverage ?? "1")).toString(),
            maxSize: maxNotional.dividedBy(price).toString(),
            maxNotional: maxNotional.toString(),
            liquidationPrice: liquidationPrice,
            baseFree,
            quoteFree,
        };
    }
};

export const OrderFormProvider = ({ pair, children }: { pair: Pair; children: ReactNode }) => {
    const feeRes = useApiQuery("/account/fees");

    const [kind, setKind] = useState<OrderKind>(OrderKind.Market);

    const [side, setSide] = useState<OrderSide>("buy");
    const [type, setType] = useState<OrderType>(OrderType.LimitGtc);

    const [price, setPrice] = useState<string>("0");
    const [takeProfitPrice, setTakeProfitPrice] = useState<string>("");
    const [stopLossPrice, setStopLossPrice] = useState<string>("");
    const [size, setSize] = useState<string>("0");

    const [notional, setNotional] = useState<string>("0");

    const [triggerType, setTriggerType] = useState<TriggerType>(TriggerType.None);
    const [triggerPriceType, setTriggerPriceType] = useState<TriggerPriceType>(TriggerPriceType.MarkPrice);
    const [triggerPrice, setTriggerPrice] = useState<string>("0");

    const [errors, setErrors] = useState({});

    const [takeProfitEnabled, setTakeProfitEnabled] = useState<boolean>(false);
    const [stopLossEnabled, setStopLossEnabled] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [orderToConfirm, setOrderToConfirm] = useState<CreateOrderRequest | null>(null);
    const [reduceOnly, setReduceOnly] = useState<boolean>(false);
    const ticker = useTicker();
    const estimatedPrice = type === OrderType.Market ? new BigNumber(ticker?.indexPrice ?? "0") : new BigNumber(price);

    const { free, maxSize, maxNotional, required, liquidationPrice, baseFree, quoteFree } = useNewMargin(
        pair,
        side,
        estimatedPrice,
        size,
    );

    const appliedFee =
        pair.pairType === PairType.Spot ? (feeRes.data?.spotTakerFee ?? "0") : (feeRes.data?.perpTakerFee ?? "0");
    const fee = new BigNumber(notional).multipliedBy(appliedFee).toString();
    const notionalLessFee = new BigNumber(notional).minus(new BigNumber(fee)).toString();

    const handleShowConfirmModal = (show: boolean, order: CreateOrderRequest | null) => {
        setShowConfirmModal(show);
        setOrderToConfirm(order);
    };

    const reset = () => {
        setPrice("0");
        setTakeProfitPrice("");
        setStopLossPrice("");
        setSize("0");
        setNotional("0");
        setTriggerType(TriggerType.None);
        setTriggerPriceType(TriggerPriceType.MarkPrice);
        setTriggerPrice("0");
        setErrors({});
        setTakeProfitEnabled(false);
        setStopLossEnabled(false);
        setShowConfirmModal(false);
        setOrderToConfirm(null);
    };

    // Sets trigger price type based on pair type.
    useEffect(() => {
        if (pair.pairType === PairType.Spot) {
            setTriggerPriceType(TriggerPriceType.LastPrice);
        } else {
            setTriggerPriceType(TriggerPriceType.MarkPrice);
        }
    }, [pair.pairType]);

    // Sets order type based on tab selected.
    useEffect(() => {
        if (kind === OrderKind.Limit) {
            setType(OrderType.LimitGtc);
            setTriggerType(TriggerType.None);
        } else if (kind === OrderKind.Market) {
            setType(OrderType.Market);
            setTriggerType(TriggerType.None);
        } else if (kind === OrderKind.TPSL) {
            setType(OrderType.Market);
            setTriggerType(TriggerType.StopLoss);
        }
    }, [kind]);

    // Sets whether trigger type is take profit or stop loss.
    useEffect(() => {
        if (triggerType !== TriggerType.None) {
            const triggerPriceNumber = new BigNumber(triggerPrice);
            setTriggerType(
                (side === "buy") === triggerPriceNumber.isLessThan(estimatedPrice)
                    ? TriggerType.TakeProfit
                    : TriggerType.StopLoss,
            );
        }
    }, [side, triggerPrice]);

    return (
        <OrderFormContext.Provider
            value={{
                pair,

                kind,
                setKind,
                side,
                setSide,
                type,
                setType,
                price,
                setPrice,
                takeProfitPrice,
                setTakeProfitPrice,
                stopLossPrice,
                setStopLossPrice,
                size,
                setSize,
                notional,
                setNotional,
                triggerType,
                triggerPriceType,
                setTriggerPriceType,
                triggerPrice,
                setTriggerPrice,
                errors,
                setErrors,
                notionalLessFee,
                fee,

                free,
                required,
                maxSize,
                maxNotional,
                liquidationPrice,

                baseFree,
                quoteFree,

                takeProfitEnabled,
                setTakeProfitEnabled,
                stopLossEnabled,
                setStopLossEnabled,
                reduceOnly,
                setReduceOnly,
                showConfirmModal,
                setShowConfirmModal: handleShowConfirmModal,
                orderToConfirm,

                reset,
            }}
        >
            {children}
        </OrderFormContext.Provider>
    );
};

export const useOrderFormContext = () => {
    const props = useContext(OrderFormContext);
    if (!props) {
        throw new Error("useOrderFormContext must be used within a OrderFormProvider");
    }
    return props as OrderFormContextProps;
};
