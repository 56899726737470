import { AssetWithBalance } from "@/app/_hooks/types";
import { useTickers } from "@/app/_hooks/useFetch";
import BigNumber from "bignumber.js";
import { useTranslations } from "next-intl";
import { useEffect, useMemo, useState } from "react";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { countDecimalPlaces } from "../../trade/orderform/utils";
import { FormattedNumber } from "../../ui/number/FormattedNumber";
import { PrivacyNumber } from "../../ui/number/PrivacyNumber";

interface AmountInputProps {
    onChange: (values: { baseValue: string; USDValue: string }) => void;
    onError: (isError: boolean) => void;
    asset: AssetWithBalance;
}

export const AmountInput = ({ onChange, onError, asset }: AmountInputProps) => {
    const t = useTranslations("AmountInput");
    const { tickers } = useTickers();
    const assetTicker = tickers.find((ticker) => ticker.symbol === asset.featuredPair);
    const indexPrice = assetTicker?.indexPrice || "0";

    const [value, setValue] = useState("");
    const [isNotional, setIsNotional] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    const calculateAmounts = (inputValue: string, isNotionalValue: boolean) => {
        if (!inputValue) return { amountBase: "0", amountNotional: "0" };
        const amount = new BigNumber(inputValue);

        if (asset.stablecoin) {
            return { amountBase: amount.toString(10), amountNotional: amount.toString(10) };
        }

        return isNotionalValue
            ? {
                  amountBase: amount.dividedBy(indexPrice).decimalPlaces(6, BigNumber.ROUND_DOWN).toString(10),
                  amountNotional: amount.toString(),
              }
            : {
                  amountBase: amount.toString(),
                  amountNotional: amount.multipliedBy(indexPrice).decimalPlaces(6, BigNumber.ROUND_DOWN).toString(10),
              };
    };

    const getInputError = () => {
        if (!value) return t("pleaseEnterAmount");

        const amount = new BigNumber(value);
        if (amount.lte(0)) return t("amountMustBeGreaterThan0");

        const { amountBase } = calculateAmounts(value, isNotional);
        const baseAmount = new BigNumber(amountBase);

        if (baseAmount.gt(new BigNumber(asset.balance))) return t("insufficientBalance");

        const decimalPlaces = value.includes(".") ? value.split(".")[1].length : 0;
        if (isNotional) {
            if (decimalPlaces > 2) return t("max2DecimalPlacesUSD");
        } else {
            if (decimalPlaces > 6) return t("max6DecimalPlaces");
        }

        return "";
    };

    const { amountBase, amountNotional } = useMemo(
        () => calculateAmounts(value, isNotional),
        [value, isNotional, asset.stablecoin, indexPrice],
    );

    const inputError = getInputError();
    const shouldShowInputError = showErrors && inputError;

    useEffect(() => {
        if (inputError) {
            onError(true);
        } else {
            onError(false);
        }
    }, [inputError, showErrors, onError]);

    const onSetMax = () => {
        setIsNotional(false);
        setShowErrors(true);
        const maxBalance = new BigNumber(asset.balance).minus(new BigNumber(asset.withdrawalFee));
        const truncatedBalance = maxBalance.decimalPlaces(6, BigNumber.ROUND_DOWN).toString();
        setValue(truncatedBalance);
        const amounts = calculateAmounts(truncatedBalance, false);
        onChange({ baseValue: amounts.amountBase, USDValue: amounts.amountNotional });
    };

    const handleAmountChange = (newValue: string) => {
        if (newValue === "" || /^\d*\.?\d*$/.test(newValue)) {
            setShowErrors(true);
            setValue(newValue);
            const amounts = calculateAmounts(newValue, isNotional);
            onChange({ baseValue: amounts.amountBase, USDValue: amounts.amountNotional });
        }
    };

    const swapIsNotional = () => {
        setIsNotional(!isNotional);
        if (value) {
            const amounts = calculateAmounts(value, !isNotional);
            onChange({ baseValue: amounts.amountBase, USDValue: amounts.amountNotional });
        }
    };

    return (
        <div className="flex flex-col items-start gap-1">
            <div className="text-white-800">Amount</div>
            <div className="flex w-full flex-col">
                <div
                    className={`border-slate-blue-800 bg-slate-blue-300 flex w-full flex-col rounded border border-solid p-2 ${
                        shouldShowInputError ? "border-red-900" : ""
                    }`}
                >
                    <div className="flex items-center">
                        <input
                            type="text"
                            value={value}
                            placeholder="0"
                            onChange={(e) => handleAmountChange(e.target.value)}
                            className="placeholder:text-white-700 w-full appearance-none rounded-sm border bg-transparent focus:border-transparent focus:outline-hidden"
                        />
                        <div className="text-gray-1000 ml-2">{isNotional ? "USD" : asset.symbol}</div>
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-1 text-xs">
                            <LiaExchangeAltSolid
                                onClick={swapIsNotional}
                                className="rotate-90 cursor-pointer text-blue-700 select-none"
                            />
                            <div className="text-white-800">
                                <span>≈</span>
                                <FormattedNumber
                                    number={isNotional ? amountBase : amountNotional}
                                    unit={isNotional ? asset.symbol : "USD"}
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-1 text-xs">
                            <FormattedNumber number="1" unit={asset.symbol} />
                            {"="}
                            <FormattedNumber
                                number={asset.stablecoin ? "1" : indexPrice}
                                unit={asset.stablecoin ? "USD" : "USD"}
                            />
                        </div>
                    </div>
                </div>

                {shouldShowInputError && (
                    <div className="bg-red-a100 w-full rounded-b px-2 py-1 text-center text-sm text-red-900">
                        {inputError}
                    </div>
                )}
            </div>
            <div className="flex w-full justify-center gap-1 text-xs">
                <button className="cursor-pointer text-blue-700" onClick={onSetMax}>
                    {t("max")}
                </button>
                <div className="text-white-600">{t("availableBalance")}:</div>
                <PrivacyNumber
                    className="ml-1"
                    number={asset.balance}
                    unit={asset.symbol}
                    options={{ fractionDigits: 6 }}
                />
            </div>
            <div className="flex w-full justify-center gap-1 text-xs">
                <div className="text-white-600">{t("withdrawalFee")}:</div>
                <FormattedNumber
                    number={asset.withdrawalFee}
                    unit={asset.symbol}
                    options={{ fractionDigits: countDecimalPlaces(asset.withdrawalFee) }}
                />
            </div>
        </div>
    );
};
