import { ModalNames, TransferSuccessModalData, useModal } from "@/app/_contexts/overlay";
import { Button } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { BiCheckCircle } from "react-icons/bi";
import { FormattedNumber } from "../../ui/number/FormattedNumber";

export function TransferSuccessModal({ data }: { data: TransferSuccessModalData }) {
    const t = useTranslations("TransferSuccessModal");
    const { closeModal } = useModal();

    const formatDate = () => {
        const date = new Date();
        return date.toLocaleString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
        });
    };

    return (
        <div className="flex w-96 min-w-96 flex-col gap-5 p-6">
            <div className="flex items-center gap-3">
                <BiCheckCircle className="text-5xl text-green-800" />
                <div className="flex flex-col">
                    <div className="text-2xl">{t("transferSuccessful")}</div>
                    <div className="text-white-900 text-xs">{formatDate()}</div>
                </div>
            </div>
            <div className="bg-black-400 flex flex-col items-center gap-3 rounded-lg py-3">
                <Image src={data.asset.imageUrl} width={43} height={43} alt="Token Icon"></Image>

                <div className="flex flex-col items-center gap-1">
                    <FormattedNumber number={data.amount} className="text-lg" unit={data.asset.symbol} />
                    <FormattedNumber number={data.usdValue} className="text-xs" unit="USD" />
                </div>
            </div>
            <div className="flex flex-col gap-3 text-xs">
                <div className="flex flex-col gap-3">
                    <div className="flex justify-between">
                        <div>{t("from")}</div>
                        <div>{data.subaccountFrom}</div>
                    </div>
                    <div className="flex justify-between">
                        <div>{t("to")}</div>
                        <div>{data.subaccountTo}</div>
                    </div>
                    <div className="flex justify-between">
                        <div>{t("transferAmount")}</div>
                        <FormattedNumber number={data.amount} unit={data.asset.symbol} />
                    </div>
                </div>
                <Button color="green" onClick={() => closeModal(ModalNames.TransferSuccess)}>
                    {t("close")}
                </Button>
            </div>
        </div>
    );
}
