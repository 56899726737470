import { useSettings } from "@/app/_hooks/useSettings";
import { VscEye, VscEyeClosed } from "react-icons/vsc";

export function ToggleBalanceShown({
    className = "",
    label,
    labelPosition = "before",
}: {
    className?: string;
    label?: string;
    labelPosition?: "before" | "after";
}) {
    const { settings, updateSetting } = useSettings();
    const displayBalance = settings?.hideBalances;

    const toggleBalanceShown = () => {
        updateSetting("hideBalances", !settings?.hideBalances);
    };

    const Icon = displayBalance ? VscEyeClosed : VscEye;

    if (label) {
        return (
            <button
                type="button"
                className={`flex cursor-pointer items-center gap-1.5 select-none ${className}`}
                onClick={toggleBalanceShown}
                aria-pressed={!displayBalance}
            >
                {labelPosition === "before" && label}
                <Icon className={`cursor-pointer select-none ${className}`} aria-hidden="true" />
                {labelPosition === "after" && label}
            </button>
        );
    }

    return (
        <button
            type="button"
            className={`flex cursor-pointer items-center justify-center select-none ${className}`}
            onClick={toggleBalanceShown}
            aria-pressed={!displayBalance}
            aria-label={displayBalance ? "Show balance" : "Hide balance"}
        >
            <Icon aria-hidden="true" />
        </button>
    );
}
