import {
    autoUpdate,
    flip,
    FloatingFocusManager,
    FloatingPortal,
    offset,
    size,
    useDismiss,
    useFloating,
    useInteractions,
} from "@floating-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

interface ComboBoxProps {
    children: React.ReactNode;
    value: string;
    onInputChange: (value: string) => void;
    required?: boolean;
    placeholder?: string;
    renderSelected?: React.ReactNode;
    resetSelected?: () => void;
}

export const ComboBox = ({
    children,
    value,
    onInputChange,
    required,
    placeholder = "Select an option",
    resetSelected,
    renderSelected,
}: ComboBoxProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const { refs, floatingStyles, context } = useFloating({
        whileElementsMounted: autoUpdate,
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            offset({ mainAxis: 8, crossAxis: 0 }),
            flip({ padding: 10 }),
            size({
                apply({ rects, elements }) {
                    Object.assign(elements.floating.style, {
                        width: `${rects.reference.width}px`,
                    });
                },
                padding: 10,
            }),
        ],
    });

    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onInputChange(event.target.value);
    };

    useEffect(() => {
        setIsOpen(false);
    }, [renderSelected]);

    const handleOpen = () => {
        setIsOpen(true);
        if (renderSelected && resetSelected) {
            resetSelected();
        }
        requestAnimationFrame(() => {
            inputRef.current?.focus();
        });
    };

    const toggleDropdown = (event: React.MouseEvent) => {
        event.stopPropagation();
        if (!isOpen) {
            handleOpen();
        } else {
            setIsOpen(false);
        }
    };

    const hasValidChildren = React.Children.count(children) > 0;

    return (
        <>
            <div
                ref={refs.setReference}
                className={`flex cursor-pointer items-center justify-between rounded border border-solid ${
                    isOpen ? "border-blue-700" : "border-white-200"
                } bg-ash-300`}
                {...getReferenceProps()}
            >
                {renderSelected ? (
                    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events -- todo: fix */
                    <div
                        className="flex h-full w-full items-center pl-2"
                        onClick={handleOpen}
                        onFocus={handleOpen}
                        tabIndex={0}
                    >
                        {renderSelected}
                    </div>
                ) : (
                    <input
                        ref={inputRef}
                        className="placeholder:text-white-500 h-full w-full bg-transparent pl-2 outline-hidden"
                        required={required}
                        value={value}
                        onChange={handleInputChange}
                        placeholder={placeholder}
                        onFocus={handleOpen}
                    />
                )}

                <button
                    className="text-gray-1000 hover:bg-ash-500 cursor-pointer rounded-r p-2 select-none"
                    onMouseDown={toggleDropdown}
                    tabIndex={-1}
                >
                    {isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </button>
            </div>
            <FloatingPortal>
                {isOpen && hasValidChildren && (
                    <FloatingFocusManager
                        context={context}
                        initialFocus={-1}
                        order={["reference", "content"]}
                        returnFocus={false}
                        modal={false}
                    >
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events -- todo: fix */}
                        <ul
                            onClick={() => setIsOpen(false)}
                            className="border-white-100 bg-ash-800 z-50 flex max-h-64 flex-col overflow-auto rounded-sm border border-solid px-1 py-2 shadow-lg backdrop-blur-xl"
                            {...getFloatingProps({
                                ref: refs.setFloating,
                                style: floatingStyles,
                            })}
                        >
                            {children}
                        </ul>
                    </FloatingFocusManager>
                )}
            </FloatingPortal>
        </>
    );
};
