import { ComboBox } from "@/app/_components/ui/elements/dropdown/Combobox";
import { AssetRow } from "@/app/_components/ui/elements/select/AssetRow";
import { Asset, AssetWithBalance, Chain, ChainType } from "@/app/_hooks/types";
import { useApiPost } from "@/app/_hooks/useApi";
import { useAssets } from "@/app/_hooks/useFetch";
import { Button, Select, useToast } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { useState } from "react";
import { Input } from "../../../ui/elements/Input";
import { ChainRow } from "../../../ui/elements/select/ChainRow";
import { isValidAddress } from "../../../ui/utils";
import { AddressDetails } from "./AddressManagementSidebar";

export function AddressInputStage({
    onNext,
    openAtAsset,
    openAtChain,
}: {
    onNext: (details: AddressDetails) => void;
    openAtAsset?: AssetWithBalance;
    openAtChain?: Chain;
}) {
    const t = useTranslations("AddressInputStage");
    const { assets } = useAssets();

    const showToast = useToast();
    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(openAtAsset || null);
    const [selectedChain, setSelectedChain] = useState<Chain | null>(openAtChain || null);
    const [address, setAddress] = useState<string>("");
    const [memo, setMemo] = useState<string>("");
    const [addressName, setAddressName] = useState<string>("");
    const [assetSearchValue, setAssetSearchValue] = useState<string>("");
    const [addressError, setAddressError] = useState<string>("");

    const isTon = selectedAsset?.symbol === "TON";
    const isXRP = selectedAsset?.symbol === "XRP";

    const { mutate: submitWithdrawalAddress, isPending } = useApiPost(
        "/account/withdrawal/addresses",
        {
            onSuccess: (resp, data) => {
                showToast(t("addressAddedPendingVerification"), { type: "warning" });
                onNext({
                    addressId: resp,
                    address: data.address,
                    chain: data.chain,
                    label: data.label,
                });
            },
            onError: (error, message) => {
                showToast(`${error.data.message} ${message.address}`, { type: "error" });
            },
        },
        () => [["/account/withdrawal/addresses"]],
    );

    const handleSubmit = () => {
        if (selectedChain) {
            if (isTon) {
                submitWithdrawalAddress({
                    address: address,
                    chain: selectedChain.symbol,
                    label: addressName,
                    ...(memo && { memo: parseInt(memo) }),
                });
            } else if (isXRP) {
                submitWithdrawalAddress({
                    address: address,
                    chain: selectedChain.symbol,
                    label: addressName,
                    ...(memo && { memo: parseInt(memo) }),
                });
            } else {
                submitWithdrawalAddress({
                    address: address,
                    chain: selectedChain.symbol,
                    label: addressName,
                });
            }
        }
    };

    const validateAddress = (address: string, chainType: number) => {
        if (!address) {
            setAddressError(t("addressError"));
            return false;
        }

        const isValid = isValidAddress(address, chainType as ChainType);
        if (!isValid) {
            switch (chainType) {
                case ChainType.EVM:
                    setAddressError(t("invalidAddressFormat", { token: "EVM" }));
                    break;
                case ChainType.Bitcoin:
                    setAddressError(t("invalidAddressFormat", { token: "Bitcoin" }));
                    break;
                case ChainType.Tron:
                    setAddressError(t("invalidAddressFormat", { token: "Tron" }));
                    break;
                case ChainType.Solana:
                    setAddressError(t("invalidAddressFormat", { token: "Solana" }));
                    break;
                case ChainType.Near:
                    setAddressError(t("invalidAddressFormat", { token: "Near" }));
                    break;
                case ChainType.Ton:
                    setAddressError(t("invalidAddressFormat", { token: "TON" }));
                    break;
                case ChainType.Doge:
                    setAddressError(t("invalidAddressFormat", { token: "Doge" }));
                    break;
                case ChainType.Sui:
                    setAddressError(t("invalidAddressFormat", { token: "SUI" }));
                    break;
                case ChainType.Ripple:
                    setAddressError(t("invalidAddressFormat", { token: "Ripple" }));
                    break;
                default:
                    setAddressError(t("invalidAddressFormat", { token: "" }));
            }
            return false;
        }

        setAddressError("");
        return true;
    };

    const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAddress = e.target.value;
        setAddress(newAddress);

        if (selectedChain) {
            validateAddress(newAddress, selectedChain.type);
        } else {
            setAddressError("");
        }
    };

    const handleAssetSelect = (asset: Asset) => {
        setSelectedAsset(asset);
        setSelectedChain(null);
        setAssetSearchValue("");
        setAddressError("");
    };

    const handleChainSelect = (chain: Chain) => {
        setSelectedChain(chain);
        if (address) {
            validateAddress(address, chain.type);
        }
    };

    const filteredAssets =
        assetSearchValue.trim() === ""
            ? assets
            : assets?.filter(
                  (asset) =>
                      asset.symbol.toLowerCase().includes(assetSearchValue.toLowerCase()) ||
                      asset.name.toLowerCase().includes(assetSearchValue.toLowerCase()),
              );

    const resetSelected = () => {
        setSelectedAsset(null);
        setSelectedChain(null);
    };

    return (
        <div className="flex flex-col gap-6 p-6">
            <div className="flex flex-col gap-5">
                <div className="flex flex-col gap-2">
                    <div className="text-white-1000 text-xs font-thin">{t("selectAsset")}</div>
                    <ComboBox
                        value={assetSearchValue || ""}
                        onInputChange={setAssetSearchValue}
                        placeholder={t("searchAssets")}
                        resetSelected={resetSelected}
                        renderSelected={selectedAsset ? <SelectedAssetDisplay asset={selectedAsset} /> : null}
                    >
                        {filteredAssets?.map((asset) => (
                            <AssetRow key={asset.symbol} asset={asset} onSelect={handleAssetSelect} />
                        ))}
                    </ComboBox>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="text-white-1000 text-xs font-thin">{t("selectNetwork")}</div>
                    <Select<string>
                        options={
                            selectedAsset?.chains.map((chain) => ({
                                value: chain.symbol,
                                label: chain.name,
                                render: (option) => (
                                    <ChainRow
                                        chain={chain}
                                        asset={selectedAsset}
                                        minAmount={selectedAsset.minWithdrawal}
                                        fee={selectedAsset.withdrawalFee}
                                    />
                                ),
                            })) || []
                        }
                        value={selectedChain?.symbol}
                        onValueChange={(value: string) => {
                            const chain = selectedAsset?.chains.find((c) => c.symbol === value);
                            if (chain) handleChainSelect(chain);
                        }}
                        placeholder={t("selectNetwork")}
                        type="opaque"
                        className="w-full"
                        renderValue={(value, options) => {
                            const option = options.find((o) => o.value === value);
                            return option ? <div className="text-white-1200">{option.label}</div> : null;
                        }}
                        onEmpty={
                            !selectedAsset ? (
                                <div className="text-white-800 p-2">{t("selectAssetFirst")}</div>
                            ) : undefined
                        }
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <div className="text-white-1000 text-xs font-thin">{t("address")}</div>
                    <Input
                        type="text"
                        placeholder={t("addressPlaceholder")}
                        value={address}
                        onChange={handleAddressChange}
                        bgColor="bg-ash-300"
                        borderColor={"border-white-200"}
                        textColor="text-white-1000"
                        error={addressError}
                        disabled={!selectedChain}
                    />
                </div>
                {isTon && (
                    <div className="flex flex-col gap-2">
                        <div className="text-white-1000 text-xs font-thin">{t("memo")}</div>
                        <Input
                            type="text"
                            placeholder={t("memoPlaceholder")}
                            value={memo}
                            onChange={(e) => setMemo(e.target.value)}
                            bgColor="bg-ash-300"
                            borderColor={"border-white-200"}
                            textColor="text-white-1000"
                            disabled={!selectedChain}
                        />
                    </div>
                )}
                {isXRP && (
                    <div className="flex flex-col gap-2">
                        <div className="text-white-1000 text-xs font-thin">{t("destinationTag")}</div>
                        <Input
                            type="text"
                            placeholder={t("destinationTagPlaceholder")}
                            value={memo}
                            onChange={(e) => setMemo(e.target.value)}
                            bgColor="bg-ash-300"
                            borderColor={"border-white-200"}
                            textColor="text-white-1000"
                            disabled={!selectedChain}
                        />
                    </div>
                )}
                <div className="flex flex-col gap-2">
                    <div className="text-white-1000 text-xs font-thin">{t("addressName")}</div>
                    <Input
                        type="text"
                        placeholder={t("addressNamePlaceholder")}
                        value={addressName}
                        onChange={(e) => setAddressName(e.target.value)}
                        bgColor="bg-ash-300"
                        borderColor={"border-white-200"}
                        textColor="text-white-1000"
                    />
                </div>
            </div>
            <div>
                <Button
                    onClick={handleSubmit}
                    color="blue"
                    className="w-full"
                    disabled={
                        !selectedAsset || !selectedChain || !address.trim() || !addressName.trim() || !!addressError
                    }
                    isLoading={isPending}
                >
                    {t("continueToVerification")}
                </Button>
            </div>
        </div>
    );
}

const SelectedAssetDisplay = ({ asset }: { asset: Asset }) => {
    return (
        <div className="flex items-center gap-1.5">
            <Image src={asset.imageUrl} width={16} height={16} alt="Asset Image" />
            <div className="text-xs font-medium">{asset.symbol}</div>
            <div className="text-white-900 text-xs">{asset.name}</div>
        </div>
    );
};
