"use client";
import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { Subaccount } from "@/app/_hooks/types";
import { useSubaccountTotalValues } from "@/app/_hooks/useBalances";
import { useSubaccount, useSubaccountMutations } from "@/app/_hooks/useSubaccount";
import React, { useEffect, useRef, useState } from "react";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaTrash } from "react-icons/fa";
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from "react-icons/io";
import { MdOutlinePushPin, MdPushPin } from "react-icons/md";
import { TruncateText } from "../../ui/elements/Truncate";
import { PrivacyNumber } from "../../ui/number/PrivacyNumber";
import { NewSubaccountInput } from "./NewSubaccountInput";

export function SubaccountRow({
    subaccount,
    isManageMode = false,
    "data-testid": dataTestId,
    showId = false,
}: {
    subaccount: Subaccount;
    isManageMode?: boolean;
    "data-testid"?: string;
    showId?: boolean;
}) {
    const { setSubaccountId, subaccountId } = useSubaccount();
    const { updateSubaccount } = useSubaccountMutations();
    const [isEditing, setIsEditing] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const { openModal } = useModal();
    const { subaccountTotalValues, isLoading } = useSubaccountTotalValues();

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditing]);

    useEffect(() => {
        if (!isManageMode) {
            setIsEditing(false);
        }
    }, [isManageMode]);

    const togglePinnedSubaccount = (event: React.MouseEvent) => {
        event.stopPropagation();
        if (subaccount.id !== 0) {
            updateSubaccount({
                id: subaccount.id,
                pinned: !subaccount.pinned,
            });
        }
    };

    function handleEditClick(event: React.MouseEvent) {
        event.stopPropagation();
        setIsEditing(true);
    }

    function handleDelete(event: React.MouseEvent) {
        openModal(ModalNames.DeleteSubaccountConfirm, subaccount);
        event.stopPropagation();
    }

    function handleKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Enter" || event.key === " ") {
            setSubaccountId(subaccount.id);
        }
    }

    return (
        <div
            className="hover-blue relative z-10 flex cursor-pointer items-center justify-between gap-3 rounded-sm px-3 py-2 text-sm"
            key={subaccount.id}
            onClick={() => setSubaccountId(subaccount.id)}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
            data-testid={`${dataTestId}-container-${subaccount.name}`}
        >
            <div className="flex items-center justify-center gap-2">
                {showId && isManageMode && <div className="text-gray-1000 text-xs">ID: {subaccount.id}</div>}
                {isManageMode &&
                    subaccount.id !== 0 &&
                    (subaccount.pinned ? (
                        <MdPushPin
                            className="text-white-700"
                            onClick={togglePinnedSubaccount}
                            data-testid={`${dataTestId}-togglepin-${subaccount.name}`}
                        />
                    ) : (
                        <MdOutlinePushPin
                            className="text-white-500 hover:text-white-700 transition-all"
                            onClick={togglePinnedSubaccount}
                            data-testid={`${dataTestId}-togglepin-${subaccount.name}`}
                        />
                    ))}
                {isEditing ? (
                    <NewSubaccountInput type="edit" onClose={() => setIsEditing(false)} subaccount={subaccount} />
                ) : (
                    <TruncateText className="max-w-36" text={subaccount.name} />
                )}
                {isManageMode && !isEditing && subaccount.id !== 0 && (
                    <BiSolidEditAlt
                        className="text-white-500 hover:text-white-700 transition-all duration-100 ease-in-out"
                        onClick={handleEditClick}
                        data-testid={`${dataTestId}-editname-${subaccount.name}`}
                    />
                )}
            </div>
            <div className="flex items-center justify-center gap-3">
                {isLoading ? (
                    "-"
                ) : (
                    <PrivacyNumber
                        number={subaccountTotalValues[subaccount.id] || "0"}
                        options={{
                            style: "currency",
                            currency: "USD",
                        }}
                    />
                )}

                {subaccount.id === subaccountId ? (
                    <IoIosRadioButtonOn className="text-blue-800" />
                ) : (
                    <IoIosRadioButtonOff className="text-blue-800" />
                )}

                {isManageMode && subaccount.id !== 0 && (
                    <FaTrash
                        className="cursor-pointer text-red-400 transition-all duration-100 ease-in-out hover:text-red-800"
                        onClick={handleDelete}
                        data-testid={`${dataTestId}-delete-${subaccount.name}`}
                    />
                )}
            </div>
        </div>
    );
}
