import { Checkbox } from "@/app/_components/ui/buttons/Checkbox";
import { Input } from "@/app/_components/ui/elements/Input";
import { ApiKeyModalData, ModalNames, useModal } from "@/app/_contexts/overlay";
import { ApiKey } from "@/app/_hooks/types";
import { useApiPost, useApiPut } from "@/app/_hooks/useApi";
import { Button, useToast } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";
import { CiWarning } from "react-icons/ci";
import { FiCopy } from "react-icons/fi";

export function ApiKeyModal({ data }: { data: ApiKeyModalData }) {
    if (data.apiKey == null) {
        return <CreateApiKeyModal />;
    }
    return <UpdateApiKeyModal apiKey={data.apiKey} />;
}

const CreateApiKeyModal = () => {
    const t = useTranslations("ApiKeyModal");
    const { closeModal, preventOutsideClickClose } = useModal();
    const [key, setKey] = useState<string>("");
    const [secret, setSecret] = useState<string>("");
    const [confired, setConfirmed] = useState<boolean>(false);

    const showToast = useToast();

    const { mutate: createApiKey } = useApiPost(
        "/api-key/create",
        {
            onSuccess: (data) => {
                setKey(data.key);
                setSecret(data.secret);
                preventOutsideClickClose();
            },
            onError: (error) => {
                showToast(error.data.message, { type: "error" });
            },
        },
        () => [["/api-key"]],
    );

    const copyKey = () => {
        navigator.clipboard.writeText(key);
    };

    const copySecret = () => {
        navigator.clipboard.writeText(secret);
    };

    if (secret.length > 0) {
        return (
            <div className="flex flex-col gap-4 p-3">
                <div className="text-sm font-medium">{t("saveYourApiSecret")}</div>
                <CiWarning style={{ margin: "auto" }} size={60} color="#FFC107" />
                <p>{t("apiKeyAndApiSecretProvidesAccessToYourAccountFunds")}</p>
                <Input value={key} size={40} label="API Key" readOnly>
                    <Button onClick={copyKey} color="blue" variant="tertiary" size="sm">
                        <FiCopy size={18} style={{ margin: "auto" }} />
                    </Button>
                </Input>
                <Input value={secret} size={40} label="API Secret" readOnly>
                    <Button onClick={copySecret} color="blue" variant="tertiary" size="sm">
                        <FiCopy size={18} style={{ margin: "auto" }} />
                    </Button>
                </Input>
                <div className="border-white-200 flex gap-3 border-t pt-3">
                    <input type="checkbox" onChange={(e) => setConfirmed(e.target.checked)} />
                    <span>{t("haveSavedYourApiKeyAndApiSecretInASecureLocation")}</span>
                </div>
                <Button
                    disabled={!confired}
                    color="green"
                    className="w-full"
                    onClick={() => closeModal(ModalNames.ApiKey)}
                >
                    {t("done")}
                </Button>
            </div>
        );
    }

    return <Modal mode="create" submit={(key) => createApiKey(key)} />;
};

const UpdateApiKeyModal = ({ apiKey }: { apiKey: ApiKey }) => {
    const { closeModal } = useModal();
    const showToast = useToast();
    const { mutate: updateApiKey } = useApiPut(
        "/api-key/update/{id}",
        {
            onSuccess: () => {
                closeModal(ModalNames.ApiKey);
                showToast("API Key updated", { type: "success" });
            },
            onError: (error) => {
                showToast(error.data.message, { type: "error" });
            },
        },
        () => [["/api-key"]],
    );

    return <Modal mode="update" submit={(key) => updateApiKey({ ...key, id: apiKey.id })} initialKey={apiKey} />;
};

const Modal = ({
    mode,
    initialKey,
    submit,
}: {
    mode: "create" | "update";
    initialKey?: ApiKey;
    submit: (key: { name: string; read: boolean; write: boolean; ipWhitelist: string[] | null }) => void;
}) => {
    const t = useTranslations("ApiKeyModal");
    const [error, setError] = useState<string | undefined>(undefined);
    const [name, setName] = useState(initialKey?.name ?? "");
    const [read, setRead] = useState<boolean>(initialKey?.read ?? true);
    const [write, setWrite] = useState<boolean>(initialKey?.write ?? true);
    const [ipWhitelist, setIpWhitelist] = useState<string>(initialKey?.ipWhitelist?.join(",") ?? "");

    useEffect(() => {
        if (name.length === 0) {
            setError(t("nameIsRequired"));
        } else if (name.length > 36) {
            setError(t("nameTooLongMax36Characters"));
        } else {
            setError(undefined);
        }
    }, [name]);

    return (
        <div className="flex flex-col gap-4 p-3">
            <div className="text-sm font-medium">{mode === "create" ? t("createNewApiKey") : t("updateApiKey")}</div>

            <span className="text-white-800">{t("name")}</span>
            <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                size={40}
                placeholder={t("keyName")}
                error={error}
            />

            <span className="text-white-800">{t("scopes")}</span>
            <Checkbox isChecked={read} onChange={() => setRead(!read)} className="text-blue-600">
                <span className="text-white-900">{t("read")}</span>
            </Checkbox>
            <Checkbox isChecked={write} onChange={() => setWrite(!write)} className="text-blue-600">
                <span className="text-white-900">{t("write")}</span>
            </Checkbox>

            <div className="flex flex-col gap-2">
                <span className="text-white-800 leading-none">{t("ipWhitelist")}</span>
                <span className="text-white-800 text-xs leading-none">{t("commaSeparated")}</span>
            </div>
            <Input
                value={ipWhitelist}
                onChange={(e) => {
                    setIpWhitelist(e.target.value);
                }}
                size={40}
                placeholder={t("ipWhitelistPlaceholder")}
            />

            <div className="flex justify-end gap-3">
                <Button
                    disabled={error != null}
                    color="green"
                    className="w-full"
                    onClick={() =>
                        submit({ name, read, write, ipWhitelist: ipWhitelist.split(",").filter((ip) => ip.length > 0) })
                    }
                >
                    {mode === "create" ? t("createNewApiKey") : t("updateApiKey")}
                </Button>
            </div>
        </div>
    );
};
