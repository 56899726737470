import React, { HTMLAttributes } from "react";

export const DropdownRow = ({
    children,
    onClick,
    className = "",
    ...props
}: HTMLAttributes<HTMLLIElement> & {
    children: React.ReactNode;
    onClick?: () => void;
    className?: string;
}) => {
    return (
        <li
            className={`hover:bg-white-100 cursor-pointer rounded-sm transition-all ${className}`}
            tabIndex={0}
            onClick={onClick}
            onKeyDown={(event) => {
                if (event.key === "Enter") onClick?.();
            }}
            {...props}
        >
            {children}
        </li>
    );
};
