import { useTranslations } from "next-intl";
import { BiCheckCircle } from "react-icons/bi";
export function ConfirmWithdrawalAddress() {
    return (
        <div>
            <AddressConfirmed />
        </div>
    );
}

export function AddressConfirmed() {
    const t = useTranslations("ConfirmWithdrawalAddress");
    return (
        <div className="mx-auto flex flex-col items-center gap-8 p-4">
            <div>{t("title")}</div>
            <BiCheckCircle className="text-white-500 text-5xl" />
            <div className="flex flex-col items-center">
                <div className="font-thin">{t("subtitle")}</div>
                <div className="font-thin">{t("close")}</div>
            </div>
        </div>
    );
}
