import { Checkbox } from "@/app/_components/ui/buttons/Checkbox";
import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { SubaccountSettingsRequest } from "@/app/_hooks/types";
import { useApiPost } from "@/app/_hooks/useApi";
import { useSubaccount } from "@/app/_hooks/useSubaccount";
import { Button, useToast } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import { useState } from "react";
import { CgClose } from "react-icons/cg";

export function EnableFuturesModal() {
    const t = useTranslations("EnableFuturesModal");
    const { subaccountName } = useSubaccount();
    const [isChecked, setIsChecked] = useState(false);
    const { closeModal } = useModal();
    const showToast = useToast();

    const { mutate: updateSetting, isPending } = useApiPost(
        "/subaccounts/update-settings",
        {
            onSuccess: () => {
                closeModal(ModalNames.EnableFutures);
                showToast(t("futuresTradingEnabled"), { type: "success" });
            },
            onError: (error, message) => {
                console.error("Failed to enable futures", error, message);
                showToast(error.data.message, { type: "error" });
            },
        },
        () => [["/subaccounts"], ["/user"]],
    );
    const { subaccountId } = useSubaccount();

    function handleEnableFutures() {
        updateSetting({ futuresEnabled: true, subaccountId } as unknown as SubaccountSettingsRequest);
    }

    return (
        <div className="flex max-w-160 flex-col gap-6 p-6">
            <div>
                <div className="flex items-start justify-between">
                    <div className="text-3xl">{t("enableFutures")}</div>
                    <button
                        className="-m-1.5 flex cursor-pointer p-1.5"
                        onClick={() => closeModal(ModalNames.EnableFutures)}
                        aria-label="Close modal"
                    >
                        <CgClose />
                    </button>
                </div>
                <div className="text-gray-900">{t("subaccount", { subaccountName })}</div>
            </div>
            <div className="text-xs text-wrap">{t("enableFuturesDescription")}</div>
            <div className="flex flex-col gap-5">
                <Checkbox isChecked={isChecked} onChange={(value) => setIsChecked(value)} className="text-xs">
                    {t("enableFuturesCheckbox")}
                </Checkbox>

                <Button
                    color="green"
                    disabled={!isChecked || isPending}
                    isLoading={isPending}
                    onClick={handleEnableFutures}
                >
                    {t("enableFuturesButton")}
                </Button>
            </div>
        </div>
    );
}
