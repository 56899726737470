"use client";

import { createContext, useContext, useEffect, useState } from "react";

const isBrowser = typeof window !== "undefined";
const INITIAL_SUBACCOUNT = isBrowser ? parseInt(localStorage.getItem("subaccountId") ?? "0") : 0;

const StorageContext = createContext({
    subaccountId: INITIAL_SUBACCOUNT,
    isLoading: true,
    setSubaccountId: (_: number) => {},
});

export const useStorage = () => {
    const context = useContext(StorageContext);
    if (context === undefined) {
        throw new Error("useStorage must be used within a StorageProvider");
    }
    return context;
};

export const StorageProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, setState] = useState({
        subaccountId: INITIAL_SUBACCOUNT,
        isLoading: true,
    });

    useEffect(() => {
        setState((prev) => ({ ...prev, isLoading: false }));

        const listener = (event: StorageEvent) => {
            if (event.key === "subaccountId") {
                setState((prev) => ({
                    ...prev,
                    subaccountId: event.newValue === null ? 0 : parseInt(event.newValue),
                }));
            }
        };
        window.addEventListener("storage", listener);
        return () => window.removeEventListener("storage", listener);
    }, []);

    const setSubaccountId = (subaccountId: number) => {
        setState((prev) => ({ ...prev, subaccountId }));
        if (isBrowser) {
            localStorage.setItem("subaccountId", subaccountId.toString());
        }
    };

    return <StorageContext.Provider value={{ ...state, setSubaccountId }}>{children}</StorageContext.Provider>;
};
