import { MarginScheduleTable } from "@/app/_components/marginschedule/MarginSchedule";
import { RangeSlider } from "@/app/_components/trade/orderform/RangeSlider";
import { FormattedNumber } from "@/app/_components/ui/number/FormattedNumber";
import { ChangeLeverageModalData, ModalNames, useModal } from "@/app/_contexts/overlay";
import { useApiPost } from "@/app/_hooks/useApi";
import { useLeverage, useMarginSchedule } from "@/app/_hooks/useLeverage";
import { usePositions } from "@/app/_hooks/usePositions";
import { useSubaccount } from "@/app/_hooks/useSubaccount";
import { getPositionLimit } from "@/app/_hooks/util";
import { Button, useToast } from "@arkham/ui-components";
import BigNumber from "bignumber.js";
import { useTranslations } from "next-intl";
import { ReactNode, useMemo, useState } from "react";
import { PrivacyNumber } from "../../ui/number/PrivacyNumber";
import styles from "./ChangeLeverage.module.css";

export function ChangeLeverage({ data }: { data: ChangeLeverageModalData }) {
    const t = useTranslations("ChangeLeverageModal");
    const { closeModal } = useModal();
    const showToast = useToast();
    const handleError = (message: ReactNode) => showToast(message, { type: "error" });
    const { subaccountId } = useSubaccount();
    const marginSchedule = useMarginSchedule(data.pair);
    const position = usePositions().get(data.pair.symbol);
    const leverageMap = useLeverage();
    const [leverage, setLeverage] = useState(() => leverageMap.get(data.pair.symbol) ?? "1");

    const { mutate: changeLeverage } = useApiPost(
        "/account/leverage",
        {
            onSuccess: () => {
                closeModal(ModalNames.ChangeLeverage);
            },
            onError: (error) => {
                handleError(error.data.message);
            },
        },
        () => [["/account/leverage"]],
    );

    const positionLimit = useMemo(() => getPositionLimit(marginSchedule, leverage), [marginSchedule, leverage]);

    const positionValue = new BigNumber(position?.value ?? "0");
    const marginReserved = positionValue.dividedBy(leverage ?? "1");

    const invalid = positionLimit?.lt(Math.abs(parseFloat(position?.value ?? "0")));

    const checkPointLabels = useMemo(() => {
        const labels: string[] = [];
        for (let i = 1; i <= parseInt(data.pair.maxLeverage!); i++) {
            labels.push(`${i}x`);
        }
        return labels;
    }, [data.pair.maxLeverage]);
    return (
        <div className={`p-4 ${styles.container}`}>
            <div className={styles.leverage}>{leverage}x</div>
            <div className={styles.row}>
                <RangeSlider
                    className={styles.slider}
                    value={parseInt(leverage) - 1}
                    valueMax={parseInt(data.pair.maxLeverage!) - 1}
                    onChange={(value) => setLeverage((value + 1).toFixed(0))}
                    checkpoints={parseInt(data.pair.maxLeverage!)}
                    checkPointLabels={checkPointLabels}
                />
            </div>
            <div className={styles.row}>
                <div className={styles.label}>{t("maxPositionAtCurrentLeverage")}</div>
                <div className={invalid ? styles.valueInvalid : undefined}>
                    <FormattedNumber
                        number={positionLimit}
                        options={{
                            fractionDigits: 0,
                        }}
                    />
                    <span className={styles.unit}>USDT</span>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>{t("currentPositionValue")}</div>
                <div className={invalid ? styles.valueInvalid : undefined}>
                    <PrivacyNumber
                        number={position?.value}
                        options={{
                            fractionDigits: 0,
                        }}
                    />
                    <span className={styles.unit}>USDT</span>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>{t("marginReservedAtCurrentLeverage")}</div>
                <div>
                    <PrivacyNumber
                        number={marginReserved}
                        options={{
                            fractionDigits: 0,
                        }}
                    />
                    <span className={styles.unit}>USDT</span>
                </div>
            </div>
            <details>
                <summary>
                    {t("marginSchedule")} {marginSchedule?.name}
                </summary>
                <div className={styles.scheduleTable}>
                    {marginSchedule && <MarginScheduleTable schedule={marginSchedule} />}
                </div>
            </details>
            <div className={styles.buttonContainer}>
                <Button className={styles.button} onClick={() => closeModal(ModalNames.ChangeLeverage)}>
                    {t("cancel")}
                </Button>
                <Button
                    disabled={invalid}
                    color="blue"
                    variant="secondary"
                    className={styles.button}
                    onClick={() =>
                        changeLeverage({
                            symbol: data.pair.symbol,
                            subaccountId,
                            leverage,
                        })
                    }
                >
                    {t("save")}
                </Button>
            </div>
        </div>
    );
}
