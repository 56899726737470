"use client";

import { Link } from "@arkham/i18n";
import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
export function LoginButton({ className, children }: { className?: string; children?: React.ReactNode }) {
    const t = useTranslations("LoginButton");
    const currentPath = usePathname();

    const loginUrl =
        `${process.env.NEXT_PUBLIC_AUTH_URL}/login?` +
        new URLSearchParams({
            redirectPath: currentPath,
        }).toString();

    return (
        <Link href={loginUrl}>
            {children || (
                <button className={`cursor-pointer text-sm leading-none ${className || ""}`}>{t("login")}</button>
            )}
        </Link>
    );
}
