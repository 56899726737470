import { Link } from "@arkham/i18n";
import React, { ReactNode } from "react";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { LoadingWrapper } from "../../wrappers/LoadingWrapper";
import styles from "./Table.module.css";

export const Direction = {
    Asc: "asc",
    Desc: "desc",
} as const;
export type Direction = (typeof Direction)[keyof typeof Direction];

interface BaseProps {
    children: ReactNode;
}

type FragmentWithKey = React.ReactElement<BaseProps, typeof React.Fragment> & { key: string | number | null };

type CustomRowWithKey = React.ReactElement<BaseProps> & { key: string | number | null };

type TableChild = FragmentWithKey | CustomRowWithKey;

function isFragment(element: TableChild): element is FragmentWithKey {
    return element.type === React.Fragment;
}

function isFunctionComponent<P = object>(
    element: TableChild,
): element is CustomRowWithKey & { type: React.FunctionComponent<P> } {
    return typeof element.type === "function";
}

// don't delete forces tailwind to include the class
const tableStyles = [
    "sm:flex",
    "md:flex",
    "lg:flex",
    "xl:flex",
    "2xl:flex",
    "md:grid",
    "lg:grid",
    "xl:grid",
    "2xl:grid",
    "hidden",
];

type ColumnBase = {
    template?: string;
    justifyContent?: string;
    onSort?: (direction: Direction) => void;
    sorted?: false | Direction;
    breakpoint?: "sm" | "md" | "lg" | "xl" | "2xl";
    hidden?: boolean;
};

export type Column =
    | ({
          header: string;
      } & ColumnBase)
    | ({
          header: ReactNode;
          key: string;
      } & ColumnBase);

interface TableProps {
    columns: Column[];
    rowHeight?: string;
    gap?: string;
    children?: TableChild | TableChild[];
    sidePadding?: string;
    onClick?: (rowIndex: number) => void;
    rowLink?: (rowIndex: number) => string | undefined;
    onEmpty?: ReactNode;
    rowClass?: string;
    className?: string;
    loading?: boolean;
    dontAlternateColors?: boolean;
}

const breakpointValues = {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    "2xl": "1536px",
};

function parseResponsiveTemplate(template?: string): Record<string, string> {
    if (!template) return { default: "1fr" };

    const parts = template.split(" ");
    const templates: Record<string, string> = {};

    parts.forEach((part) => {
        if (part.includes(":")) {
            const [breakpoint, value] = part.split(":");
            templates[breakpoint] = value;
        } else {
            templates.default = part;
        }
    });

    return templates;
}

export const Table: React.FC<TableProps> = ({
    columns,
    rowHeight = "3em",
    gap = "0.75rem",
    children,
    sidePadding = "0.375rem",
    onClick,
    rowLink,
    onEmpty,
    rowClass,
    className,
    loading,
    dontAlternateColors = false,
}) => {
    const getColumnsForBreakpoint = (targetBreakpoint?: "sm" | "md" | "lg" | "xl" | "2xl") => {
        const breakpoints: Array<"sm" | "md" | "lg" | "xl" | "2xl"> = ["sm", "md", "lg", "xl", "2xl"];
        const targetIndex = targetBreakpoint ? breakpoints.indexOf(targetBreakpoint) : -1;

        return columns
            .filter((column) => {
                if (column.hidden) return false;
                if (!targetBreakpoint) return !column.breakpoint;
                if (!column.breakpoint) return true;
                const columnIndex = breakpoints.indexOf(column.breakpoint);
                return columnIndex <= targetIndex;
            })
            .map((column) => {
                const templates = parseResponsiveTemplate(column.template);

                if (!targetBreakpoint) {
                    return wrapTemplateWithMinMax(templates.default);
                }

                let appropriateTemplate = templates.default;

                for (let i = 0; i <= targetIndex; i++) {
                    const breakpoint = breakpoints[i];
                    if (templates[breakpoint]) {
                        appropriateTemplate = templates[breakpoint];
                    }
                }

                return wrapTemplateWithMinMax(appropriateTemplate);
            })
            .join(" ");
    };

    // Helper function to wrap templates with minmax
    const wrapTemplateWithMinMax = (template: string) => {
        if (!template) return "minmax(0, 1fr)";

        // Skip if already using minmax
        if (template.includes("minmax")) return template;

        // Handle specific size values separately
        if (template.includes("rem") || template.includes("px") || template === "auto") {
            return template;
        }

        // Handle fr units
        if (template.includes("fr")) {
            return template.replace(/(\d*\.?\d+)fr/g, "minmax(0, $&)");
        }

        // Default fallback - assume it's a value that needs minmax wrapping
        return `minmax(0, ${template})`;
    };
    const empty = React.Children.count(children) === 0;
    const tableClasses = [" grid text-xs", className].filter(Boolean).join(" ");
    const rows = children ? (React.Children.toArray(children) as React.ReactElement[]) : [];
    const rowKeys = rows.map((row) => row.key);

    return (
        <div
            className={`${tableClasses} ${styles.table} `}
            style={
                {
                    "--grid-template-columns": getColumnsForBreakpoint(),
                    "--grid-template-columns-sm": getColumnsForBreakpoint("sm"),
                    "--grid-template-columns-md": getColumnsForBreakpoint("md"),
                    "--grid-template-columns-lg": getColumnsForBreakpoint("lg"),
                    "--grid-template-columns-xl": getColumnsForBreakpoint("xl"),
                    "--grid-template-columns-2xl": getColumnsForBreakpoint("2xl"),
                } as React.CSSProperties
            }
        >
            <TableHeader columns={columns} rowHeight={rowHeight} gap={gap} sidePadding={sidePadding} />
            {loading ? (
                <LoadingWrapper loading={loading} className="col-span-full row-span-full row-start-2 my-4">
                    <div></div>
                </LoadingWrapper>
            ) : empty && onEmpty ? (
                <div className={styles.empty}>{onEmpty}</div>
            ) : (
                rows.map((row, index) => (
                    <TableRow
                        key={rowKeys[index]}
                        columns={columns}
                        rowHeight={rowHeight}
                        gap={gap}
                        sidePadding={sidePadding}
                        onClick={onClick}
                        rowLink={rowLink}
                        rowClass={rowClass}
                        index={index}
                        row={row as TableChild}
                        dontAlternateColors={dontAlternateColors}
                    />
                ))
            )}
        </div>
    );
};

interface TableHeaderProps {
    columns: Column[];
    rowHeight: string;
    gap: string;
    sidePadding: string;
}

const TableHeader: React.FC<TableHeaderProps> = ({ columns, rowHeight, gap, sidePadding }) => {
    const visibleColumns = columns
        .filter((col) => !col.hidden) // Filter out hidden columns
        .map((col, index) => ({
            index,
            breakpoint: col.breakpoint,
        }));

    return (
        <div className={styles.headerRow}>
            {columns.map((column, i) => {
                if (column.hidden === true) return null; // Skip hidden columns

                const columnKey = "key" in column ? column.key : String(column.header);
                const isSortable = Boolean(column.onSort);
                const newSortDirection = column.sorted === Direction.Asc ? Direction.Desc : Direction.Asc;

                const handleSort = () => {
                    if (column.onSort) {
                        column.onSort(newSortDirection);
                    }
                };

                const breakpointClass = column.breakpoint ? `hidden ${column.breakpoint}:flex` : "flex";

                const isFirstVisible =
                    !column.breakpoint ||
                    visibleColumns
                        .filter((col) => !col.breakpoint || col.breakpoint === column.breakpoint)
                        .sort((a, b) => a.index - b.index)[0]?.index === i;

                const isLastVisible =
                    !column.breakpoint ||
                    visibleColumns
                        .filter((col) => !col.breakpoint || col.breakpoint === column.breakpoint)
                        .sort((a, b) => b.index - a.index)[0]?.index === i;

                const commonProps = {
                    className: `${styles.header} ${isSortable ? styles.hoverUnderline : ""} ${breakpointClass} ${column.justifyContent}`,
                    style: {
                        paddingLeft: isFirstVisible ? sidePadding : gap,
                        paddingRight: isLastVisible ? sidePadding : undefined,
                        height: rowHeight,
                    },
                };

                const content = (
                    <div className="flex items-center gap-0.5">
                        {column.header}
                        {isSortable && column.sorted && (
                            <span className="flex translate-y-px">
                                {column.sorted === Direction.Asc ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
                            </span>
                        )}
                    </div>
                );

                return isSortable ? (
                    <button
                        key={columnKey}
                        {...commonProps}
                        type="button"
                        onClick={handleSort}
                        className={commonProps.className}
                        aria-sort={
                            column.sorted ? (column.sorted === Direction.Asc ? "ascending" : "descending") : undefined
                        }
                    >
                        {content}
                    </button>
                ) : (
                    <div key={columnKey} {...commonProps}>
                        {content}
                    </div>
                );
            })}
        </div>
    );
};

interface TableRowProps {
    columns: Column[];
    rowHeight: string;
    gap: string;
    sidePadding: string;
    onClick?: (rowIndex: number) => void;
    rowLink?: (rowIndex: number) => string | undefined;
    rowClass?: string;
    index: number;
    row: TableChild;
    dontAlternateColors?: boolean;
}

const TableRow: React.FC<TableRowProps> = ({
    columns,
    rowHeight,
    gap,
    sidePadding,
    onClick,
    rowLink,
    rowClass,
    index,
    row,
    dontAlternateColors = false,
}) => {
    if (!React.isValidElement(row)) {
        throw new Error("Grid children must be valid elements.");
    }

    let cells: React.ReactNode[];
    if (isFragment(row)) {
        cells = React.Children.toArray(row.props.children);
    } else if (isFunctionComponent(row)) {
        const renderedChild = row.type(row.props);
        if (!React.isValidElement(renderedChild)) {
            throw new Error("Rendered child must be a valid React element.");
        }
        interface WithChildren {
            children?: React.ReactNode;
        }
        cells = React.Children.toArray((renderedChild.props as WithChildren).children);
    } else {
        cells = [row];
    }

    if (cells.length !== columns.length) {
        throw new Error(
            `Grid children must have the same number of children as the number of columns. Expected ${columns.length}, got ${cells.length}.`,
        );
    }

    const rowClasses = [
        styles.row,
        rowClass,
        !dontAlternateColors && index % 2 === 0 ? styles.evenRow : undefined,
        rowLink || onClick ? styles.interactiveRow : undefined,
    ]
        .filter(Boolean)
        .join(" ");

    const visibleColumns = columns
        .filter((col) => !col.hidden) // Filter out hidden columns
        .map((col, index) => ({
            index,
            breakpoint: col.breakpoint,
        }));

    const rowContent = (
        <>
            {React.Children.map(cells, (cell, i) => {
                if (columns[i].hidden) return null; // Skip hidden columns

                const columnKey = "key" in columns[i] ? columns[i].key : String(columns[i].header);

                const breakpointClass = columns[i].breakpoint ? `hidden ${columns[i].breakpoint}:flex` : "flex";

                const isFirstVisible =
                    !columns[i].breakpoint ||
                    visibleColumns
                        .filter((col) => !col.breakpoint || col.breakpoint === columns[i].breakpoint)
                        .sort((a, b) => a.index - b.index)[0]?.index === i;

                const isLastVisible =
                    !columns[i].breakpoint ||
                    visibleColumns
                        .filter((col) => !col.breakpoint || col.breakpoint === columns[i].breakpoint)
                        .sort((a, b) => b.index - a.index)[0]?.index === i;

                return (
                    <div
                        key={columnKey}
                        style={{
                            paddingLeft: isFirstVisible ? sidePadding : gap,
                            paddingRight: isLastVisible ? sidePadding : undefined,
                            minHeight: rowHeight,
                            maxHeight: rowHeight,
                            alignItems: "center",
                        }}
                        className={breakpointClass + " " + columns[i].justifyContent}
                    >
                        {cell}
                    </div>
                );
            })}
        </>
    );
    const link = rowLink ? rowLink(index) : undefined;

    if (link) {
        return (
            <Link href={link} className={rowClasses}>
                {rowContent}
            </Link>
        );
    }

    if (onClick) {
        return (
            <div className={rowClasses} onClick={() => onClick(index)}>
                {rowContent}
            </div>
        );
    }

    return <div className={rowClasses}>{rowContent}</div>;
};
