import { useEffect, useState } from "react";

export const Sidebar = ({
    children,
    isClosing,
    onClose,
}: {
    children: React.ReactNode;
    isClosing?: boolean;
    onClose?: () => void;
}) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const frame = requestAnimationFrame(() => {
            setIsOpen(true);
        });

        return () => cancelAnimationFrame(frame);
    }, []);

    useEffect(() => {
        if (isClosing) {
            setIsOpen(false);
        }
    }, [isClosing]);

    return (
        <div className="fixed top-0 right-0 z-50 h-full md:pt-14 md:pb-7">
            <div
                className={`border-0.5 border-slate-blue-700 bg-ash-600 h-full w-96 rounded-l-md border-t border-b border-l shadow-xl backdrop-blur-xl transition-transform duration-500 ease-in-out ${
                    isOpen ? "translate-x-0" : "translate-x-full"
                }`}
            >
                {children}
            </div>
        </div>
    );
};
