import { SidebarNames, useSidebar } from "@/app/_contexts/sidebar";
import { useApiDelete, useApiPost, useApiQuery } from "@/app/_hooks/useApi";
import { Button, Logo, useToast } from "@arkham/ui-components";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslations } from "next-intl";
import { useEffect } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { AddressDetails } from "./AddressManagementSidebar";

interface ConfirmationEmailStageProps {
    addressDetails: AddressDetails;
    onNext: () => void;
}

export function ConfirmationEmailStage({ addressDetails, onNext }: ConfirmationEmailStageProps) {
    const { closeSidebar } = useSidebar();
    const t = useTranslations("ConfirmationEmailStage");
    const { data: withdrawalAddressStatus } = useApiQuery("/account/withdrawal/addresses/{id}", {
        pathParams: { id: addressDetails.addressId },
        options: {
            refetchInterval: 5000,
        },
    });
    const showToast = useToast();

    const { mutate: resendConfirmation, isPending } = useApiPost(
        "/account/withdrawal/addresses",
        {
            onSuccess: (resp, data) => {
                showToast(t("resentConfirmationEmail"), { type: "success" });
            },
            onError: (error, message) => {
                showToast(`${(error.data.message, message)}`, { type: "error" });
            },
        },
        () => [["/account/withdrawal/addresses"]],
    );

    const { mutate: deleteWithdrawalAddress, isPending: isDeletePending } = useApiDelete(
        "/account/withdrawal/addresses/{id}",
        {
            onSuccess: (resp, data) => {
                showToast(t("cancelledAddress"), { type: "success" });
                closeSidebar(SidebarNames.AddressManagement);
            },
            onError: (error, message) => {
                showToast(`${t("failedToCancelAddress")}: ${message}`, { type: "error" });
            },
        },
        () => [["/account/withdrawal/addresses"]],
    );

    function handleResend() {
        resendConfirmation({
            address: addressDetails.address,
            chain: addressDetails.chain,
            label: addressDetails.label,
        });
    }

    const queryClient = useQueryClient();
    useEffect(() => {
        if (withdrawalAddressStatus?.confirmed === true) {
            onNext();
            queryClient.invalidateQueries({ queryKey: ["/account/withdrawal/addresses"] });
        }
    }, [withdrawalAddressStatus?.confirmed, onNext]);

    return (
        <>
            <div className="border-white-300 flex flex-col items-center gap-4 border-b border-solid py-4">
                <AiOutlineMail className="text-4xl" />
                <div className="text-center">
                    We have sent a confirmation email to your email. Please confirm your new withdrawal address.
                </div>

                <Logo of="loadingIcon" className="h-5 w-5" />
                <div className="text-white-600 font-thin">Awaiting Confirmation</div>
            </div>
            <div className="flex flex-col items-center">
                <Button
                    color="grey"
                    className="mt-8 w-52"
                    isLoading={isDeletePending}
                    onClick={() => deleteWithdrawalAddress({ id: addressDetails.addressId })}
                >
                    Cancel
                </Button>

                <div className="mt-3 text-xs">
                    <span>Didn't receive an email? </span>
                    <button className="inline text-blue-700" onClick={handleResend} disabled={isPending}>
                        Resend
                    </button>
                </div>
            </div>
        </>
    );
}
