import { useAuth } from "@/app/_contexts/auth";
import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { useSubaccountList } from "@/app/_hooks/useSubaccount";
import { Button } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import { useState } from "react";
import { CgClose } from "react-icons/cg";
import { KYCRequiredOverlay } from "../../ui/elements/BlurOverlay";
import { NewSubaccountInput } from "./NewSubaccountInput";
import { SubaccountRow } from "./SubaccountRow";

export function SubaccountManagementModal() {
    const t = useTranslations("SubaccountManagementModal");
    const { sortedSubaccounts } = useSubaccountList();
    const { closeModal } = useModal();
    const [isManageMode, setIsManageMode] = useState(false);
    const [isCreatingNew, setIsCreatingNew] = useState(false);

    const toggleManageMode = () => {
        setIsManageMode(!isManageMode);
    };

    const toggleCreateNew = () => {
        setIsCreatingNew(!isCreatingNew);
    };

    const { user } = useAuth();

    return (
        <div className="flex h-full w-full flex-col gap-3 overflow-auto p-4 md:w-[30rem]">
            <div>
                <div className="mb-4 flex justify-between text-xl font-medium">
                    <div>{t("switchAccount")}</div>
                    <div>
                        <CgClose
                            onClick={() => closeModal(ModalNames.SubaccountManagement)}
                            className="cursor-pointer text-lg"
                            data-testid="modal-subaccount-close-button"
                        />
                    </div>
                </div>
                <div className="text-white-900 mb-4 text-sm">{t("chooseAccount")}</div>
                <div className="text-white-900 mb-4 text-sm">{t("pinAccounts")}</div>
            </div>
            <div className="min-h-24 grow overflow-y-auto">
                <div className="flex flex-col py-1.5">
                    {sortedSubaccounts.map((subaccount) => (
                        <SubaccountRow
                            subaccount={subaccount}
                            key={subaccount.id}
                            isManageMode={isManageMode}
                            data-testid="modal-subaccount-subaccountrow"
                            showId={true}
                        />
                    ))}
                </div>
            </div>

            {isCreatingNew && <NewSubaccountInput type="new" onClose={() => setIsCreatingNew(false)} />}

            <div className="flex w-full gap-3 text-sm">
                <Button
                    color="grey"
                    variant="primary"
                    onClick={toggleCreateNew}
                    className="flex-1"
                    size="md"
                    data-testid="modal-subaccount-new-button"
                >
                    {isCreatingNew ? t("cancel") : t("newAccount")}
                </Button>
                <Button
                    color="blue"
                    variant="secondary"
                    onClick={toggleManageMode}
                    className="flex-1"
                    size="md"
                    data-testid="modal-subaccount-manage-button"
                >
                    {isManageMode ? t("done") : t("manage")}
                </Button>
            </div>
            {!user?.kycVerifiedAt ? (
                <KYCRequiredOverlay
                    text={t("pleaseVerifyYourIdentityToManageSubaccounts")}
                    onClose={() => closeModal(ModalNames.SubaccountManagement)}
                />
            ) : null}
        </div>
    );
}
