import { FormattedNumber } from "@/app/_components/ui/number/FormattedNumber";
import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { useApiQuery } from "@/app/_hooks/useApi";
import { Button } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import { CgClose } from "react-icons/cg";
export function PreviousSeasonsModal() {
    const t = useTranslations("PreviousSeasonsModal");
    const { closeModal } = useModal();

    const { data: points, isLoading: PointsLoading } = useApiQuery(`/affiliate-dashboard/points-season-1`);

    const { data: volume, isLoading: volumeLoading } = useApiQuery(`/affiliate-dashboard/volume-season-1`);
    const { data: referrals, isLoading: referralsLoading } = useApiQuery(`/affiliate-dashboard/referrals-season-1`);

    return (
        <div className="flex min-w-96 flex-col gap-6 p-6">
            <div className="flex items-center justify-between">
                <div className="text-lg font-medium">{t("previousSeasonsStats")}</div>
                <CgClose className="cursor-pointer text-xl" onClick={() => closeModal(ModalNames.PreviousSeasons)} />
            </div>
            <div className="flex flex-col">
                <div className="text-white-1100 pb-3">Season 1</div>
                <div className="border-slate-blue-700 flex items-center justify-between border-b py-2">
                    <div className="text-white-900 text-xs font-light">{t("totalArkhamPoints")}</div>
                    <div className="text-sm">{points?.points} pts</div>
                </div>
                <div className="border-slate-blue-700 flex items-center justify-between border-b py-2">
                    <div className="text-white-900 text-xs font-light">{t("totalUsersReferred")}</div>
                    <div className="text-sm">{referrals} users</div>
                </div>
                <div className="border-slate-blue-700 flex items-center justify-between border-b py-2">
                    <div className="text-white-900 text-xs font-light">{t("finalRanking")}</div>
                    <div className="text-sm">{points?.rank === 0 ? "-" : `#${points?.rank}`}</div>
                </div>

                <div className="border-slate-blue-700 flex items-center justify-between border-b py-2">
                    <div className="text-white-900 text-xs font-light">{t("spotTradingVolume")}</div>
                    <FormattedNumber
                        className="text-sm"
                        number={volume?.spotVolume}
                        options={{
                            style: "currency",
                            currency: "USD",
                            fractionDigits: 2,
                        }}
                    />
                </div>
                <div className="border-slate-blue-700 flex items-center justify-between border-b py-2">
                    <div className="text-white-900 text-xs font-light">{t("perpTradingVolume")}</div>
                    <FormattedNumber
                        className="text-sm"
                        number={volume?.perpVolume}
                        options={{
                            style: "currency",
                            currency: "USD",
                            fractionDigits: 2,
                        }}
                    />
                </div>
            </div>
            <Button variant="secondary" onClick={() => closeModal(ModalNames.PreviousSeasons)}>
                Close
            </Button>
        </div>
    );
}
