import { CgDetailsMore } from "react-icons/cg";

export function MobileMoreButton({ onClick }: { onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void }) {
    return (
        <button
            className="align-center flex cursor-pointer justify-center rounded-sm bg-blue-300 p-1 text-blue-700 hover:bg-blue-400 md:hidden"
            onClick={onClick}
            aria-label="More options"
        >
            <CgDetailsMore />
        </button>
    );
}
