"use client";
import { useEffect, useRef, useState } from "react";

export function TruncateText({ text, className }: { text: string; className?: string }) {
    const textRef = useRef<HTMLDivElement>(null);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        const checkTruncation = () => {
            const element = textRef.current;
            if (element) {
                setShowTooltip(element.scrollWidth > element.clientWidth);
            }
        };

        checkTruncation();

        const resizeObserver = new ResizeObserver(checkTruncation);
        if (textRef.current) {
            resizeObserver.observe(textRef.current);
        }

        return () => resizeObserver.disconnect();
    }, [text]);

    return (
        <div
            ref={textRef}
            className={`min-w-0 overflow-hidden text-ellipsis whitespace-nowrap ${className}`}
            data-tooltip-id="tooltip"
            data-tooltip-content={showTooltip ? text : ""}
            data-tooltip-place="bottom"
        >
            {text}
        </div>
    );
}
