import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { AssetWithBalance } from "@/app/_hooks/types";
import { useTranslations } from "next-intl";
import { CgClose } from "react-icons/cg";
import { AssetIcon } from "../../ui/images/AssetIcon";
import { PrivacyNumber } from "../../ui/number/PrivacyNumber";
import { AssetActions } from "../../wallet/AssetsSection";
export function WalletAssetInfoModal({ data: asset }: { data: AssetWithBalance }) {
    const t = useTranslations("WalletAssetInfoModal");
    const { closeModal } = useModal();

    return (
        <div className="p-4">
            <div className="flex justify-between">
                <div className="mb-1.5 flex items-center gap-1.5 text-xl">
                    <AssetIcon url={asset.imageUrl} width={26} height={26}></AssetIcon>
                    <div>{asset.name}</div>
                </div>
                <div className="-m-1.5 flex cursor-pointer p-1.5">
                    <CgClose onClick={() => closeModal(ModalNames.WalletAssetInfo)} />
                </div>
            </div>

            <div className="border-slate-blue-700 flex items-center justify-between border-b px-2 py-3">
                <div>{t("balance")}</div>
                <PrivacyNumber number={asset.balance} options={{ fractionDigits: 6 }} />
            </div>
            <div className="border-slate-blue-700 flex items-center justify-between border-b px-2 py-3">
                <div>{t("freeBalance")}</div>
                <PrivacyNumber number={asset.free} options={{ fractionDigits: 6 }} />
            </div>
            <div className="border-slate-blue-700 flex items-center justify-between border-b px-2 py-3">
                <div>USD</div>
                <PrivacyNumber
                    number={asset.balanceUSDT}
                    options={{
                        fractionDigits: 2,
                        style: "currency",
                        currency: "USD",
                    }}
                />
            </div>
            <div className="flex items-center justify-center px-2 py-3">
                <AssetActions asset={asset} />
            </div>
        </div>
    );
}
