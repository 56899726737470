"use client";
import { MFAModalData, ModalNames, useModal } from "@/app/_contexts/overlay";
import { useApiPost, useApiQuery } from "@/app/_hooks/useApi";
import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";

interface MFAResponse {
    success: boolean;
    successCode: string;
    tooManyAttempts: boolean;
}

export function MFAModal({ data }: { data: MFAModalData }) {
    const [code, setCode] = useState("");
    const [error, setError] = useState<string | null>(null);
    const t = useTranslations("MFAModal");

    const { closeModal } = useModal();

    const { data: hasAuthenticatorApp } = useApiQuery("/auth/authenticator-app/exists");

    useEffect(() => {
        if (hasAuthenticatorApp === false) {
            const mutationData = {
                ...data.mutationData,
                headers: {
                    ...(data.mutationData?.headers || {}),
                },
            };
            data.mutation.mutate(mutationData);
            closeModal(ModalNames.MFA);
        }
    }, [hasAuthenticatorApp, data.mutationData]);

    const {
        mutate: verifyMfa,
        isPending: isVerifyingMfa,
        error: verifyMfaError,
    } = useApiPost("/auth/authenticator-app/verify", {
        onSuccess: (response) => {
            setError(null);

            if (!response.success) {
                setError(response.tooManyAttempts ? t("tooManyAttempts") : t("invalidMfaCode"));
                return;
            }

            const mutationData = {
                ...data.mutationData,
                headers: {
                    ...(data.mutationData?.headers || {}),
                    "Mfa-Success-Code": response.successCode,
                },
            };
            data.mutation.mutate(mutationData);
            closeModal(ModalNames.MFA);
        },
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        if (!code.trim()) {
            setError(t("pleaseEnterMfaCode"));
            return;
        }

        if (code.length !== 6) {
            setError(t("mfaCodeMustBe6Digits"));
            return;
        }

        verifyMfa({ code, challengeType: data.topic });
    };

    return (
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 p-4">
            <div className="space-y-2">
                <div className="block text-sm font-medium">{t("enterAuthenticationCode")}</div>
                <input
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength={6}
                    value={code}
                    onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, "");
                        setCode(value);
                    }}
                    placeholder="000000"
                    className="border-white-800 placeholder:text-white-800 w-full appearance-none rounded-md border bg-transparent px-3 py-2 text-black focus:border-blue-900 focus:outline-hidden"
                />
            </div>

            <button
                type="submit"
                disabled={isVerifyingMfa || code.length !== 6}
                className="rounded-md bg-blue-500 px-4 py-2 font-medium text-white transition-colors hover:bg-blue-600 disabled:cursor-not-allowed disabled:bg-blue-300"
            >
                {isVerifyingMfa ? t("verifying") : t("verify")}
            </button>
            {(error || verifyMfaError) && <p className="text-red-900">{error || verifyMfaError?.message}</p>}
        </form>
    );
}
