"use client";
import { useWebsocketClient } from "@/app/_contexts/websocket";
import { Asset, AssetWithBalance } from "@/app/_hooks/types";
import { useAssets } from "@/app/_hooks/useFetch";
import { useSubaccount } from "@/app/_hooks/useSubaccount";
import BigNumber from "bignumber.js";
import { useEffect } from "react";
import { create } from "zustand";
import { Balance } from "./types";
import { useApiQuery } from "./useApi";
import { useMargin } from "./useMargin";

interface AssetsState {
    metadata: Record<string, Asset>;
    balances: Record<string, Omit<AssetWithBalance, keyof Asset>>;
    isLoading: boolean;
    initialize: (assets: Asset[]) => void;
    updateBalance: (balance: Balance) => void;
    updateBalances: (balances: Balance[], isSnapshot?: boolean) => void;
    reset: () => void;
}

const initialState = {
    metadata: {},
    balances: {},
    isLoading: true,
};

const emptyBalance = {
    balance: "0",
    balanceUSDT: "0",
    free: "0",
    freeUSDT: "0",
    priceUSDT: "0",
};

export const useAssetsStore = create<AssetsState>((set) => ({
    ...initialState,
    initialize: (assets) =>
        set({
            metadata: assets.reduce(
                (acc, asset) => {
                    acc[asset.symbol] = asset;
                    return acc;
                },
                {} as Record<string, Asset>,
            ),
            balances: assets.reduce(
                (acc, asset) => {
                    acc[asset.symbol] = emptyBalance;
                    return acc;
                },
                {} as Record<string, Omit<AssetWithBalance, keyof Asset>>,
            ),
        }),
    updateBalance: (balance) =>
        set((state) => ({
            balances: {
                ...state.balances,
                [balance.symbol]: {
                    balance: balance.balance,
                    balanceUSDT: balance.balanceUSDT,
                    free: balance.free,
                    freeUSDT: balance.freeUSDT,
                    priceUSDT: balance.priceUSDT,
                },
            },
            isLoading: false,
        })),
    updateBalances: (balances, isSnapshot = false) =>
        set((state) => {
            if (isSnapshot) {
                const newBalances = Object.keys(state.metadata).reduce(
                    (acc, symbol) => {
                        acc[symbol] = emptyBalance;
                        return acc;
                    },
                    {} as Record<string, Omit<AssetWithBalance, keyof Asset>>,
                );

                balances.forEach((balance) => {
                    if (balance.symbol in state.metadata) {
                        newBalances[balance.symbol] = {
                            balance: balance.balance,
                            balanceUSDT: balance.balanceUSDT,
                            free: balance.free,
                            freeUSDT: balance.freeUSDT,
                            priceUSDT: balance.priceUSDT,
                        };
                    }
                });

                return { balances: newBalances, isLoading: false };
            }

            const updates = balances.reduce(
                (acc, balance) => {
                    if (balance.symbol in state.metadata) {
                        acc[balance.symbol] = {
                            balance: balance.balance,
                            balanceUSDT: balance.balanceUSDT,
                            free: balance.free,
                            freeUSDT: balance.freeUSDT,
                            priceUSDT: balance.priceUSDT,
                        };
                    }
                    return acc;
                },
                {} as Record<string, Omit<AssetWithBalance, keyof Asset>>,
            );

            return {
                balances: {
                    ...state.balances,
                    ...updates,
                },
                isLoading: false,
            };
        }),
    reset: () => set(initialState),
}));

export const useBalances = () => {
    const { metadata, balances, isLoading } = useAssetsStore();
    return {
        balances: Object.keys(metadata)
            .map((symbol) => ({
                ...metadata[symbol],
                ...balances[symbol],
            }))
            .sort((a, b) => {
                const aValue = parseFloat(a.balanceUSDT) || 0;
                const bValue = parseFloat(b.balanceUSDT) || 0;
                return bValue - aValue;
            }),
        isLoading,
    };
};

export const useBalanceSubscription = ({ userId }: { userId: number }) => {
    const { client, isConnected } = useWebsocketClient();
    const { subaccountId } = useSubaccount();
    const assetsData = useAssets();
    const { initialize, updateBalance, updateBalances, reset } = useAssetsStore();

    useEffect(() => {
        if (assetsData?.assets && assetsData.assets.length > 0) {
            initialize(assetsData.assets);
        }
    }, [userId, subaccountId, assetsData?.assets, initialize]);

    useEffect(() => {
        if (!client || !isConnected) {
            return;
        }

        const handleBalanceUpdate = (data: Balance | Balance[]) => {
            if (Array.isArray(data)) {
                updateBalances(data, true);
            } else {
                updateBalance(data);
            }
        };

        const unsubscribe = client.balances(subaccountId, true, handleBalanceUpdate);

        return () => {
            unsubscribe();
            reset();
        };
    }, [client, isConnected, userId, subaccountId, updateBalance, updateBalances, reset]);
};

export const useCurrentSubaccountTotalUSDValue = () => {
    const { balances, isLoading: balancesLoading } = useBalances();
    const { margin, isLoading: marginLoading } = useMargin();
    const isLoading = balancesLoading || marginLoading;
    const summedBalance = balances
        .reduce((acc, asset) => acc.plus(new BigNumber(asset.balanceUSDT || "0")), new BigNumber(margin?.pnl || "0"))
        .toString();
    return { balancesTotalUSDValue: summedBalance, isLoading };
};

export const useSubaccountTotalValues = () => {
    const { data: margins, isLoading } = useApiQuery("/account/margin/all", {
        options: { refetchInterval: 30000 },
    });
    const memo: Record<string, BigNumber> = {};

    for (const balances of margins || []) {
        memo[balances.subaccountId] = memo[balances.subaccountId] || new BigNumber(0);
        memo[balances.subaccountId] = memo[balances.subaccountId].plus(balances.totalAssetValue).plus(balances.pnl);
    }

    const subaccountTotalValues: Record<string, string> = {};
    for (const key in memo) {
        subaccountTotalValues[key] = memo[key].toString();
    }
    return { subaccountTotalValues, isLoading };
};
