"use client";
import { useEffect } from "react";
import styles from "./VIPSupportTypeform.module.css";

interface VIPSupportTypeformProps {
    className?: string;
}

export function VIPSupportTypeform({ className }: VIPSupportTypeformProps) {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "//embed.typeform.com/next/embed.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return <div className={`${styles.typeform} w-full ${className || ""}`} data-tf-live="01JCZZYMXJJ3KETEWXXCGDPASR" />;
}
