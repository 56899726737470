"use client";

import { VALID_SETTINGS_TABS } from "@/app/[locale]/(routes)/(responsive)/settings/definitions";
import { PrivacyNumber } from "@/app/_components/ui/number/PrivacyNumber";
import { URLS } from "@/app/_constants/urls";
import { useAuth } from "@/app/_contexts/auth";
import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { useCurrentSubaccountTotalUSDValue } from "@/app/_hooks/useBalances";
import { useLogout } from "@/app/_hooks/useLogout";
import { useSubaccount } from "@/app/_hooks/useSubaccount";
import { Link } from "@arkham/i18n";
import { Button, Logo } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { ToggleBalanceShown } from "../../ui/buttons/ToggleBalanceShown";
import { TruncateText } from "../../ui/elements/Truncate";
import { getCurrentYear } from "../../ui/utils";
import { LoginButton } from "../desktop/LoginButton";
import { MobileLanguageSwitcher } from "./MobileLanguageSwitcher";

interface MenuItem {
    text: string;
    type?: string;
    link?: string;
    onClick?: () => void;
    submenu?: MenuItem[];
    className?: string;
}

type MenuItems = MenuItem[];

interface DropdownMenuProps {
    item: MenuItem;
    handleClose: () => void;
    level?: number;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ item, handleClose, level = 0 }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);

    const toggleExpanded = useCallback(() => {
        setIsExpanded((prev) => !prev);
    }, []);

    useEffect(() => {
        if (contentRef.current) {
            const updateHeight = () => {
                if (isExpanded) {
                    setHeight(contentRef.current?.scrollHeight || 0);
                } else {
                    setHeight(0);
                }
            };

            updateHeight();

            const resizeObserver = new ResizeObserver(updateHeight);
            resizeObserver.observe(contentRef.current);

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [isExpanded]);
    const hasSubmenu = item.submenu && item.submenu.length > 0;

    if (!hasSubmenu) {
        return (
            <Link
                href={item.link || "#"}
                className={`block w-full px-4 py-1.5 transition-colors duration-0 hover:bg-gray-100 ${item.className}`}
                style={{ paddingLeft: `${level}rem` }}
                onClick={(e) => {
                    if (item.onClick) {
                        e.preventDefault();
                        item.onClick();
                    }
                    handleClose();
                }}
                target={item.type === "external" ? "_blank" : undefined}
                rel={item.type === "external" ? "noopener noreferrer" : undefined}
            >
                {item.text}
            </Link>
        );
    }

    return (
        <div className="w-full">
            <button
                type="button"
                className={`flex w-full items-center justify-between px-4 py-1.5 transition-colors duration-200 hover:bg-gray-100 ${item.className}`}
                style={{ paddingLeft: `${level}rem` }}
                onClick={toggleExpanded}
            >
                <span>{item.text}</span>
                <BsChevronDown
                    className={`transform transition-transform duration-300 ${isExpanded ? "rotate-180" : ""}`}
                />
            </button>

            <div
                className={`overflow-hidden transition-all ease-in-out ${level === 0 ? "duration-300" : "duration-0"}`}
                style={{
                    height: `${height}px`,
                }}
            >
                <div ref={contentRef}>
                    {item.submenu?.map((subItem, index) => (
                        <DropdownMenu
                            key={`${subItem.text}`}
                            item={subItem}
                            handleClose={handleClose}
                            level={level + 1}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

function AccountBalanceRow() {
    const { subaccountName } = useSubaccount();
    const { balancesTotalUSDValue } = useCurrentSubaccountTotalUSDValue();
    const t = useTranslations("AccountBalanceRow");
    return (
        <div className="flex flex-col gap-1.5">
            <div className="flex items-center justify-between">
                <div className="text-white-800 flex items-center text-sm">
                    <span>{t("accountBalance")} &nbsp;</span>
                    <span>{"("}</span>
                    <TruncateText className="text-blue-1000 max-w-28" text={subaccountName} />
                    <span>{")"}</span>
                </div>
                <ToggleBalanceShown />
            </div>
            <PrivacyNumber
                className="text-3xl"
                number={balancesTotalUSDValue}
                options={{ style: "currency", currency: "USD" }}
            />
        </div>
    );
}

export function MobileOverlay() {
    const t = useTranslations("MobileOverlay");
    const settingsT = useTranslations("SettingsTabs");
    const { user } = useAuth();
    const { closeModal, openModal } = useModal();
    const { handleLogout } = useLogout();
    const [logoHovered, setLogoHovered] = useState<boolean>(false);

    const menuitems: MenuItems = [
        {
            text: t("trade"),
            submenu: [
                { text: t("spotTrading"), type: "internal", link: "/trade/BTC_USDT" },
                { text: t("usdtPerps"), type: "internal", link: "/markets/perpetuals" },
                {
                    text: t("cryptoDeposit"),
                    type: "internal",
                    link: "#",
                    onClick: () => openModal(ModalNames.Deposit, undefined),
                },
            ],
        },
        ...(user
            ? [
                  {
                      text: t("wallet"),
                      submenu: [
                          { text: t("overview"), type: "internal", link: "/wallet/assets" },
                          { text: t("deposits"), type: "internal", link: "/wallet/deposits" },
                          { text: t("withdrawals"), type: "internal", link: "/wallet/withdrawals" },
                          { text: t("transactionHistory"), type: "internal", link: "/wallet/transaction-history" },
                          { text: t("accountStatement"), type: "internal", link: "/wallet/account-statement" },
                      ],
                  },
                  {
                      text: t("history"),
                      submenu: [
                          { text: t("orders"), type: "internal", link: "/history/orders" },
                          { text: t("trades"), type: "internal", link: "/history/trades" },
                      ],
                  },
                  {
                      text: t("settings"),
                      submenu: [
                          ...VALID_SETTINGS_TABS.map((tab) => ({
                              text: settingsT(tab),
                              type: "internal",
                              link: `/settings/${tab}`,
                          })),
                          { text: settingsT("logout"), type: "internal", link: "#", onClick: handleLogout },
                      ],
                  },
              ]
            : []),
        {
            text: t("more"),
            submenu: [
                {
                    text: t("programs"),
                    submenu: [
                        { text: t("pointsProgram"), type: "internal", link: "/points" },
                        { text: t("vipProgram"), type: "internal", link: "/vip" },
                        { text: t("institutional"), type: "internal", link: "/institutional" },
                        { text: t("commissions"), type: "internal", link: "/commissions" },
                        { text: t("dmm"), type: "internal", link: "/dmm" },
                        { text: t("verified"), type: "internal", link: "/verified" },
                    ],
                },
                {
                    text: t("apiTools"),
                    submenu: [
                        { text: t("apiDocumentation"), type: "internal", link: "/docs" },
                        { text: t("apiLimits"), type: "internal", link: "/limits-api" },
                        { text: t("intelApi"), type: "external", link: "https://info.arkm.com/api-platform" },
                    ],
                },
                {
                    text: t("info"),
                    submenu: [
                        { text: t("announcements"), type: "external", link: "https://info.arkm.com/announcements" },
                        { text: t("media"), type: "external", link: "https://info.arkm.com/media" },
                        { text: t("research"), type: "external", link: "https://info.arkm.com/research" },
                        { text: t("careers"), type: "external", link: "https://info.arkm.com/careers" },
                        { text: t("codex"), type: "external", link: "https://codex.arkhamintelligence.com/" },
                        { text: t("whitepaper"), type: "external", link: "https://info.arkm.com/whitepaper" },
                        {
                            text: t("brandAssets"),
                            type: "external",
                            link: "https://drive.google.com/drive/folders/1Fx6Gx2OYlR4rwZwentsmkr6TslAvpeWG",
                        },
                    ],
                },
                { text: t("fees"), type: "internal", link: "/fees" },
                { text: t("listings"), type: "internal", link: "/listings" },
                { text: t("support"), type: "internal", link: "/support" },
            ],
        },
    ];

    const handleClose = () => closeModal(ModalNames.MobileMenu);

    return (
        <div className="bg-black-900 fixed top-0 left-0 z-30 flex h-screen min-h-full w-screen flex-col items-center overflow-y-auto backdrop-blur-md">
            <div className="border-slate-blue-700 bg-black-1000 flex w-full flex-col gap-4 border-b p-4 pb-6 backdrop-blur-2xl">
                <div className="flex items-center justify-between">
                    <Link href="/" onMouseEnter={() => setLogoHovered(true)} onMouseLeave={() => setLogoHovered(false)}>
                        <Logo
                            stopped={!logoHovered}
                            of="lookingAndBlinkingIcon"
                            className="h-6 w-6 text-2xl select-none"
                        />
                    </Link>
                    <div className="flex items-center gap-4">
                        <button type="button" onClick={handleClose} aria-label="Close Menu">
                            <CgClose className="h-6 w-6 text-2xl select-none" />
                        </button>
                    </div>
                </div>
                {!user && (
                    <div className="mt-2 flex items-center justify-between gap-4">
                        <LoginButton className="text-lg" />

                        <Link href={`${process.env.NEXT_PUBLIC_AUTH_URL!}/register`}>
                            <button className="bg-white-1200 text-black-1200 hover:bg-white-400 hover:text-white-1200 rounded-sm p-2 text-lg leading-none transition-all">
                                {t("signUp")}
                            </button>
                        </Link>
                    </div>
                )}

                {user && (
                    <>
                        <AccountBalanceRow />
                        <Button color="blue" variant="alpha" onClick={() => openModal(ModalNames.SubaccountManagement)}>
                            {t("switchAccount")}
                        </Button>
                    </>
                )}
            </div>
            <div className="flex w-full flex-1 flex-col px-4 py-6">
                <Link href="/markets/spot" className="py-1.5" onClick={handleClose}>
                    {t("markets")}
                </Link>
                {user && (
                    <Link href="/dashboard" className="py-1.5" onClick={handleClose}>
                        {t("pointsProgram")}
                    </Link>
                )}
                <Link href="/vip" className="py-1.5" onClick={handleClose}>
                    {t("vipProgram")}
                </Link>

                {menuitems.map((item) => (
                    <DropdownMenu key={item.text} item={item} handleClose={handleClose} />
                ))}

                <div className="my-2">
                    <MobileLanguageSwitcher handleClose={handleClose} />
                </div>

                <Link href="/legal/terms-of-service" className="py-1.5" onClick={handleClose}>
                    {t("termsOfService")}
                </Link>
                <Link href="/legal/privacy-policy" className="py-1.5" onClick={handleClose}>
                    {t("privacyPolicy")}
                </Link>
                <Link href={URLS.INTELLIGENCE_PLATFORM} target="_blank" className="py-1.5" onClick={handleClose}>
                    {t("intelApi")}
                </Link>
            </div>
            <div aria-label="Copyright information" className="w-full px-4 pt-8">
                © {getCurrentYear()} Arkham Markets International S.R.L. {t("allRightsReserved")}
            </div>
        </div>
    );
}
