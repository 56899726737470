import { DepositModalData, ModalNames } from "@/app/_contexts/overlay";
import { AssetWithBalance } from "@/app/_hooks/types";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { useState } from "react";
import { AssetSelector } from "../shared/AssetSelector";
import { TransactionModalHeader } from "../shared/TransactionModalHeader";
import { SelectWithdrawalAddressSection } from "./SelectWithdrawalAddressSection";

export function WithdrawalModal({ data }: { data?: DepositModalData }) {
    const t = useTranslations("WithdrawalModal");
    const [stage, setStage] = useState(data?.asset ? 2 : 1);
    const [selectedAsset, setSelectedAsset] = useState<AssetWithBalance | null>(data?.asset || null);

    const handleAssetSelect = (asset: AssetWithBalance) => {
        setSelectedAsset(asset);
        setStage(2);
    };

    const stageHeaderContent = {
        1: "Select an asset to withdraw",
        2: selectedAsset && (
            <>
                <span>{t("selectNetwork")}</span>
                <div className="flex items-center gap-1">
                    <div className="relative h-4 w-4 shrink-0">
                        <Image
                            src={selectedAsset.imageUrl}
                            alt={`${selectedAsset.name} logo`}
                            className="object-contain"
                            width={16}
                            height={16}
                        />
                    </div>
                    <span className="font-medium">{selectedAsset.symbol}</span>
                </div>
            </>
        ),
    };

    return (
        <div className="flex w-full flex-col sm:w-96">
            <TransactionModalHeader
                currentStage={stage}
                totalStages={2}
                setStage={setStage}
                modalName={ModalNames.Withdrawal}
                headerText="Withdraw"
                stageHeaderContent={stageHeaderContent[stage as keyof typeof stageHeaderContent]}
            />
            <div className="p-6 pt-0">
                {stage === 1 && <AssetSelector onSelect={handleAssetSelect} />}
                {stage === 2 && selectedAsset && <SelectWithdrawalAddressSection selectedAsset={selectedAsset} />}
            </div>
        </div>
    );
}
