"use client";
import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { Session } from "@/app/_hooks/types";
import { useApiQuery } from "@/app/_hooks/useApi";
import { useWindowSize } from "@/app/_hooks/useWindowSize";
import { Button } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import React from "react";
import { AiOutlineDesktop, AiOutlineMobile, AiOutlineTablet } from "react-icons/ai";
import { MdKeyboardArrowRight } from "react-icons/md";
import { UAParser } from "ua-parser-js";
import { Section } from "../../layout/Section";
import { Table } from "../../ui/elements/table/Table";
import { TruncateText } from "../../ui/elements/Truncate";
import { SettingsSectionHeader } from "../SettingsComponents";

type DeviceType = "desktop" | "mobile" | "tablet" | "other";

type UserAgentInfo = {
    deviceType: DeviceType;
    displayString: string;
};

export const parseUserAgent = (userAgent: string): UserAgentInfo => {
    const parser = new UAParser(userAgent);
    const result = parser.getResult();

    let deviceType: DeviceType = "other";

    if (!result.device.type) {
        deviceType = "desktop";
    } else if (result.device.type === "mobile") {
        deviceType = "mobile";
    } else if (result.device.type === "tablet") {
        deviceType = "tablet";
    }

    const displayString = `${result.browser.name || "Unknown"} on ${result.os.name || "Unknown"}`;

    return {
        deviceType,
        displayString,
    };
};

export function SessionsSettingsTab() {
    const {
        data: sessionsResponse,
        isLoading,
        error,
    } = useApiQuery("/account/sessions", {
        options: {
            refetchInterval: 60000,
        },
    });
    const t = useTranslations("SessionsSection");
    const { openModal } = useModal();

    const { breakpointAbove } = useWindowSize();

    const handleDeleteSession = (session: Session) => {
        openModal(ModalNames.SessionManagementConfirm, {
            session,
            isOpenedOnMobile: !breakpointAbove("md"),
        });
    };

    const handleTerminateAll = () => {
        openModal(ModalNames.SessionManagementConfirm, { allSessions: true });
    };

    if (error) {
        return (
            <Section>
                <div className="border-slate-blue-800 border-b-0.5 px-5 pt-5 pb-3 text-lg font-bold">
                    {t("sessionManagement")}
                </div>
                <div className="px-5 py-4 text-red-900">{t("errorLoadingSessions")}</div>
            </Section>
        );
    }

    const sortedSessions = sessionsResponse?.sessions
        ? [...sessionsResponse.sessions].sort((a, b) => {
              // Always put the current active session first
              if (sessionsResponse.currentSession === a.id) return -1;
              if (sessionsResponse.currentSession === b.id) return 1;
              // Then sort by lastUsedAt time (most recent first)
              return new Date(b.lastUsedAt).getTime() - new Date(a.lastUsedAt).getTime();
          })
        : [];

    const renderSessionRow = (session: Session) => {
        const isCurrentSession = sessionsResponse?.currentSession === session.id;
        const formattedLastUsed = isCurrentSession ? (
            <span className="font-normal text-blue-900 uppercase">{t("active")}</span>
        ) : (
            <span className="text-white-700 md:text-white-1200">{new Date(session.lastUsedAt).toLocaleString()}</span>
        );

        const locationInfo = session.ipInfo?.location
            ? `(${session.ipInfo.location.city}, ${session.ipInfo.location.country})`
            : null;

        const { deviceType, displayString } = parseUserAgent(session.userAgent);

        return (
            <React.Fragment key={session.id}>
                <div className="flex items-center gap-3">
                    {deviceType === "desktop" ? (
                        <AiOutlineDesktop className="text-base" />
                    ) : deviceType === "mobile" ? (
                        <AiOutlineMobile className="text-base" />
                    ) : (
                        <AiOutlineTablet className="text-base" />
                    )}
                    <div className="flex flex-col gap-1">
                        <div>{displayString}</div>
                        <div className="md:hidden">{formattedLastUsed}</div>
                    </div>
                </div>
                <div>{formattedLastUsed}</div>
                <div>{new Date(session.createdAt).toLocaleString()}</div>
                <div className="flex min-w-0 flex-col md:flex-row md:items-center md:gap-1">
                    <TruncateText
                        text={session.ipAddress}
                        className="text-white-700 md:text-white-1200 order-2 md:order-1"
                    />
                    <TruncateText
                        text={locationInfo ?? ""}
                        className="text-white-700 md:text-white-1200 order-1 md:order-2"
                    />
                </div>
                <div className="flex justify-end">
                    <MdKeyboardArrowRight className="text-base md:hidden" />
                    <div className="hidden md:block">
                        {isCurrentSession ? (
                            <Button
                                size="sm"
                                className="w-[4.8125rem]"
                                variant="tertiary"
                                color="grey"
                                onClick={() => handleDeleteSession(session)}
                            >
                                {t("logout")}
                            </Button>
                        ) : (
                            <Button
                                size="sm"
                                className="w-[4.8125rem]"
                                variant="tertiary"
                                color="red"
                                onClick={() => handleDeleteSession(session)}
                            >
                                {t("delete")}
                            </Button>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    return (
        <Section>
            <SettingsSectionHeader title={t("sessionManagement")}>
                <Button color="red" variant="secondary" className="text-xs" size="sm" onClick={handleTerminateAll}>
                    {t("terminateAll")}
                </Button>
            </SettingsSectionHeader>
            <div className="px-2 pb-3">
                <Table
                    loading={isLoading}
                    rowHeight="44px"
                    onClick={breakpointAbove("md") ? undefined : (index) => handleDeleteSession(sortedSessions[index])}
                    columns={[
                        { header: t("userAgent") },
                        { header: t("lastUsed"), breakpoint: "md" },
                        { header: t("createdAt"), breakpoint: "md" },
                        { header: t("ipAddress") },
                        {
                            header: <span className="hidden md:flex">{t("actions")}</span>,
                            key: "actions",
                            justifyContent: "justify-end",
                            template: "2rem md:5.5rem",
                        },
                    ]}
                >
                    {sortedSessions.map(renderSessionRow)}
                </Table>
            </div>
        </Section>
    );
}
