import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { Order, OrderStatus } from "@/app/_hooks/types";
import { useCancelOrder } from "@/app/_hooks/useOrders";
import { Button } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import styles from "./OrderInfo.module.css";

export function OrderInfoModal({ data }: { data: Order }) {
    const t = useTranslations("OrderInfoModal");
    const { cancelOrder } = useCancelOrder();
    const { closeModal } = useModal();

    const handleCancelOrder = () => {
        cancelOrder(data);
        closeModal(ModalNames.OrderInfo);
    };

    return (
        <div className="p-4">
            <div className={styles.row}>
                <div>{t("type")}</div>
                <div>{data.type}</div>
            </div>
            <div className={styles.row}>
                <div>{t("side")}</div>
                <div>{data.side}</div>
            </div>

            <div className={styles.row}>
                <div>{t("size")}</div>
                <div>{data.size}</div>
            </div>

            <div className={styles.row}>
                <div>{t("price")}</div>
                <div>{data.price}</div>
            </div>

            <div className={styles.row}>
                <div>{t("executedSize")}</div>
                <div>{data.executedSize}</div>
            </div>

            <div className={styles.row}>
                <div>{t("executedNotional")}</div>
                <div>{data.executedNotional}</div>
            </div>

            <div className={styles.row}>
                <div>{t("avgPrice")}</div>
                <div>{data.avgPrice}</div>
            </div>

            <div className={styles.row}>
                <div>{t("symbol")}</div>
                <div>{data.symbol}</div>
            </div>

            <div className={styles.row}>
                <div>{t("quoteFeePaid")}</div>
                <div>{data.quoteFeePaid}</div>
            </div>

            <div className={styles.row}>
                <div>{t("arkmFeePaid")}</div>
                <div>{data.arkmFeePaid}</div>
            </div>
            <div className={styles.row}>
                <div>{t("orderId")}</div>
                <div>{data.orderId}</div>
            </div>

            {data.status !== OrderStatus.Closed ? (
                <div className="mt-4 flex justify-center">
                    <Button size="md" className={` ${styles.action}`} onClick={handleCancelOrder}>
                        {t("cancel")}
                    </Button>
                </div>
            ) : (
                <span className={styles.noActions}>{t("noActions")}</span>
            )}
        </div>
    );
}
