import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { useApiQuery } from "@/app/_hooks/useApi";
import { Button } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import { BiCheckCircle } from "react-icons/bi";
import { CgClose } from "react-icons/cg";

export function AirdropClaimAddressModal() {
    const { closeModal } = useModal();
    const t = useTranslations("AirdropClaimAddressModal");

    const { data: airdropAddress } = useApiQuery("/airdrop");

    return (
        <div className="flex flex-col gap-6 p-5">
            <div className="flex flex-col gap-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2 text-2xl">
                        <BiCheckCircle className="text-green-800" />
                        <div className="font-medium">{t("airdropAddressConfirmed")}</div>
                    </div>
                    <CgClose
                        className="cursor-pointer text-2xl"
                        onClick={() => closeModal(ModalNames.AirdropClaimAddress)}
                    />
                </div>

                <div className="text-white-1000 text-lg">{t("airdropAddressConfirmedDescription")}</div>
            </div>
            <div className="flex flex-col gap-1">
                <div className="text-white-800 text-sm">{t("airdropReceivingAddress")}</div>
                <div className="text-white-1100">{airdropAddress}</div>
            </div>
            <Button color="green" onClick={() => closeModal(ModalNames.AirdropClaimAddress)}>
                {t("done")}
            </Button>
        </div>
    );
}
