export const URLS = {
    DMM: "https://docs.google.com/forms/d/e/1FAIpQLSfswaoXprwoNBGp4GI5YT6kyBS_CCalTat5IeicJa1Q3CRJiw/viewform",
    CONTACT_FORM: "https://docs.google.com/forms/d/e/1FAIpQLScUOOhIf4UsGWU7ju8u7nphopwqcCe4N_6QL0Fe3j6n2m4e3A/viewform",
    LISTING_FORM: "https://docs.google.com/forms/d/e/1FAIpQLSf29yXmGr8TbB2Qj5RKytdOqJozV-yH7hcNc5Xht0a7iNsckw/viewform",
    NOMINATE_VIP: "https://docs.google.com/forms/d/e/1FAIpQLSfYNveuWGdUgpkAtXGEiBEsSVzvB5NjDLpYRYpf28UDweixIg/viewform",
    CLAIM_VIP_BOX:
        "https://docs.google.com/forms/d/e/1FAIpQLSd0epHD9mfOzanHrBr0gB3TZoY3gDcNCv9Wv51KDCrMsZvF6w/viewform",
    VIP_TELEGRAM: "https://t.me/+SoiU8SYMvdtiYTBl",
    SUBMIT_AMPLIFICATION_CONTENT:
        "https://docs.google.com/forms/d/e/1FAIpQLSchAq6I2E9rWXOy1iPIB_jGYY4OV-iVjyXgepxriaZzeZOhrg/viewform",
    APPLY_FOR_VERIFIED:
        "https://docs.google.com/forms/d/e/1FAIpQLSejwZUISziJU8IWBWy7iHvm-16nQbCJ0mXmZ_LQBIN_ktqSVg/viewform",
    APPLY_FOR_VIP:
        "https://docs.google.com/forms/d/e/1FAIpQLSfc5toiu9E4MA_o29S_vzltHwkPkqqps4YI80qAwFhL03VdpQ/viewform",
    INTELLIGENCE_PLATFORM: "https://intel.arkm.com/",
    AFFILIATE_PROGRAM_AGREEMENT: "https://www.arkhamintelligence.com/affiliate-program-agreement",
} as const;
