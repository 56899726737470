import { Asset, AssetWithBalance } from "@/app/_hooks/types";
import { useTranslations } from "next-intl";
import { FormattedNumber } from "../../ui/number/FormattedNumber";

interface ExpectedTimeProps {
    time?: string;
    minAmount?: string;
    asset?: Asset | AssetWithBalance;
}

export function EstimatedTime({ time = "-", minAmount = "-", asset }: ExpectedTimeProps) {
    const t = useTranslations("EstimatedTime");
    return (
        <div className="border-slate-blue-800 flex w-full justify-between border-b p-1.5 pb-3">
            <div className="flex flex-col gap-1">
                <div className="text-white-800 text-xs">{t("expectedArrival")}</div>
                <div className="text-sm">{time}</div>
            </div>
            <div className="flex flex-col items-end gap-1">
                <div className="text-white-800 text-xs">{t("minimum")}</div>
                {minAmount === "-" ? (
                    "-"
                ) : (
                    <FormattedNumber
                        number={minAmount}
                        unit={asset?.symbol}
                        options={{ maximumFractionDigits: 10 }}
                        className="text-sm"
                    />
                )}
            </div>
        </div>
    );
}
