import { Asset } from "@/app/_hooks/types";
import Image from "next/image";
import { DropdownRow } from "./DropdownRow";

export const AssetRow = ({ asset, onSelect }: { asset: Asset; onSelect: (asset: Asset) => void }) => {
    return (
        <DropdownRow className="flex items-center gap-2 p-2" onClick={() => onSelect(asset)} role="option">
            <div className="relative h-6 w-6 shrink-0">
                <Image src={asset.imageUrl} alt={`${asset.name} logo`} className="object-contain" fill />
            </div>
            <div className="flex flex-col">
                <span className="text-white-1200 text-sm font-medium">{asset.name}</span>
                <span className="text-white-1000 text-xs">{asset.symbol}</span>
            </div>
        </DropdownRow>
    );
};
