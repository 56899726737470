import { Button } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import Link from "next/link";

export function BlurOverlay({ children, className }: { children: React.ReactNode; className?: string }) {
    return (
        <div
            className={`absolute inset-0 z-10 flex flex-col items-center justify-center gap-6 rounded-sm p-8 text-center text-base backdrop-blur-sm ${className}`}
        >
            {children}
        </div>
    );
}

import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

interface BlurOverlayProps {
    children: React.ReactNode;
    className?: string;
}

export function BlurOverlayParent({ children, className }: BlurOverlayProps) {
    const [mounted, setMounted] = useState(false);
    const reference = useRef<HTMLDivElement>(null);
    const [position, setPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });

    // Update position when mounted and on resize
    useEffect(() => {
        setMounted(true);

        const updatePosition = () => {
            if (reference.current) {
                const rect = reference.current.getBoundingClientRect();
                setPosition({
                    top: rect.top + window.scrollY,
                    left: rect.left + window.scrollX,
                    width: rect.width,
                    height: rect.height,
                });
            }
        };

        // Initial position
        updatePosition();

        // Update on resize and scroll
        window.addEventListener("resize", updatePosition);
        window.addEventListener("scroll", updatePosition, true); // true for capture phase

        return () => {
            window.removeEventListener("resize", updatePosition);
            window.removeEventListener("scroll", updatePosition, true);
        };
    }, []);

    // Reference element that helps us track the parent's position
    const referenceElement = (
        <div ref={reference} className="pointer-events-none absolute inset-0 h-full w-full" aria-hidden="true" />
    );

    // The actual overlay content
    const overlayContent = mounted
        ? createPortal(
              <div
                  style={{
                      position: "absolute",
                      top: position.top,
                      left: position.left,
                      width: position.width,
                      height: position.height,
                  }}
                  className={`z-50 flex flex-col items-center justify-center gap-6 rounded-lg p-8 text-center text-base backdrop-blur-sm ${className}`}
              >
                  {children}
              </div>,
              document.body,
          )
        : null;

    return (
        <>
            {referenceElement}
            {overlayContent}
        </>
    );
}

export function KYCRequiredOverlay({ text, onClose }: { text: string; onClose: () => void }) {
    const t = useTranslations("BlurOverlay");

    return (
        <BlurOverlayParent>
            <div className="text-lg font-medium">{text}</div>
            <Link href={`${process.env.NEXT_PUBLIC_AUTH_URL!}/identity`} onClick={onClose}>
                <Button color="blue">{t("beginVerification")}</Button>
            </Link>
        </BlurOverlayParent>
    );
}

export function TwoFactorRequiredOverlay({ text, onClose }: { text: string; onClose: () => void }) {
    const t = useTranslations("BlurOverlay");

    return (
        <BlurOverlayParent>
            <div className="text-lg font-medium">{text}</div>
            <Link href="/settings/security" onClick={onClose}>
                <Button color="blue">{t("setUp2FA")}</Button>
            </Link>
        </BlurOverlayParent>
    );
}
