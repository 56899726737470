import { ChainType } from "@/app/_hooks/types";

export const formatTime = (time: number) => {
    const timestamp = new Date(time / 1000);
    const hhmmss = `${timestamp.getHours().toString().padStart(2, "0")}:${timestamp.getMinutes().toString().padStart(2, "0")}:${timestamp.getSeconds().toString().padStart(2, "0")}`;
    const dmy = `${timestamp.getDate().toString().padStart(2, "0")}/${(timestamp.getMonth() + 1).toString().padStart(2, "0")}/${timestamp.getFullYear()}`;
    return `${dmy} ${hhmmss}`;
};

export function formatDepositTime(blockTimeUs: number, confirmations: number): string {
    const totalMicroseconds = blockTimeUs * confirmations;

    const ms = totalMicroseconds / 1000;

    const timeUnits = [
        { unit: "day", ms: 86400000 },
        { unit: "hour", ms: 3600000 },
        { unit: "minute", ms: 60000 },
        { unit: "second", ms: 1000 },
        { unit: "millisecond", ms: 1 },
    ];

    if (ms < 1) {
        return `${totalMicroseconds} microseconds`;
    }

    for (const { unit, ms: unitMs } of timeUnits) {
        if (ms >= unitMs) {
            const value = Math.floor(ms / unitMs);
            const pluralUnit = value === 1 ? unit : `${unit}s`;
            return `${value} ${pluralUnit}`;
        }
    }

    return "unknown";
}

const ethAddressRE = /^0x[0-9a-fA-F]{40}$/;
const btcAddressRE = /^bc1[a-zA-Z0-9]{25,59}$|^1[a-zA-Z0-9]{25,34}$|^3[a-zA-Z0-9]{25,34}$/;
const tronAddressRE = /^T[a-zA-Z0-9]{33}$/;
const solanaAddressRE = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
const nearAddressRE = /^[a-zA-Z0-9_-]{2,64}\.near$|^[a-zA-Z0-9_-]{2,64}$/;
const tonAddressRE = /^(?:-1|0):[0-9a-fA-F]{64}$/; // TODO: this does not work for all formats.
const dogeAddressRE = /^D[a-zA-Z0-9]{25,59}$/;
const suiAddressRE = /^0[xX][a-fA-F0-9]{64}$/;
const rippleAddressRE = /^r[1-9A-HJ-NP-Za-km-z]{24,34}$/; // Disallows ?dt addresses and X addresses.

export const isValidEVMAddress = (address: string): boolean => {
    return ethAddressRE.test(address);
};

export const isValidBTCAddress = (address: string): boolean => {
    return btcAddressRE.test(address);
};

export const isValidTronAddress = (address: string): boolean => {
    return tronAddressRE.test(address);
};

export const isValidSolanaAddress = (address: string): boolean => {
    return solanaAddressRE.test(address);
};

export const isValidNearAddress = (address: string): boolean => {
    return nearAddressRE.test(address);
};

export const isValidTonAddress = (address: string): boolean => {
    return tonAddressRE.test(address);
};

export const isValidDogeAddress = (address: string): boolean => {
    return dogeAddressRE.test(address);
};

export const isValidSuiAddress = (address: string): boolean => {
    return suiAddressRE.test(address);
};

export const isValidRippleAddress = (address: string): boolean => {
    return rippleAddressRE.test(address);
};

// Utility function to validate address based on chain type
export const isValidAddress = (address: string, chainType: ChainType): boolean => {
    switch (chainType) {
        case ChainType.EVM:
            return isValidEVMAddress(address);
        case ChainType.Bitcoin:
            return isValidBTCAddress(address);
        case ChainType.Tron:
            return isValidTronAddress(address);
        case ChainType.Solana:
            return isValidSolanaAddress(address);
        case ChainType.Near:
            return isValidNearAddress(address);
        case ChainType.Ton:
            return true;
        case ChainType.Doge:
            return isValidDogeAddress(address);
        case ChainType.Sui:
            return isValidSuiAddress(address);
        case ChainType.Ripple:
            return isValidRippleAddress(address);
        case ChainType.Mock:
            return true; // Mock chain type always returns true for testing
        default:
            return false;
    }
};

export const getCurrentYear = () => {
    return new Date().getFullYear();
};
