"use client";

import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { useOrderFormContext } from "@/app/_contexts/useOrderFormContext";
import { useWebsocketClient } from "@/app/_contexts/websocket";
import {
    CreateOrderRequest,
    CreateOrderResponse,
    CreateTriggerOrderRequest,
    CreateTriggerOrderResponse,
    Order,
    OrderStatus,
    OrderType,
    Pair,
    TriggerOrder,
    TriggerOrderStatus,
    TriggerPriceType,
} from "@/app/_hooks/types";
import { useApiPost } from "@/app/_hooks/useApi";
import { useToast } from "@arkham/ui-components";
import { ReactNode, useEffect, useMemo } from "react";
import { create } from "zustand";
import { getTrigger } from "../_components/trade/orderform/utils";
import { FormattedNumber } from "../_components/ui/number/FormattedNumber";
import { usePairs } from "./useFetch";
import styles from "./useOrders.module.css";
import { useSettings } from "./useSettings";
import { useSubaccount } from "./useSubaccount";

interface OrdersState {
    ordersMap: Map<number, Order>;
    setOrdersMap: (updater: (prev: Map<number, Order>) => Map<number, Order>) => void;
    triggerOrdersMap: Map<number, TriggerOrder>;
    setTriggerOrdersMap: (updater: (prev: Map<number, TriggerOrder>) => Map<number, TriggerOrder>) => void;
    reset: () => void;
}

const initialState = {
    ordersMap: new Map<number, Order>(),
    triggerOrdersMap: new Map<number, TriggerOrder>(),
};

export const useOrdersStore = create<OrdersState>((set) => ({
    ...initialState,
    setOrdersMap: (updater) =>
        set((state) => ({
            ordersMap: updater(state.ordersMap),
        })),
    setTriggerOrdersMap: (updater) =>
        set((state) => ({
            triggerOrdersMap: updater(state.triggerOrdersMap),
        })),
    reset: () => set(initialState),
}));

export const useOrdersSubscription = ({ userId }: { userId: number }) => {
    const { client, isConnected } = useWebsocketClient();
    const { subaccountId } = useSubaccount();
    const { setOrdersMap, setTriggerOrdersMap, reset } = useOrdersStore();

    useEffect(() => {
        if (!client || !isConnected) {
            return;
        }

        const unsubscribe = client.orderStatuses(subaccountId, (data: Order | Order[]) => {
            setOrdersMap((prevMap) => {
                const newMap = Array.isArray(data) ? new Map() : new Map(prevMap);

                if (Array.isArray(data)) {
                    data.forEach((order) => {
                        newMap.set(order.orderId, order);
                    });
                } else {
                    if (data.status === OrderStatus.Booked) {
                        newMap.set(data.orderId, data);
                    } else if (data.status === OrderStatus.Cancelled || data.status === OrderStatus.Closed) {
                        newMap.delete(data.orderId);
                    } else if (data.status === OrderStatus.Maker) {
                        newMap.set(data.orderId, { ...newMap.get(data.orderId), ...data });
                    }
                }

                return newMap;
            });
        });

        return () => {
            unsubscribe();
            reset();
        };
    }, [client, isConnected, subaccountId, setOrdersMap, reset]);

    useEffect(() => {
        if (!client || !isConnected) {
            return;
        }

        const unsubscribe = client.triggerOrders(subaccountId, (data: TriggerOrder | TriggerOrder[]) => {
            setTriggerOrdersMap((prevMap) => {
                const newMap = Array.isArray(data) ? new Map() : new Map(prevMap);

                if (Array.isArray(data)) {
                    data.forEach((order) => {
                        newMap.set(order.triggerOrderId, order);
                    });
                } else {
                    if (data.status === TriggerOrderStatus.Staged) {
                        newMap.set(data.triggerOrderId, data);
                    } else if (
                        data.status === TriggerOrderStatus.Triggered ||
                        data.status === TriggerOrderStatus.Cancelled
                    ) {
                        newMap.delete(data.triggerOrderId);
                    } else if (newMap.has(data.triggerOrderId)) {
                        newMap.set(data.triggerOrderId, { ...newMap.get(data.triggerOrderId), ...data });
                    }
                }

                return newMap;
            });
        });

        return () => {
            unsubscribe();
            reset();
        };
    }, [client, isConnected, subaccountId, setTriggerOrdersMap, reset]);
};

export const useOrders = () => {
    const ordersMap = useOrdersStore((state) => state.ordersMap);
    const triggerOrdersMap = useOrdersStore((state) => state.triggerOrdersMap);
    const openOrders = useMemo(() => Array.from(ordersMap.values()), [ordersMap]);
    const triggerOrders = useMemo(() => Array.from(triggerOrdersMap.values()), [triggerOrdersMap]);
    return { openOrders, triggerOrders };
};

const generateOrderSuccessMessage = (
    resp: CreateOrderResponse,
    params: CreateOrderRequest,
    pair: Pair | undefined,
): ReactNode => {
    switch (resp.type) {
        case OrderType.LimitGtc:
            return (
                <div className={styles.message}>
                    <div className="font-medium">Order Successful</div>
                    <span>You placed an order to {params.side}&#8201;</span>
                    <FormattedNumber className={styles.forceColor} number={params.size} unit={pair?.baseSymbol} />
                    <span>&#8201;at a price of&#8201;</span>
                    <FormattedNumber className={styles.forceColor} number={params.price} unit={pair?.quoteSymbol} />
                </div>
            );
        case OrderType.Market:
            return (
                <div className={styles.message}>
                    <div className="font-medium">Order Successful</div>
                    <span>You placed a market order to {params.side}&#8201;</span>
                    <FormattedNumber className={styles.forceColor} number={params.size} unit={pair?.baseSymbol} />
                </div>
            );
    }
};

const generateTriggerOrderSuccessMessage = (
    resp: CreateTriggerOrderResponse,
    params: CreateTriggerOrderRequest,
    pair: Pair | undefined,
): ReactNode => {
    switch (resp.type) {
        case OrderType.LimitGtc:
            return (
                <div className={styles.message}>
                    <div className="font-medium">Trigger Order Successful</div>
                    <span>
                        You created a {params.triggerType} trigger for {getTrigger(params)} {pair?.quoteSymbol} to
                        create an order to {params.side}&#8201;
                    </span>
                    <FormattedNumber className={styles.forceColor} number={params.size} unit={pair?.baseSymbol} />
                    <span>&#8201;at a price of&#8201;</span>
                    <FormattedNumber className={styles.forceColor} number={params.price} unit={pair?.quoteSymbol} />
                </div>
            );
        case OrderType.Market:
            return (
                <div className={styles.message}>
                    <div className="font-medium">Trigger Order Successful</div>
                    <span>
                        You created a {params.triggerType} trigger for {getTrigger(params)} {pair?.quoteSymbol} to
                        create a market order to {params.side}&#8201;
                    </span>
                    <FormattedNumber className={styles.forceColor} number={params.size} unit={pair?.baseSymbol} />
                </div>
            );
    }
};

import BigNumber from "bignumber.js";
import { Position } from "./types";

export function useMarketClose() {
    const showToast = useToast();
    const { pairs } = usePairs();

    const handleSuccess = (message: ReactNode) => {
        showToast(message, { type: "success" });
    };

    const handleError = (message: ReactNode) => {
        showToast(message, { type: "error" });
    };

    const newOrderMutation = useApiPost(
        "/orders/new",
        {
            onSuccess: async (resp, params) => {
                const pair = pairs?.find((pair) => pair.symbol === params.symbol);
                const message = generateOrderSuccessMessage(resp, params, pair);
                handleSuccess(message);
            },
            onError: (e, params) => {
                console.error("Order error:", e);
                const message = e.data?.message ?? "Failed to place market close order.";
                handleError(
                    <div>
                        <div className="font-medium">Order Failed</div>
                        {message}
                    </div>,
                );
            },
        },
        () => [["/orders"], ["/orders/history"]],
    );

    const { cancelAllTriggerOrders } = useCancelAllTriggerOrders();

    const marketClose = (position: Position) => {
        const base = new BigNumber(position.base);

        newOrderMutation.mutate({
            symbol: position.symbol,
            subaccountId: position.subaccountId,
            side: base.isPositive() ? "sell" : "buy",
            size: base.abs().toString(),
            type: OrderType.Market,
            price: "0",
            clientOrderId: null,
            postOnly: false,
            reduceOnly: true,
        });

        cancelAllTriggerOrders({
            symbol: position.symbol,
            triggerPriceType: TriggerPriceType.MarkPrice,
        });
    };

    return {
        marketClose,
        isLoading: newOrderMutation.isPending,
    };
}
export function useNewOrder() {
    const { openModal, isModalOpen, closeModal } = useModal();
    const showToast = useToast();
    const { settings } = useSettings();
    const { pairs } = usePairs();
    const { reset, setShowConfirmModal, showConfirmModal } = useOrderFormContext();

    const handleSuccess = (message: ReactNode) => {
        showToast(message, { type: "success" });
    };

    const handleError = (message: ReactNode) => showToast(message, { type: "error" });

    const newOrderMutation = useApiPost(
        "/orders/new",
        {
            onSuccess: async (resp, params) => {
                const pair = pairs?.find((pair) => pair.symbol === params.symbol);
                const message = generateOrderSuccessMessage(resp, params, pair);
                handleSuccess(message);
                reset();
            },
            onError: (e, params) => {
                console.error("e", e);
                console.error("e.data", e.data);
                console.error("params", params);
                const message = e.data.message ?? "An error occurred while placing your order.";
                handleError(
                    <div className={styles.message}>
                        <div className="font-medium">Order Failed</div>
                        {message}
                    </div>,
                );
            },
        },
        () => [["/orders"], ["/orders/history"]],
    );

    const placeOrder = (order: CreateOrderRequest) => {
        if (settings?.confirmBeforePlaceOrder && !showConfirmModal) {
            setShowConfirmModal(true, order);
        } else {
            newOrderMutation.mutate(order);
        }
    };

    return { placeOrder };
}

export function usePlaceOrdersWithoutReset() {
    const showToast = useToast();
    const { pairs } = usePairs();

    const handleSuccess = (message: ReactNode) => {
        showToast(message, { type: "success" });
    };

    const handleError = (message: ReactNode) => showToast(message, { type: "error" });

    const newOrderMutation = useApiPost(
        "/orders/new",
        {
            onSuccess: async (resp, params) => {
                const pair = pairs?.find((pair) => pair.symbol === params.symbol);
                const message = generateOrderSuccessMessage(resp, params, pair);
                handleSuccess(message);
            },
            onError: (e) => {
                const message = e.data.message ?? "An error occurred while placing your order.";
                handleError(
                    <div className={styles.message}>
                        <div className="font-medium">Order Failed</div>
                        {message}
                    </div>,
                );
            },
        },
        () => [["/orders"], ["/orders/history"]],
    );

    const placeOrder = (order: CreateOrderRequest) => {
        newOrderMutation.mutate(order);
    };

    return { placeOrder };
}

export function useCancelOrder() {
    const { openModal, isModalOpen, closeModal } = useModal();
    const showToast = useToast();
    const { settings } = useSettings();

    const handleSuccess = (message: ReactNode) => {
        showToast(message, { type: "success" });
        closeModal(ModalNames.Cancel);
    };

    const handleError = (message: ReactNode) => showToast(message, { type: "error" });

    const cancelOrderMutation = useApiPost("/orders/cancel", {
        onSuccess: () => handleSuccess("Your order has been cancelled successfully."),
        onError: () => handleError("An error occurred while cancelling your order."),
    });

    const cancelOrder = (order: Order) => {
        if (settings?.confirmBeforePlaceOrder && !isModalOpen(ModalNames.Cancel)) {
            openModal(ModalNames.Cancel, order);
        } else {
            cancelOrderMutation.mutate({
                orderId: order.orderId,
                subaccountId: order.subaccountId,
                timeToCancel: 0,
            });
        }
    };

    return { cancelOrder };
}

export function useTriggerOrder() {
    const { openModal, isModalOpen, closeModal } = useModal();
    const showToast = useToast();
    const { settings } = useSettings();
    const { pairs } = usePairs();

    const handleSuccess = (message: ReactNode) => {
        showToast(message, { type: "success" });
        closeModal(ModalNames.Cancel);
    };

    const handleError = (message: ReactNode) => showToast(message, { type: "error" });

    const newOrderMutation = useApiPost(
        "/trigger-orders/new",
        {
            onSuccess: (resp, params) => {
                const pair = pairs?.find((pair) => pair.symbol === params.symbol);
                const message = generateTriggerOrderSuccessMessage(resp, params, pair);
                handleSuccess(message);
            },
            onError: (e, params) => {
                console.error("e", e);
                console.error("e.data", e.data);
                console.error("params", params);
                handleError(
                    <div className={styles.message}>
                        <div className="font-medium">Trigger Order Failed</div>
                        <span>An error occurred while placing your trigger order.</span>
                    </div>,
                );
            },
        },
        (_, params) => [["/trigger-orders", { subaccountId: params.subaccountId, symbol: params.symbol }]],
    );

    const cancelOrderMutation = useApiPost(
        "/trigger-orders/cancel",
        {
            onSuccess: () => handleSuccess("Your order has been cancelled successfully."),
            onError: () => handleError("An error occurred while cancelling your order."),
        },
        () => [["/trigger-orders"]],
    );

    const placeTriggerOrder = (order: CreateTriggerOrderRequest) => {
        newOrderMutation.mutate(order);
    };

    const cancelTriggerOrder = (order: TriggerOrder) => {
        if (settings?.confirmBeforePlaceOrder && !isModalOpen(ModalNames.Cancel)) {
            openModal(ModalNames.Cancel, order);
        } else {
            cancelOrderMutation.mutate({
                triggerOrderId: order.triggerOrderId,
                triggerPriceType: order.triggerPriceType,
                symbol: order.symbol,
                subaccountId: order.subaccountId,
            });
        }
    };

    return {
        placeTriggerOrder,
        cancelTriggerOrder,
    };
}

export function useCancelAllTriggerOrders() {
    const showToast = useToast();
    const { subaccountId } = useSubaccount();

    const handleSuccess = (message: ReactNode) => {
        showToast(message, { type: "success" });
    };

    const handleError = (message: ReactNode) => showToast(message, { type: "error" });

    const cancelAllTriggerOrdersMutation = useApiPost("/trigger-orders/cancel/all", {
        onSuccess: () => handleSuccess("Your trigger orders have been cancelled successfully."),
        onError: () => handleError("An error occurred while cancelling your trigger orders."),
    });

    const cancelAllTriggerOrders = ({
        symbol,
        triggerPriceType,
    }: {
        symbol: string;
        triggerPriceType: TriggerPriceType;
    }) => {
        cancelAllTriggerOrdersMutation.mutate({
            symbol,
            subaccountId,
            triggerPriceType,
        });
    };

    return { cancelAllTriggerOrders };
}
