import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { useTranslations } from "next-intl";
import { ReactNode } from "react";
import { CgClose } from "react-icons/cg";
import { IoMdArrowBack } from "react-icons/io";
import { ProgressBar } from "./ProgressBar";

export function TransactionModalHeader({
    currentStage,
    totalStages,
    setStage,
    modalName,
    headerText,
    stageHeaderContent,
}: {
    currentStage: number;
    totalStages: number;
    setStage: (stage: number) => void;
    modalName: ModalNames;
    headerText: string;
    stageHeaderContent: ReactNode;
}) {
    const t = useTranslations("TransactionModalHeader");
    const closeModal = useModal().closeModal;
    const handleBackButton = () => {
        if (currentStage === 1) {
            closeModal(modalName);
        } else {
            setStage(currentStage - 1);
        }
    };

    return (
        <div className="flex-none">
            <div className="flex w-full flex-col">
                <div className="flex w-full flex-col items-center px-4 py-3">
                    <div className="flex w-full items-start justify-between">
                        {currentStage !== 1 ? (
                            <IoMdArrowBack className="text-white-900 cursor-pointer" onClick={handleBackButton} />
                        ) : (
                            <div className="w-4"></div>
                        )}
                        <div className="text-white-1100">{headerText}</div>
                        <CgClose className="text-white-900 cursor-pointer" onClick={() => closeModal(modalName)} />
                    </div>
                    <div className="text-white-900 text-xs font-thin">
                        {t("step", {
                            step: currentStage,
                            totalSteps: totalStages,
                        })}
                    </div>
                </div>
                <ProgressBar currentStage={currentStage} totalStages={totalStages} />
                <div className="flex flex-none items-center justify-center gap-2 p-4 px-6 leading-5 whitespace-nowrap">
                    {stageHeaderContent}
                </div>
            </div>
        </div>
    );
}
