import { MarginSchedule } from "@/app/_hooks/types";
import { useTranslations } from "next-intl";
export const MarginScheduleTable = ({
    schedule,
    showRebate = false,
}: {
    schedule: MarginSchedule;
    showRebate?: boolean;
}) => {
    const t = useTranslations("MarginSchedule");
    return (
        <table>
            <thead>
                <tr>
                    <th>{t("positionSize")}</th>
                    <th>{t("maximumLeverage")}</th>
                    <th>{t("initialMarginRate")}</th>
                    {showRebate && <th>{t("rebate")}</th>}
                </tr>
            </thead>
            <tbody>
                {schedule.bands.map((band, i) => (
                    <tr key={band.leverageRate}>
                        <td>
                            {parseInt(schedule.bands[i - 1]?.positionLimit ?? 0).toLocaleString()} -{" "}
                            {parseInt(band.positionLimit).toLocaleString()}
                        </td>
                        <td>{band.leverageRate}x</td>
                        <td>{parseFloat(band.marginRate) * 100}%</td>
                        {showRebate && <td>{parseInt(band.rebate).toLocaleString()}</td>}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
