import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { Subaccount } from "@/app/_hooks/types";
import { useSubaccountMutations } from "@/app/_hooks/useSubaccount";
import { Button } from "@arkham/ui-components";
import { useTranslations } from "next-intl";

export function DeleteSubaccountConfirmModal({ data }: { data: Subaccount }) {
    const t = useTranslations("DeleteSubaccountConfirmModal");
    const { closeModal } = useModal();

    const { deleteSubaccount } = useSubaccountMutations();

    return (
        <div className="flex w-96 flex-col items-center gap-3 p-4" data-testid="modal-deletesubaccount-container">
            <div className="text-lg font-medium">{t("deleteSubaccount")}</div>
            <div className="text-center text-sm">
                {t("deleteSubaccountConfirmation", {
                    name: data.name,
                })}
            </div>
            <div className="mt-1.5 flex gap-4.5">
                <Button
                    onClick={() => closeModal(ModalNames.DeleteSubaccountConfirm)}
                    data-testid="modal-deletesubaccount-cancel"
                >
                    {t("cancel")}
                </Button>
                <Button
                    onClick={() => deleteSubaccount({ subaccountId: data.id })}
                    color="red"
                    variant="secondary-alpha"
                    data-testid="modal-deletesubaccount-confirm"
                >
                    {t("delete")}
                </Button>
            </div>
        </div>
    );
}
