import { AmountInput } from "@/app/_components/modals/shared/AmountInput";
import { PrivacyNumber } from "@/app/_components/ui/number/PrivacyNumber";
import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { AssetWithBalance, Subaccount } from "@/app/_hooks/types";
import { useApiPost, useApiQuery } from "@/app/_hooks/useApi";
import { useSubaccount, useSubaccountList } from "@/app/_hooks/useSubaccount";
import { Button, Select, useToast } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import { useEffect, useMemo, useState } from "react";

export const SubaccountSelectionSection = ({ selectedAsset }: { selectedAsset: AssetWithBalance }) => {
    const t = useTranslations("SubaccountSelectionSection");
    const { sortedSubaccounts } = useSubaccountList();
    const { subaccountId, subaccountName } = useSubaccount();
    const showToast = useToast();
    const { openModal, closeModal } = useModal();

    const { data: allBalances } = useApiQuery("/account/balances/all");

    const [selectedSubaccount, setSelectedSubaccount] = useState<Subaccount | null>(null);
    const [baseValue, setBaseValue] = useState("");
    const [USDValue, setUSDValue] = useState("");
    const [showErrors, setShowErrors] = useState(false);

    const subaccountsWithBalances = useMemo(() => {
        return sortedSubaccounts.map((subaccount) => {
            const balance = allBalances?.find(
                (bal) => bal.symbol === selectedAsset.symbol && bal.subaccountId === subaccount.id,
            );
            return {
                ...subaccount,
                assetBalance: balance?.balance || "0",
            };
        });
    }, [sortedSubaccounts, allBalances, selectedAsset.symbol]);

    const subAccountsExceptCurrent = subaccountsWithBalances.filter((subaccount) => subaccount.id !== subaccountId);

    const getSubaccountError = () => {
        if (!selectedSubaccount) return t("selectSubaccountError");
        return "";
    };

    useEffect(() => {
        if (baseValue) setShowErrors(true);
    }, [baseValue]);

    const subaccountError = getSubaccountError();
    const [inputError, setInputError] = useState(false);
    const isButtonDisabled = Boolean(subaccountError || inputError);
    const shouldShowSubaccountError = showErrors && subaccountError;

    const { mutate: handleTransfer, isPending } = useApiPost("/subaccounts/transfer", {
        onSuccess: () => {
            showToast(`Transferred ${baseValue} ${selectedAsset.symbol} to ${selectedSubaccount?.name}`, {
                type: "success",
            });
            openModal(ModalNames.TransferSuccess, {
                asset: selectedAsset,
                amount: baseValue,
                usdValue: USDValue,
                subaccountFrom: subaccountName,
                subaccountTo: selectedSubaccount!.name,
            });
            closeModal(ModalNames.Transfer);
        },
        onError: (error: { data: { message: string } }) => {
            showToast(error.data.message ?? t("transferError"), { type: "error" });
        },
    });

    const handleSubmit = () => {
        if (selectedSubaccount) {
            handleTransfer({
                fromSubaccountId: subaccountId,
                toSubaccountId: selectedSubaccount.id,
                symbol: selectedAsset.symbol,
                amount: baseValue,
            });
        }
    };

    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-1">
                    <div className="text-white-800 text-sm">{t("from")}</div>
                    <div className="border-0.5 border-slate-blue-1000 rounded-sm px-3 py-2 text-xs">
                        <span className="text-white-1000 mr-1">{subaccountName}</span>
                        <span className="text-white-800">({t("currentAccount")})</span>
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <div className="flex flex-col">
                        <Select<number>
                            label="To"
                            options={subAccountsExceptCurrent.map((subaccount) => ({
                                value: subaccount.id,
                                label: subaccount.name,
                                render: () => (
                                    <div className="flex w-full justify-between">
                                        <div>{subaccount.name}</div>
                                        <PrivacyNumber
                                            className="text-white-900"
                                            number={subaccount.assetBalance}
                                            unit={selectedAsset.symbol}
                                        />
                                    </div>
                                ),
                            }))}
                            value={selectedSubaccount?.id}
                            onValueChange={(value: string) => {
                                const numValue = parseInt(value, 10);
                                const selected = subAccountsExceptCurrent.find((s) => s.id === numValue);
                                if (selected) setSelectedSubaccount(selected);
                            }}
                            placeholder={t("selectSubaccountPlaceholder")}
                            type="transparent"
                            error={shouldShowSubaccountError ? subaccountError : undefined}
                            renderValue={(value, options) => {
                                const option = options.find((o) => o.value === value);
                                return option ? <div className="text-white-1200">{option.label}</div> : null;
                            }}
                        />
                    </div>
                </div>
            </div>

            <AmountInput
                onChange={({ baseValue, USDValue }) => {
                    setBaseValue(baseValue);
                    setUSDValue(USDValue);
                }}
                asset={selectedAsset}
                onError={(value) => setInputError(value)}
            />

            <Button color="green" disabled={isButtonDisabled} onClick={handleSubmit} isLoading={isPending}>
                {t("confirmTransfer")}
            </Button>
        </div>
    );
};
