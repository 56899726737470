import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { useRef } from "react";
import { CgClose } from "react-icons/cg";

interface ModalProps {
    name: ModalNames;
    children: React.ReactNode;
    zIndex?: number;
    canOutsideClickClose?: boolean;
    header?: React.ReactNode;
}

export function Modal({ name, children, zIndex, canOutsideClickClose, header }: ModalProps) {
    const { closeModal } = useModal();
    const modalRef = useRef<HTMLDivElement>(null);

    const handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (canOutsideClickClose && event.target === event.currentTarget) {
            closeModal(name);
        }
    };

    // // @ts-expect-error usehooks not updated to react 19
    // useOnClickOutside(modalRef, () => {
    //     if (canOutsideClickClose) {
    //         closeModal(name);
    //     }
    // });

    return (
        <div
            className="bg-black-700 fixed inset-0 flex items-start justify-center"
            style={{ zIndex }}
            role="presentation"
            aria-label="Modal background"
            onClick={handleBackgroundClick}
        >
            <div
                ref={modalRef}
                className="bg-blue-blur relative mx-4 mt-4 flex max-h-[calc(100vh-6rem)] w-full max-w-screen flex-col gap-4 overflow-auto rounded-lg sm:mt-14 sm:w-auto sm:max-w-4/5 sm:min-w-96 [@media(min-height:667px)]:mt-20 [@media(min-height:667px)]:max-h-[calc(100vh-8rem)] [@media(min-height:768px)]:mt-40"
                aria-modal="true"
            >
                {header && (
                    <div className="flex items-center justify-between px-4 pt-4 text-xl font-medium">
                        <span>{header}</span>
                        <button
                            className="-m-1.5 flex cursor-pointer p-1.5"
                            onClick={() => closeModal(name)}
                            aria-label="Close modal"
                        >
                            <CgClose />
                        </button>
                    </div>
                )}
                {children}
            </div>
        </div>
    );
}
