import { CgClose } from "react-icons/cg";
import { MdCheck } from "react-icons/md";

export function Checks({
    onSave,
    onCancel,
    isDisabled = false,
}: {
    onSave: (event: React.MouseEvent) => void;
    onCancel: (event: React.MouseEvent) => void;
    isDisabled?: boolean;
}) {
    return (
        <div className="flex items-center">
            <button
                onClick={onSave}
                disabled={isDisabled}
                className={`hover:bg-slate-blue-500 p-3 transition-colors ${isDisabled ? "cursor-not-allowed text-gray-500" : "cursor-pointer text-green-500 hover:text-green-900"}`}
            >
                <MdCheck size={20} />
            </button>
            <button
                onClick={onCancel}
                className="hover:bg-slate-blue-500 cursor-pointer rounded-r p-3 text-red-500 transition hover:text-red-900"
            >
                <CgClose size={20} />
            </button>
        </div>
    );
}
