"use client";

import { usePathname, useRouter } from "@arkham/i18n";
import { useLocale } from "next-intl";
import { useCallback } from "react";
import { DropdownMenu } from "./MobileOverlay"; // Adjust the import path if needed

interface MobileLanguageSwitcherProps {
    handleClose: () => void;
}

export function MobileLanguageSwitcher({ handleClose }: MobileLanguageSwitcherProps) {
    const locale = useLocale();
    const router = useRouter();
    const pathname = usePathname();

    const changeLanguage = useCallback(
        (newLocale: string) => {
            router.replace(pathname, { locale: newLocale });
            handleClose();
        },
        [router, pathname, handleClose],
    );

    const languageMenuItem = {
        text: "Language",
        submenu: [
            {
                text: "English",
                onClick: () => changeLanguage("en"),
                customClass: locale === "en" ? "text-white-1200" : "text-white-700",
            },
            {
                text: "中文",
                onClick: () => changeLanguage("zh"),
                customClass: locale === "zh" ? "text-white-1200" : "text-white-700",
            },
        ],
    };

    return <DropdownMenu item={languageMenuItem} handleClose={handleClose} />;
}
