import { EstimatedTime } from "@/app/_components/modals/shared/EstimatedTime";
import { Copy } from "@/app/_components/ui/buttons/Copy";
import { formatDepositTime } from "@/app/_components/ui/utils";
import { DepositAddressModalData, ModalNames, useModal } from "@/app/_contexts/overlay";
import { Link } from "@arkham/i18n";
import { useTranslations } from "next-intl";
import { AiTwotoneExclamationCircle } from "react-icons/ai";
import { CgClose } from "react-icons/cg";
import { MdArrowOutward } from "react-icons/md";
import QRCode from "react-qr-code";
import styles from "./DepositAddressModal.module.css";

export function DepositAddressModal({ data }: { data: DepositAddressModalData }) {
    const { closeModal } = useModal();
    const t = useTranslations("DepositAddressModal");

    const isTon = data.address.includes("?memoId");
    const isXrp = data.address.includes("?dt=");

    const isAvax = data.chain.name === "Avalanche";
    const isEth = data.chain.name === "Ethereum";

    const address = isTon || isXrp ? data.address.split("?")[0] : data.address;
    const memoId = isTon ? data.address.split("?memoId=")[1] : undefined;
    const destinationTag = isXrp ? data.address.split("?dt=")[1] : undefined;

    return (
        <div className="flex flex-col gap-2 p-3 sm:gap-5 sm:p-6">
            <h2 className="text-2xl font-normal">{t("yourDepositAddress")}</h2>
            <CgClose
                className="absolute top-4 right-4 cursor-pointer text-lg"
                onClick={() => closeModal(ModalNames.DepositAddress)}
            />

            <div
                className={`flex flex-col items-center gap-4 rounded-lg p-4 sm:flex-row sm:p-4 ${styles.gradient} ${styles.gradientBorder}`}
            >
                <div className="aspect-square w-full max-w-64 shrink-0 sm:w-32">
                    <div className="relative h-full w-full">
                        <QRCode
                            value={data.address}
                            style={{ width: "100%", height: "100%" }}
                            viewBox={`0 0 128 128`}
                            bgColor="transparent"
                            fgColor="white"
                            level="L"
                        />
                    </div>
                </div>

                <div className="flex w-full flex-1 flex-col justify-between gap-4">
                    <div className="flex w-full justify-between">
                        <div className="flex flex-col items-start justify-between">
                            <div className="text-gray-1000 text-xs">{t("asset")}</div>
                            <div>{data.asset.name}</div>
                        </div>

                        <div className="flex flex-col items-end justify-between">
                            <div className="text-gray-1000 text-xs">{t("network")}</div>
                            <div>{data.chain.name}</div>
                        </div>
                    </div>

                    {(isTon || isXrp) && (
                        <div className="flex flex-col">
                            <div className="text-gray-1000 text-xs">
                                <span>{isTon ? t("memo") : t("destinationTag")} </span>
                                <span className="text-red-900">{t("required")}</span>
                            </div>
                            <div className="flex items-center gap-4">
                                <div className="text-left text-wrap break-all">{isTon ? memoId : destinationTag}</div>
                                <Copy
                                    value={isTon ? memoId! : destinationTag!}
                                    tooltipText={isTon ? t("copyMemo") : t("copyDestinationTag")}
                                />
                            </div>
                        </div>
                    )}

                    <div className="flex flex-col">
                        <div className="text-gray-1000 text-xs">{t("address")}</div>
                        <div className="flex items-center justify-between gap-4">
                            <div className="w-80 text-left text-wrap break-all">{address}</div>
                            <Copy value={address} tooltipText={t("copyAddress")} />
                        </div>
                    </div>
                </div>
            </div>

            {isTon && (
                <div className="text-thin border-white-300 bg-white-200 text-white-1100 flex items-center gap-2 rounded-md border border-solid p-2 text-xs">
                    <AiTwotoneExclamationCircle className="shrink-0 text-lg" />
                    <div className="text-wrap">{t("toncoinRequiresBothAddressAndMemo")}</div>
                </div>
            )}

            {isXrp && (
                <div className="text-thin border-white-300 bg-white-200 text-white-1100 flex items-center gap-2 rounded-md border border-solid p-2 text-xs">
                    <AiTwotoneExclamationCircle className="shrink-0 text-lg" />
                    <div className="text-wrap">{t("xrpRequiresBothAddressAndDestinationTag")}</div>
                </div>
            )}

            {isAvax && (
                <div className="text-thin border-white-300 bg-white-200 text-white-1100 flex items-center gap-2 rounded-md border border-solid p-2 text-xs">
                    <AiTwotoneExclamationCircle className="shrink-0 text-lg" />
                    <div className="text-wrap">{t("pleaseEnsureAvaxDepositOnMainnet")}</div>
                </div>
            )}

            {isEth && (
                <div className="text-thin border-white-300 bg-white-200 text-white-1100 flex items-center gap-2 rounded-md border border-solid p-2 text-xs">
                    <AiTwotoneExclamationCircle className="shrink-0 text-lg" />
                    <div className="text-wrap">{t("pleaseEnsureEthDepositOnMainnet")}</div>
                </div>
            )}

            <EstimatedTime
                time={formatDepositTime(data.chain.blockTime, data.chain.confirmations)}
                minAmount={data.asset.minDeposit}
                asset={data.asset}
            />

            <div className="text-white-700 rounded-lg text-center text-sm text-wrap">
                {t("fundsWillTypicallyBeAvailableAfter", {
                    time: formatDepositTime(data.chain.blockTime, data.chain.confirmations),
                })}
            </div>

            <Link href="/wallet/deposits" onClick={() => closeModal(ModalNames.DepositAddress)}>
                <button className="bg-white-200 hover:bg-white-300 flex w-full items-center justify-center gap-0.5 p-2 transition-all">
                    {t("viewPendingDeposits")}
                    <MdArrowOutward className="text-2xs -translate-y-1 transform" />
                </button>
            </Link>
        </div>
    );
}
