export function Address({ address, full = false, className }: { address: string; full?: boolean; className?: string }) {
    if (!full) {
        return (
            <div data-tooltip-id="tooltip" data-tooltip-content={address} className={className}>
                {address.slice(0, 8)}...{address.slice(-6)}
            </div>
        );
    }

    return <div>{address}</div>;
}
