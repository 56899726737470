import { ModalNames, PriceAlertModalData, useModal } from "@/app/_contexts/overlay";
import { useTicker } from "@/app/_contexts/trades/ticker";
import { AlertPriceType, PriceAlertType } from "@/app/_hooks/types";
import { useApiDelete, useApiPut } from "@/app/_hooks/useApi";
import { useSubaccount } from "@/app/_hooks/useSubaccount";
import { Button, useToast } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import { ReactNode, useEffect, useRef, useState } from "react";
import { isNumber } from "../../trade/orderform/utils";
import { AssetIcon } from "../../ui/images/AssetIcon";

export function PriceAlertModal({ data }: { data: PriceAlertModalData }) {
    const t = useTranslations("PriceAlertModal");
    const { closeModal } = useModal();
    const showToast = useToast();
    const handleError = (message: ReactNode) => showToast(message, { type: "error" });
    const { subaccountId } = useSubaccount();
    const [alertPrice, setAlertPrice] = useState<string>(data.alertPrice);
    const [alertPriceType] = useState<`${AlertPriceType}`>(data.alertPriceType);
    const hiddenRef = useRef<HTMLSpanElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (hiddenRef.current && inputRef.current) {
            inputRef.current.style.width = `${hiddenRef.current.scrollWidth}px`;
        }
    }, [alertPrice]);

    const ticker = useTicker();

    const priceMap = {
        [AlertPriceType.LastPrice]: ticker?.price,
        [AlertPriceType.MarkPrice]: ticker?.markPrice,
        [AlertPriceType.IndexPrice]: ticker?.indexPrice,
    };

    const currentPrice = priceMap[alertPriceType] ?? "0";

    const alertType = currentPrice <= alertPrice ? PriceAlertType.Above : PriceAlertType.Below;

    const { mutate: createPriceAlert } = useApiPut(
        "/account/settings/price-alert",
        {
            onSuccess: () => {
                closeModal(ModalNames.PriceAlert);
            },
            onError: (error) => {
                handleError(error.data.message);
            },
        },
        () => [["/account/settings/price-alert", { symbol: data.pair.symbol, subaccountId }]],
    );

    const { mutate: deletePriceAlert } = useApiDelete(
        "/account/settings/price-alert",
        {
            onSuccess: () => {
                closeModal(ModalNames.PriceAlert);
            },
            onError: (error) => {
                handleError(error.data.message);
            },
        },
        () => [["/account/settings/price-alert", { symbol: data.pair.symbol, subaccountId }]],
    );

    return (
        <div className="flex min-w-96 flex-col gap-4 p-4">
            <div className="text-gray-1100 flex flex-row items-start gap-1.5 text-sm font-thin text-nowrap">
                <span>{t("alertMeWhen")}</span>
                <AssetIcon url={data.pair.baseImageUrl} width={18} height={18} />
                <span className="text-white-1200">
                    {data.pair.baseSymbol}/{data.pair.quoteSymbol}
                </span>
                <span>{t("reachesAPriceOf")}</span>
                <span className="pointer-events-none invisible absolute -z-9999999 p-0.5 font-[normal]" ref={hiddenRef}>
                    {alertPrice}
                </span>
                <input
                    ref={inputRef}
                    className="border-b-gray-1000 text-white-1200 m-0 inline-block min-w-8 border-0 border-b-2 bg-transparent align-baseline outline-hidden"
                    value={alertPrice}
                    onChange={(e) => {
                        setAlertPrice(e.target.value);
                    }}
                    size={40}
                    placeholder="0.00"
                />
            </div>
            <div className="flex items-center gap-3">
                <Button
                    disabled={data.alertPrice === "0.00"}
                    className="w-full"
                    onClick={() => deletePriceAlert({ symbol: data.pair.symbol, subaccountId })}
                >
                    {t("clear")}
                </Button>
                <Button
                    disabled={!isNumber(alertPrice) || parseFloat(alertPrice) <= 0}
                    color="green"
                    className="w-full"
                    onClick={() =>
                        createPriceAlert({
                            symbol: data.pair.symbol,
                            subaccountId,
                            alertType,
                            alertPrice,
                            alertPriceType,
                        })
                    }
                >
                    {t("setAlert")}
                </Button>
            </div>
        </div>
    );
}
