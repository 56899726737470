"use client";
import { useCallback, useEffect, useState } from "react";

const breakpointOrder = ["xs", "sm", "md", "lg", "xl", "2xl"] as const;
type Breakpoint = (typeof breakpointOrder)[number];

const breakpoints: Record<Breakpoint, number> = {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1536,
};

function getBreakpoint(width: number): Breakpoint {
    if (width >= breakpoints["2xl"]) return "2xl";
    if (width >= breakpoints.xl) return "xl";
    if (width >= breakpoints.lg) return "lg";
    if (width >= breakpoints.md) return "md";
    if (width >= breakpoints.sm) return "sm";
    return "xs";
}

type Subscriber = (breakpoint: Breakpoint) => void;

class BreakpointManager {
    private subscribers: Subscriber[] = [];
    public breakpoint: Breakpoint;
    private mqls: { mql: MediaQueryList; listener: () => void }[] = [];

    constructor() {
        if (typeof window !== "undefined") {
            this.breakpoint = getBreakpoint(window.innerWidth);
            this.initMediaQueries();
        } else {
            this.breakpoint = "xs";
        }
    }

    private initMediaQueries() {
        breakpointOrder.slice(1).forEach((bp) => {
            const query = `(min-width: ${breakpoints[bp]}px)`;
            const mql = window.matchMedia(query);

            const listener = () => {
                const newBreakpoint = getBreakpoint(window.innerWidth);
                if (newBreakpoint !== this.breakpoint) {
                    this.breakpoint = newBreakpoint;
                    this.notifySubscribers();
                }
            };

            mql.addEventListener("change", listener);
            this.mqls.push({ mql, listener });
        });
    }

    private notifySubscribers() {
        this.subscribers.forEach((subscriber) => subscriber(this.breakpoint));
    }

    public subscribe(subscriber: Subscriber) {
        this.subscribers.push(subscriber);
        subscriber(this.breakpoint);
        return () => {
            this.subscribers = this.subscribers.filter((sub) => sub !== subscriber);
        };
    }
}

const breakpointManager = new BreakpointManager();

function useWindowSize(): {
    breakpoint: Breakpoint;
    breakpointBelow: (breakpoint: Breakpoint) => boolean;
    breakpointAbove: (breakpoint: Breakpoint) => boolean;
} {
    const [breakpoint, setBreakpoint] = useState<Breakpoint>(breakpointManager.breakpoint);

    useEffect(() => {
        const unsubscribe = breakpointManager.subscribe(setBreakpoint);
        return () => {
            unsubscribe();
        };
    }, []);

    const breakpointAbove = useCallback(
        (bp: Breakpoint) => {
            const bpIndex = breakpointOrder.indexOf(bp);
            const currentBpIndex = breakpointOrder.indexOf(breakpoint);
            return currentBpIndex >= bpIndex;
        },
        [breakpoint],
    );

    const breakpointBelow = useCallback(
        (bp: Breakpoint) => {
            const bpIndex = breakpointOrder.indexOf(bp);
            const currentBpIndex = breakpointOrder.indexOf(breakpoint);
            return currentBpIndex < bpIndex;
        },
        [breakpoint],
    );

    return { breakpoint, breakpointBelow, breakpointAbove };
}

export { breakpoints, useWindowSize };
