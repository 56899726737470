import { Asset, AssetWithBalance, Chain } from "@/app/_hooks/types";
import { useTranslations } from "next-intl";
import { FormattedNumber } from "../../number/FormattedNumber";
import { formatDepositTime } from "../../utils";
export function ChainRow({
    chain,
    minAmount,
    asset,
    fee,
}: {
    chain: Chain;
    minAmount: string;
    fee?: string;
    asset: Asset | AssetWithBalance;
}) {
    const t = useTranslations("ChainRow");
    return (
        <div className="w-full">
            <div className="text-xs font-medium">{chain.name}</div>
            <div className="text-white-600 flex justify-between text-xs">
                <div>
                    {t("expectedArrival")} {formatDepositTime(chain.blockTime, chain.confirmations)}{" "}
                </div>
                <div>
                    <span>{t("min")} &nbsp;</span>
                    <FormattedNumber number={minAmount} options={{ maximumFractionDigits: 10 }} unit={asset.symbol} />
                    {fee && (
                        <span>
                            <span>,&nbsp; {t("fee")} </span>
                            <FormattedNumber number={fee} unit={asset.symbol} />
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}
